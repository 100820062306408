import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CancelEntryPointItem, checkboxSelection, checkboxSelectionWithParent, Child, Data, DataObject } from '../../models/crosstab.model';
import { customCalculationvalidationRules, customGroupsvalidationRules } from '../../constants/custom-group-validation';
import { AlertMessageModel, results } from '../../models/common.model';
import { AlertMessageService } from '../../services/alert-message.service';
import { LoaderService } from '../../services/loader.service';
import { CrosstabService } from '../../services/crosstab.service';
import { UserProjectManagementService } from '../../services/user-project-management.service';
import * as _ from 'lodash';
import { Status } from '../../models/enum/status.enum';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { BUTTON_HOVER_NAMES, COMMON_CONSTANTS } from '../../constants/common-constants';
import { Feature } from '../../models/usermanagement.model';
import { ListItem } from '../../models/shared.model';
import { CustomVariableService } from '../../services/custom-variables.service';

@Component({
  selector: 'app-custom-variables',
  templateUrl: './custom-variables.component.html',
  styleUrls: ['./custom-variables.component.css']
})
export class CustomVariablesComponent implements OnInit {

  $unSubscribeSubmitData1!: Subscription;
  $unSubscribeSubmitData2!: Subscription;

  ngOnInit() {
    this.$unSubscribeSubmitData1 =
      this.crossTabService.variableTabData$.subscribe(({ data }) => {
        if (data !== null && data !== undefined) {
          this.variableTabData = data;
        }
      });
    this.bindCustomGroupFlags(this.bindCustomGroupFlagsData);
  
    
  }

  ngOnChanges(changes: SimpleChanges) {
      this.bindCustomGroupFlags(this.bindCustomGroupFlagsData);
  }

  buttonCssClassMap: Record<string, string> = {
    '(': 'openParaButton',
    ')': 'closeParaButton',
    NOT: 'notButton',
    AND: 'andButton',
    OR: 'orButton',
    METRIC: 'customGroup',
    NUMERAL: 'numeralButton',
    '+': 'addButton',
    '-': 'subtractButton',
    '*': 'multiplyButton',
    '/': 'divideButton',
  };

  public dataObject: DataObject = {
    Row: [],
    Column: [],
    RowNesting: [],
    ColNesting: [],
    Filter: [],
    CustomGroup: [],
    CustomCalculation: [],
  };

  public previewData: DataObject = {
    Row: [],
    Column: [],
    RowNesting: [],
    ColNesting: [],
    Filter: [],
    CustomGroup: [],
    CustomCalculation: [],
  };

  private listASortID: number = 0;
  private listBSortID: number = 0;
  private listCSortID: number = 0;
  private listDSortID: number = 0;
  private listESortID: number = 0;

  customGroupSelectionsObj: {
    displayString: string;
    oneObject: any;
    operatorValue: string;
    type: string;
    rowOrder?: number;
    variableCode: string[];
    responseCode: string;
    headerText: string;
  }[] = [];

  customCalculationSelectionsObj: {
    displayString: string;
    oneObject: any;
    operatorValue: string;
    type: string;
    rowOrder?: number;
    variableCode: string[];
    responseCode: string;
    headerText: string;
  }[] = [];

  listCustomCalculationItemsList: {
    customGroupRowOrder?: number;
    customCalculationRowOrder?: number;
    Level0: string;
    Level1: string;
    tooltip: string;
    has_nextLevel: boolean;
    sortId: number;
    is_Derived: string;
    is_calc_type: string;
    data_variable_code: string;
    data_response_code: string;
    data_sort_id: string;
    data_is_multi_punch: string;
    data_date: string;
    data_year_code: string;
    is_dimension: boolean;
    is_custmGrpPresent: boolean;
    is_custmCalcPresent: boolean;
    kibanaFilterScript: string;
    customGroupId: string;
    customCalculationId: string;
    dataYearCode: string;
    isSelectAllMetrics: boolean;
    isSelectAllMetricsCount: number;
    is_entrypoint: boolean
  }[] = [];

  responseData1: {
    customGroupRowOrder?: number;
    customCalculationRowOrder?: number;
    Level0: string;
    Level1: string;
    isCheckbox: boolean;
    sortId: number;
    is_entryPoint: boolean;
    Level2: {
      MetricOption: string;
      response_code: string;
      data_variable_code: string;
      calculation_type: string;
      tooltip: string;
      isDerviedVariable: boolean;
      sort_id: number;
      isMultiPunch: boolean;
      year_code: string;
      date: string;
      is_dimension: boolean;
      isCustomGroup: boolean;
      isCustomCalculation: boolean;
      kibanaFilterScript: string;
      customGroupId: string;
      customCalculationId: string;
      dataYearCode: string;
      MultiPunchNumber: number;
      isSelectAllMetrics: boolean;
      isSelectAllMetricsCount: number;
    }[];
  }[] = [];

  responseData2: {
    customGroupRowOrder?: number;
    customCalculationRowOrder?: number;
    Level0: string;
    Level1: string;
    Level2: string;
    isCheckbox: boolean;
    sortId: number;
    is_entryPoint: boolean;
    Level3: {
      MetricOption: string;
      response_code: string;
      data_variable_code: string;
      calculation_type: string;
      tooltip: string;
      isDerviedVariable: boolean;
      sort_id: number;
      isMultiPunch: boolean;
      year_code: string;
      date: string;
      is_dimension: boolean;
      isCustomGroup: boolean;
      isCustomCalculation: boolean;
      kibanaFilterScript: string;
      customGroupId: string;
      customCalculationId: string;
      dataYearCode: string;
      MultiPunchNumber: number;
    }[];
  }[] = [];

  responseData3: {
    customGroupRowOrder?: number;
    customCalculationRowOrder?: number;
    Level0: string;
    Level1: string;
    Level2: string;
    Level3: string;
    isCheckbox: boolean;
    sortId: number;
    is_entryPoint: boolean;
    Level4: {
      MetricOption: string;
      response_code: string;
      data_variable_code: string;
      calculation_type: string;
      tooltip: string;
      isDerviedVariable: boolean;
      sort_id: number;
      isMultiPunch: boolean;
      year_code: string;
      date: string;
      is_dimension: boolean;
      isCustomGroup: boolean;
      isCustomCalculation: boolean;
      kibanaFilterScript: string;
      customGroupId: string;
      customCalculationId: string;
      dataYearCode: string;
      MultiPunchNumber: number;
    }[];
  }[] = [];

  responseData4: {
    customGroupRowOrder?: number;
    customCalculationRowOrder?: number;
    Level0: string;
    Level1: string;
    Level2: string;
    Level3: string;
    Level4: string;
    isCheckbox: boolean;
    sortId: number;
    is_entryPoint: boolean;
    Level5: {
      MetricOption: string;
      response_code: string;
      data_variable_code: string;
      calculation_type: string;
      tooltip: string;
      isDerviedVariable: boolean;
      sort_id: number;
      isMultiPunch: boolean;
      year_code: string;
      date: string;
      is_dimension: boolean;
      isCustomGroup: boolean;
      isCustomCalculation: boolean;
      kibanaFilterScript: string;
      customGroupId: string;
      customCalculationId: string;
      dataYearCode: string;
      MultiPunchNumber: number;
    }[];
  }[] = [];

  responseData5: {
    customGroupRowOrder?: number;
    customCalculationRowOrder?: number;
    Level0: string;
    Level1: string;
    Level2: string;
    Level3: string;
    Level4: string;
    Level5: string;
    isCheckbox: boolean;
    sortId: number;
    is_entryPoint: boolean;
    Level6: {
      MetricOption: string;
      response_code: string;
      data_variable_code: string;
      calculation_type: string;
      tooltip: string;
      isDerviedVariable: boolean;
      sort_id: number;
      isMultiPunch: boolean;
      year_code: string;
      date: string;
      is_dimension: boolean;
      isCustomGroup: boolean;
      isCustomCalculation: boolean;
      kibanaFilterScript: string;
      customGroupId: string;
      customCalculationId: string;
      dataYearCode: string;
      MultiPunchNumber: number;
    }[];
  }[] = [];

  listCustomGroupItemsList: {
    customGroupRowOrder?: number;
    Level0: string;
    Level1: string;
    tooltip: string;
    has_nextLevel: boolean;
    sortId: number;
    is_Derived: string;
    is_calc_type: string;
    data_variable_code: string;
    data_response_code: string;
    data_sort_id: string;
    data_is_multi_punch: string;
    data_date: string;
    data_year_code: string;
    is_dimension: boolean;
    is_custmGrpPresent: boolean;
    is_custmCalcPresent: boolean;
    kibanaFilterScript: string;
    customGroupId: string;
    customCalculationId: string;
    dataYearCode: string;
    isSelectAllMetrics: boolean;
    isSelectAllMetricsCount: number;
    is_entrypoint: boolean
  }[] = [];

  dragdropConnected: any = [
    'customVariable'
  ];
  drag_drop_container = [...this.dragdropConnected];

  listAItemsList: ListItem[] = [];
  listBItemsList: ListItem[] = [];
  listCItemsList: ListItem[] = [];
  listDItemsList: ListItem[] = [];
  listEItemsList: ListItem[] = [];

  @Input() isCustomCalculation: boolean = false;
  @Input() isCreateCustomCalculation: boolean = false;
  @Input() isPreviewExpand: boolean = false;
  @Input() customCalculationItemIndex: number = -1;
  @Input() disabledCustomCalculationOperatorList: string[] = [];
  @Input() isCreateBtnClick: boolean = false;
  @Input() isShareMetric: boolean = true;
  @Input() isMeanMetric: boolean = false;
  @Input() ImageURL: string = '';
  @Input() selected_study: string = '';
  @Input() selected_client: string = '';
  selectedDataFormat: string = '';
  @Input() isCustomGroups: boolean = true;
  @Input() customGroupItemIndex: number = -1;
  @Input() landingdata: any;
  @Input() helpGuide: boolean = false;
  @Input() dataArray: any[] = [];
  @Input() isCreateTable: boolean = false;
  @Input() isSaveSelection: boolean = false;
  @Input() bindCustomGroupFlagsData: any;
  @Input() loadCustomGroupUserDefinedData: any;
  @Input() loadCustomGroupSharedWithMeData: any;

  @Output() closeCustomVariableScreen = new EventEmitter<any>();
  @Output() customvariable = new EventEmitter<any>();

  @ViewChild('summaryText') summaryText: ElementRef | undefined;

  isSavedScenarioSelected: boolean = false;
  isCustomGroupClick: boolean = false;
  isCreateCustomGroup: boolean = true;
  isUpdateCustomGroup: boolean = false;
  isDeleteCustomGroup: boolean = false;
  isDuplicateCustomGroup: boolean = false;
  isCustomCalculationClick = false;
  isbackButton: boolean = false;
  hidePanel: boolean = false;
  buttonHoverNames = BUTTON_HOVER_NAMES;
  NestingConfig = COMMON_CONSTANTS.NestingConfiguration;
  left_panel_collapse: boolean = false;
  displayMainComponent: boolean = false;
  isRowNesting: boolean = false;
  isSelectAll: boolean = false;
  sidePanelDisplay: boolean = false;
  sidePanelNestingDisplay: boolean = false;
  columnPanelDisplay: boolean = false;
  columnNestingPanelDispaly: boolean = false;
  filterPanelDisplay: boolean = false;
  is_entrypoint: boolean = false;
  enableProfiling: boolean = false;
  enableAnswerBase: boolean = true;
  isTotalBase: boolean = false;
  isAnswerBase: boolean = true;
  FooterNote: boolean = false;
  has_nextLevel: boolean = false;
  leftpanelDataArray: any[] = [];
  variableEntryNullData: any;
  disabledCustomGroupOperatorList: string[] = [];
  selectionObjects: any[] = [];
  Level3MultiVariables: any = [];
  isChangeEntryPoint: boolean = false;
  isFilteringActive: boolean = true;
  isProfilingActive: boolean = false;
  isSelectionSummary: boolean = false;
  confidenceLevelDropDown: boolean = false;
  referenceLevelDropDown: boolean = false;
  weightDropDown: boolean = false;
  dataDropDown: boolean = false;
  isReference: boolean = false;
  showCustomMetricsPopup: boolean = false;
  moduleName: string = 'Custom SnapShot';
  is_calc_type: string = '';
  customGroupHtml: string = '';
  customGroupQuery: string = '';
  customGroupRowOrder: number = 1;

  droppedItem: string = '';
  droppedItemSortId: number = 0;
  droppedLevel2Item: string = '';
  droppedLevel2ItemSortId: number = 0;
  droppedLevel3Item: string = '';
  droppedLevel3ItemSortId: number = 0;
  droppedLevel4Item: string = '';
  droppedLevel4ItemSortId: number = 0;
  droppedLevel5Item: string = '';
  droppedLevel5ItemSortId: number = 0;

  filterPanelLevel1Display: boolean = false;
  filterPanelLevel2Display: boolean = false;
  filterPanelLevel3Display: boolean = false;
  filterPanelLevel4Display: boolean = false;
  filterPanelLevel5Display: boolean = false;

  searchTextLevel1: string = '';
  searchTextLevel2: string = '';
  searchTextLevel3: string = '';
  searchTextLevel4: string = '';
  searchTextLevel5: string = '';

  sidePanelLevel1Display: boolean = false;
  sidePanelLevel2Display: boolean = false;
  sidePanelLevel3Display: boolean = false;
  sidePanelLevel4Display: boolean = false;
  sidePanelLevel5Display: boolean = false;

  selectedLevel1Item: string = '';
  selectedLevel2Item: string = '';
  selectedLevel3Item: string = '';
  selectedLevel4Item: string = '';

  sidePanelLevel1NestingDisplay: boolean = false;
  sidePanelLevel2NestingDisplay: boolean = false;
  sidePanelLevel3NestingDisplay: boolean = false;
  sidePanelLevel4NestingDisplay: boolean = false;
  sidePanelLevel5NestingDisplay: boolean = false;

  columnPanelLevel1Display: boolean = false;
  columnPanelLevel2Display: boolean = false;
  columnPanelLevel3Display: boolean = false;
  columnPanelLevel4Display: boolean = false;
  columnPanelLevel5Display: boolean = false;

  columnPanelNestLeve11Display: boolean = false;
  columnPanelNestLeve12Display: boolean = false;
  columnPanelNestLeve13Display: boolean = false;
  columnPanelNestLeve14Display: boolean = false;
  columnPanelNestLeve15Display: boolean = false;

  customGroupId: string = '';
  selectedVariableLevel0: string = '';
  selectedVariableLevel1: string = '';
  isSelectAllMetricsLevel: boolean = false;
  allValidationsPass: boolean = false;
  selectedVariable_IsDervied: string = '';
  selectedVariable_IsCalc: string = '';
  data_variable_code: string = '';
  dataYearCode: string = '';
  data_response_code: string = '';
  data_sort_id: string = '';
  data_is_multi_punch: string = '';
  data_date: string = '';
  data_year_code: string = '';
  is_Derived: string = '';
  kibanaFilterScript: string = '';
  customCalculationId: string = '';
  is_dimension: boolean = false;
  is_custmGrpPresent: boolean = false;
  is_custmCalcPresent: boolean = false;
  is_Time_Period_Present: boolean = false;
  is_tooltip: string = '';
  entryList: any[] = [];
  customCalculationRowOrder: number = 1;
  variableTabData: any;
  isSelectAllMetrics: boolean = false;
  LimitForPreviewTable: number = 70;
  limitCellCount: number = 5000;
  sampleSizeDropdown: boolean = false;
  isSaveCustomGroupSelectionClick = false;
  selectedCustomGroupName: string = '';
  selectionSummary: string = '';
  isShareCustomGroupPopupClick: boolean = false;
  selectedCustomGroupName_Bucket: string = '';
  isSaveSelectionClick = false;
  isLoadSelection: boolean = false;
  selectedCustomGroupObj: any; //get complete selected Custom Group Obj
  selectedEmailsToShare: string[] = [];
  selected_module: string = '';
  customGroupShareUserList: string[] = [];
  customCalculationShareUserList: string[] = [];
  selectedCustomCalculationObj: any;
  checkStatus: boolean = false;
  disableStackChart: boolean = false;
  isVerticalStackChartClick: boolean = false;
  isUpdateCustomCalculation: boolean = false;
  isDuplicateCustomCalculation: boolean = false;
  showLoaderPopUp: boolean = false;


  constructor(
    private _alertService: AlertMessageService,
    private Loader: LoaderService,
    private crossTabService: CrosstabService,
    private customVariableService: CustomVariableService,
    private _usermanagementService: UserProjectManagementService
  ) 
    { }

    private setCustomGroupRowOrder(
      headerText: string,
      ismultipunch: boolean,
      currentVariableCode: string,
      CustomGroupRowOrder?: number
    ) {
      var filteredObj: any = [];
 
      filteredObj = _.filter(
        this.customGroupSelectionsObj,
        (data) =>
          data.headerText == headerText && data.rowOrder == CustomGroupRowOrder
      );
 
      const maxRowOrder =
        _.maxBy(this.customGroupSelectionsObj, 'rowOrder')?.rowOrder ||
        this.customGroupRowOrder;
  
      var lastRowOrder = maxRowOrder;
  
      lastRowOrder = lastRowOrder == undefined ? 0 : lastRowOrder;
      //if this object already exists then take the existing customGroup order if not assign based on maximum value
      this.customGroupRowOrder =
        filteredObj.length > 0 ? filteredObj[0].rowOrder : lastRowOrder + 1;
    }

    rebindCustomGroupView() {
      var customGroupData;
      if (this.selectedCustomGroupName_Bucket == 'userDefined')
        customGroupData = this.loadCustomGroupUserDefinedData;
      else customGroupData = this.loadCustomGroupSharedWithMeData;
      var selectedCustomGroupObj = customGroupData.filter(
        (a: any) => a.selection_name === this.selectedCustomGroupName
      );
  
      var selectionObject = JSON.parse(
        selectedCustomGroupObj[0].selection_object
      );
  
      //to bind custom group selections
      this.customGroupSelectionsObj = selectionObject.customGroupSelectionsObj;
      //to bind left panel selections in custom group view
      this.listCustomGroupItemsList = selectionObject.listCustomGroupItemsList;
  
      var tempRowOrder;
      // Check if 'customGroupRowOrder' is not already a property in the 'listCustomGroupItemsList' objects
      if (
        !this.listCustomGroupItemsList.some((e) => 'customGroupRowOrder' in e)
      ) {
        // If 'customGroupRowOrder' is not a property, execute the loop
        this.listCustomGroupItemsList.forEach((e) => {
          var tempRowOrder = _.filter(
            this.customGroupSelectionsObj,
            (r: any) => r.headerText == e.Level1
          )[0]?.rowOrder;
          e.customGroupRowOrder = tempRowOrder;
        });
      }
  
      this.dataObject['CustomGroup'] = selectionObject.customGroupDataObj;
  
      // Check if 'customGroupRowOrder' is not already a property in the 'CustomGroup' objects
      if (
        !this.dataObject['CustomGroup'].some((e) => 'customGroupRowOrder' in e)
      ) {
        // If 'customGroupRowOrder' is not a property, execute the loop
        this.dataObject['CustomGroup'].forEach((e) => {
          var tempRowOrder = _.filter(
            this.customGroupSelectionsObj,
            (r: any) => r.headerText == e.Parent
          )[0]?.rowOrder;
          e.customGroupRowOrder = tempRowOrder;
        });
      }
    }
  

    bindCustomGroupFlags(eventData: {
      displayMainComponent: boolean;
      isCreateCustomGroup: boolean;
      isUpdateCustomGroup: boolean;
      isDeleteCustomGroup: boolean;
      isDuplicateCustomGroup: boolean;
      isShareCustomGroup: boolean;
      selectedCustomGroupName: string;
      selectedCustomGroupName_Bucket: string;
      customGroupId: string;
      isShareMetric: boolean;
      isMeanMetric: boolean;
    }) {
      this.isSavedScenarioSelected = false;
      this.clearSelection('CustomGroup'); //clear already existing contents
      this.displayMainComponent = eventData.displayMainComponent;
      this.isCreateCustomGroup = eventData.isCreateCustomGroup;
      this.isUpdateCustomGroup = eventData.isUpdateCustomGroup;
      this.selectedCustomGroupName = eventData.selectedCustomGroupName;
      this.isDeleteCustomGroup = eventData.isDeleteCustomGroup;
      this.isDuplicateCustomGroup = eventData.isDuplicateCustomGroup;
      this.isShareCustomGroupPopupClick = eventData.isShareCustomGroup;
      this.selectedCustomGroupName_Bucket =
        eventData.selectedCustomGroupName_Bucket;
      this.isMeanMetric = eventData.isMeanMetric;
      this.isShareMetric = eventData.isShareMetric;
      if (!this.isDeleteCustomGroup && !this.isShareCustomGroupPopupClick) {
        this.isCustomGroupClick = false; //to close custom group popup
        this.isSaveSelection = false; //to hide save button
        this.isLoadSelection = false; //to create load button
  
        if (this.isDuplicateCustomGroup) this.isCreateTable = true;
        else this.isCreateTable = false; //to hide create table button
      }
  
      if (this.isCreateCustomGroup) {
        //add this operator on load
        this.invokeValidationRules('(');
        let event: Event = new Event('');
        this.customGroupSelectionsObj = [];
        this.bindCustomGroupOperatorClick(event, '(');
      } else if (this.isUpdateCustomGroup) {
        //rebind contents to update
        this.rebindCustomGroupView();
        //to display save custom group button
        this.isCreateCustomGroup = true;
        this.invokeValidationRules(')');
      } else if (this.isDeleteCustomGroup) {
        this.getCompleteCustomGroupInfo();
        this.bindDeleteCustomGroup(eventData.customGroupId);
      } else if (this.isDuplicateCustomGroup) {
        this.bindDuplicateCustomGroup();
        this.invokeValidationRules(')');
      } else if (this.isShareCustomGroupPopupClick) {
        this.getCompleteCustomGroupInfo(); //update currently selected custom group data obj
        this.bindShareCustomGroup();
      }
      this.customGroupItemIndex = -1;
    }

    
    bindDuplicateCustomGroup() {
      this.isSavedScenarioSelected = false;
      this.isCustomGroupClick = false; //close custom group popup
      this.isCreateCustomGroup = true; //bind all contents similar to create customgroup
      this.rebindCustomGroupView(); //to bind all the data objects
    }

    bindDeleteCustomGroup(customGroupId: string) {
      this.$unSubscribeSubmitData2 = this.customVariableService
        .deleteCustomGroupSelectionData(
          this.selected_client,
          this.selected_study,
          this.selectedCustomGroupName,
          this.selectedCustomGroupName_Bucket,
          this.selectedCustomGroupObj.shared_with,
          COMMON_CONSTANTS.SnapshotModuleId
        )
        .subscribe((result: results) => {
          // Response is valid
          if (result.HasValidData) {
            let JsonData = JSON.parse(result.Data);
            if (JsonData == 'Deleted Successfully') {
              var customGroupData;
              if (this.selectedCustomGroupName_Bucket == 'userDefined') {
                customGroupData = this.loadCustomGroupUserDefinedData;
                this.checkForDeletedCustomGroup(customGroupId);
              } else customGroupData = this.loadCustomGroupSharedWithMeData;
              // Find the index of the row with selection_name
              const index = customGroupData.findIndex(
                (item: any) =>
                  item.selection_name === this.selectedCustomGroupName
              );
  
              // If the row is found, update the is_Active property to false
              if (index !== -1) {
                customGroupData[index].is_active = false;
                this.selectedCustomGroupName = ''; //after deletion of Percentage Variable clear the selection
              }
  
              this.errorPopup('Percentage Variable Deleted Successfully.', 'Alert');
            } else if (JsonData == 'Deletion Failed') {
              this.errorPopup(
                'Percentage Variable Deletion Failed. Please try again later.',
                'Alert'
              );
            }
          }
          //hide the loader
          this.Loader.hideLoader();
  
          this.usageDataLog(Feature.DeleteCustomGroup);
        });
    }

    checkForDeletedCustomGroup(customGroupId: string) {
      this.findCustomGroupToRemove(
        this.listAItemsList,
        this.dataObject.Row,
        customGroupId
      );
      this.findCustomGroupToRemove(
        this.listCItemsList,
        this.dataObject.Column,
        customGroupId
      );
      this.findCustomGroupToRemove(
        this.listEItemsList,
        this.dataObject.Filter,
        customGroupId
      );
    }

    findCustomGroupToRemove(
      dropdownItem: any,
      dataobject: any,
      customGroupId: string
    ) {
      if (dropdownItem.length > 0) {
        let customGroupIndex = dropdownItem.findIndex((x: any) => {
          return x.customGroupId == customGroupId;
        });
        if (customGroupIndex > -1) {
          dropdownItem.splice(customGroupIndex, 1);
          let dataindex = dataobject.findIndex((x: any) => {
            return x.isCustomGroup && x.customGroupId == customGroupId;
          });
          if (dataindex > -1) {
            dataobject.splice(dataindex, 1);
          }
          this.getLimitedDataPreview();
        }
      }
    }

    
  bindShareCustomGroup(customtype: string = 'customGroup') {
    this.selectedEmailsToShare = [];
    //get user list to share based on module,client and study
    this._usermanagementService
      .getUsersBasedonStudy(
        this.selected_client,
        this.selected_study,
        this.selected_module
      )
      .subscribe((result: results) => {
        // Check if the response has valid data
        if (result.HasValidData) {
          this.customGroupShareUserList = JSON.parse(result.Data);
          if (customtype == 'Custom Calculation')
            this.customCalculationShareUserList = JSON.parse(result.Data);
        }
        if (customtype == 'Custom Calculation') {
          //users with whom this custom Calculation is shared already
          this.selectedEmailsToShare =
            this.selectedCustomCalculationObj.shared_with.trim().length > 0
              ? this.selectedCustomCalculationObj.shared_with.split(',')
              : [];
        } else {
          //users with whom this custom group is shared already
          this.selectedEmailsToShare =
            this.selectedCustomGroupObj.shared_with.trim().length > 0
              ? this.selectedCustomGroupObj.shared_with.split(',')
              : [];
        }
      });
  }

      //get complete info on selected custom group
  getCompleteCustomGroupInfo() {
    var customGroupData =
      this.selectedCustomGroupName_Bucket == 'userDefined'
        ? this.loadCustomGroupUserDefinedData
        : this.loadCustomGroupSharedWithMeData;
    //only user defined custom group can be shared
    this.selectedCustomGroupObj = _.filter(
      customGroupData,
      (r) =>
        r.selection_name === this.selectedCustomGroupName &&
        r.client_name === this.selected_client &&
        r.study === this.selected_study
      // r.module_id === this.selected_module
    )[0];

    //update the selected object to shared list
    //this.loadSharedWithMeSavedScenarioData.push(this.selectedCustomGroupObj);
  }

    bindCustomGroupOperatorClick(event: Event, operator: string) {
      this.invokeValidationRules(operator);
      this.setCustomGroupRowOrder('', false, '');
  
      const newEntry = {
        displayString: operator,
        oneObject: this.dataObject['CustomGroup'],
        operatorValue: operator,
        type: 'operator',
        rowOrder: this.customGroupRowOrder,
        variableCode: [],
        responseCode: '',
        headerText: operator,
      };
      if (this.customGroupItemIndex == -1) {
        this.customGroupSelectionsObj.push(newEntry);
        this.customGroupItemIndex = this.customGroupSelectionsObj.length - 1;
      } else {
        this.customGroupItemIndex = this.customGroupItemIndex + 1;
        this.customGroupSelectionsObj.splice(
          this.customGroupItemIndex,
          0,
          newEntry
        );
      }

    }

  HelpGuideFromLeftPanel(boolValue: boolean) {
    this.helpGuide = boolValue;
  }

  selectAll(Parent: string, Selection: string, is_entrypoint: boolean, customGroupRowOrder?: number) {
   
    this.isSelectAll = !this.isSelectAll;
    this.checkStatus = !this.checkStatus;

    let selectedList: any[] = [];
    let callback: ((item: any, itemList: any) => void) | undefined = undefined;

    if (Parent === 'Level1') {
      if (Selection == 'CustomGroup')
        selectedList = _.filter(
          this.responseData1,
          (r) => r.customGroupRowOrder == customGroupRowOrder
        );
      else if (Selection == 'CustomCalculation')
        selectedList = _.filter(
          this.responseData1,
          (r) => r.customCalculationRowOrder == customGroupRowOrder
        );
      else selectedList = this.responseData1;
      callback = (item, itemList) =>
        this.checkboxClick(
          Selection,
          is_entrypoint,
          item.Level0,
          item.Level1 + '|' + itemList.MetricOption,
          'true',
          item.Level1,
          this.isSelectAll,
          itemList.data_variable_code,
          itemList.response_code,
          itemList.isDerviedVariable,
          itemList.calculation_type,
          itemList.sort_id,
          itemList.isMultiPunch,
          itemList.year_code,
          itemList.date,
          item.sortid,
          itemList.isMultiPunch ? item.Level1 : '',
          itemList.is_dimension,
          itemList.isCustomGroup,
          itemList.kibanaFilterScript,
          itemList.customGroupId,
          itemList.dataYearCode,
          itemList.MultiPunchNumber,
          itemList.isCustomCalculation,
          itemList.customCalculationId,
          item.customCalculationRowOrder,
          item.customGroupRowOrder
        );
    } else if (Parent === 'Level2') {
      if (Selection == 'CustomGroup')
        selectedList = _.filter(
          this.responseData2,
          (r) => r.customGroupRowOrder == customGroupRowOrder
        );
      else if (Selection == 'CustomCalculation')
        selectedList = _.filter(
          this.responseData2,
          (r) => r.customCalculationRowOrder == customGroupRowOrder
        );
      else selectedList = this.responseData2;
      callback = (item, itemList) =>
        this.checkboxClick(
          Selection,
          is_entrypoint,
          item.Level0,
          item.Level1 + '|' + item.Level2 + '|' + itemList.MetricOption,
          'true',
          item.Level1,
          this.isSelectAll,
          itemList.data_variable_code,
          itemList.response_code,
          itemList.isDerviedVariable,
          itemList.calculation_type,
          itemList.sort_id,
          itemList.isMultiPunch,
          itemList.year_code,
          itemList.date,
          item.sortid,
          itemList.isMultiPunch ? item.Level2 : '',
          itemList.is_dimension,
          itemList.isCustomGroup,
          itemList.kibanaFilterScript,
          itemList.customGroupId,
          itemList.dataYearCode,
          itemList.MultiPunchNumber,
          itemList.isCustomCalculation,
          itemList.customCalculationId,
          item.customCalculationRowOrder,
          item.customGroupRowOrder
        );
    } else if (Parent === 'Level3') {
      if (Selection == 'CustomGroup')
        selectedList = _.filter(
          this.responseData3,
          (r) => r.customGroupRowOrder == customGroupRowOrder
        );
      else if (Selection == 'CustomCalculation')
        selectedList = _.filter(
          this.responseData3,
          (r) => r.customCalculationRowOrder == customGroupRowOrder
        );
      else selectedList = this.responseData3;
      callback = (item, itemList) =>
        this.checkboxClick(
          Selection,
          is_entrypoint,
          item.Level0,
          item.Level1 +
          '|' +
          item.Level2 +
          '|' +
          item.Level3 +
          '|' +
          itemList.MetricOption,
          'true',
          item.Level1,
          this.isSelectAll,
          itemList.data_variable_code,
          itemList.response_code,
          itemList.isDerviedVariable,
          itemList.calculation_type,
          itemList.sort_id,
          itemList.isMultiPunch,
          itemList.year_code,
          itemList.date,
          item.sortid,
          itemList.isMultiPunch ? item.Level3 : '',
          itemList.is_dimension,
          itemList.isCustomGroup,
          itemList.kibanaFilterScript,
          itemList.customGroupId,
          itemList.dataYearCode,
          itemList.MultiPunchNumber,
          itemList.isCustomCalculation,
          itemList.customCalculationId,
          item.customCalculationRowOrder,

          item.customGroupRowOrder
        );
    } else if (Parent === 'Level4') {
      if (Selection == 'CustomGroup')
        selectedList = _.filter(
          this.responseData4,
          (r) => r.customGroupRowOrder == customGroupRowOrder
        );
      else if (Selection == 'CustomCalculation')
        selectedList = _.filter(
          this.responseData4,
          (r) => r.customCalculationRowOrder == customGroupRowOrder
        );
      else selectedList = this.responseData4;
      callback = (item, itemList) =>
        this.checkboxClick(
          Selection,
          is_entrypoint,
          item.Level0,
          item.Level1 +
          '|' +
          item.Level2 +
          '|' +
          item.Level3 +
          '|' +
          item.Level4 +
          '|' +
          itemList.MetricOption,
          'true',
          item.Level1,
          this.isSelectAll,
          itemList.data_variable_code,
          itemList.response_code,
          itemList.isDerviedVariable,
          itemList.calculation_type,
          itemList.sort_id,
          itemList.isMultiPunch,
          itemList.year_code,
          itemList.date,
          item.sortid,
          itemList.isMultiPunch ? item.Level4 : '',
          itemList.is_dimension,
          itemList.isCustomGroup,
          itemList.kibanaFilterScript,
          itemList.customGroupId,
          itemList.dataYearCode,
          itemList.MultiPunchNumber,
          itemList.isCustomCalculation,
          itemList.customCalculationId,
          item.customCalculationRowOrder,

          item.customGroupRowOrder
        );
    }

    if (selectedList && callback) {
      this.processSelectedList(selectedList, callback);
    }
  }

  private processSelectedList(
    selectedList: any[],
    callback: (item: any, itemList: string) => void
  ): void {
    for (const item of selectedList) {
      if (item.Level5 && item.Level5.length > 0) {
        for (const itemList of item.Level5) {
          callback(item, itemList);
        }
      } else if (item.Level4 && item.Level4.length > 0) {
        for (const itemList of item.Level4) {
          callback(item, itemList);
        }
      } else if (item.Level3 && item.Level3.length > 0) {
        for (const itemList of item.Level3) {
          callback(item, itemList);
        }
      } else if (item.Level2 && item.Level2.length > 0) {
        for (const itemList of item.Level2) {
          callback(item, itemList);
        }
      }
    }
  }

  handleValueChange(value: boolean) {
    if (value == true) {
      this.left_panel_collapse = true;
    }
  }

  removeCustomGroup(
    variableCode: string[],
    operatorValue: string,
    index: number,
    customGroupRowOrder?: number
  ) {
    let customObject = this.customGroupSelectionsObj;

    let dataObj = this.dataObject['CustomGroup'];
    //let listToUpdate = this.customGroupSelectionsObj;

    let listToUpdate = customObject.filter(
      (a) => a.rowOrder !== customGroupRowOrder
    );
    this.customGroupSelectionsObj = listToUpdate;

    let operatorCheck = this.customGroupSelectionsObj.filter(
      (a) => a.operatorValue == operatorValue
    );
    if (operatorCheck.length == 0) {
      var lastIndex = this.customGroupSelectionsObj.length;
      //enable operators for based on last selection made in the custom Group selected value
      var previousOperatorValue =
        this.customGroupSelectionsObj[lastIndex - 1].operatorValue;
      previousOperatorValue =
        previousOperatorValue == '' ? 'metric' : previousOperatorValue;
      let event: Event = new Event('');

      this.invokeValidationRules(previousOperatorValue);
    }

    //update dropped selected object
    this.dataObject['CustomGroup'] = this.dataObject['CustomGroup'].filter(
      (r) => r.customGroupRowOrder !== customGroupRowOrder
    );

    //update dropped objects
    this.listCustomGroupItemsList = this.listCustomGroupItemsList.filter(
      (r) => r.customGroupRowOrder !== customGroupRowOrder
    );
    if (this.customGroupSelectionsObj.length - 1 < this.customGroupItemIndex){
      this.customGroupItemIndex = this.customGroupSelectionsObj.length - 1;
    }
  }

  customGroupSelection() {
   this.customvariable.emit('Custom Group');
  }

  brandLogo(eventData: { Image: string }) {
    this.ImageURL = eventData.Image;
  }

  ParenthesisCheck(customType: string = 'customGroup') {
    var jsonDataList;
    if (customType == 'customCalculation') {
      jsonDataList = this.customCalculationSelectionsObj;
    } else {
      jsonDataList = this.customGroupSelectionsObj;
    }
    var parenthesisCheck = true;
    var openParenthesisLength = 0;
    var closeParenthesisLength = 0;
    for (var i in jsonDataList) {
      if (jsonDataList[i].type == 'metric') {
        continue;
      } else if (
        jsonDataList[i].type == 'operator' &&
        jsonDataList[i].operatorValue == '('
      ) {
        openParenthesisLength++;
      } else if (
        jsonDataList[i].type == 'operator' &&
        jsonDataList[i].operatorValue == ')'
      ) {
        closeParenthesisLength++;
      }
    }
    if (openParenthesisLength == closeParenthesisLength) {
      parenthesisCheck = true;
    } else {
      parenthesisCheck = false;
    }

    if (parenthesisCheck == true) {
      if (
        jsonDataList != null &&
        jsonDataList != undefined &&
        jsonDataList.length > 0 &&
        jsonDataList[jsonDataList.length - 1].type == 'operator' &&
        jsonDataList[jsonDataList.length - 1].operatorValue != ')'
      ) {
        parenthesisCheck = false;
      }
    }
    return parenthesisCheck;
  }

  validateCustomGroupSummary() {
    var jsonDataList = this.customGroupSelectionsObj;
    var validationResult = {
      isSelectionValid: true,
      invalidObject: {},
    };

    var previousSelection = '';
    for (var i in jsonDataList) {
      if (validationResult.isSelectionValid) {
        var currentSelection = '';

        if (previousSelection == '') {
          if (jsonDataList[i].type == 'metric') {
            previousSelection = 'metric';
          } else {
            previousSelection = jsonDataList[i].operatorValue;
          }
        } else {
          if (jsonDataList[i].type == 'metric') {
            currentSelection = 'metric';
          } else {
            currentSelection = jsonDataList[i].operatorValue;
          }

          for (var j in customGroupsvalidationRules) {
            if (
              previousSelection != null &&
              previousSelection != undefined &&
              previousSelection.toLowerCase() ==
              customGroupsvalidationRules[j].latestSelection.toLowerCase()
            ) {
              if (
                customGroupsvalidationRules[j].itemsToBeEnabled.indexOf(
                  currentSelection.toUpperCase()
                ) >= 0 ||
                customGroupsvalidationRules[j].itemsToBeEnabled.indexOf(
                  currentSelection.toLowerCase()
                ) >= 0
              ) {
              } else {
                validationResult.isSelectionValid = false;
                validationResult.invalidObject = jsonDataList[i];
              }
              break;
            }
          }

          previousSelection = currentSelection;
        }
      } else {
        break;
      }
    }

    return validationResult;
  }

  validateCustomGroupDropDowns() {
    var jsonDataList = this.customGroupSelectionsObj;
    var areAllDropDownsValid = {
      valid: true,
      invalidObject: '',
    };
    if (_.filter(jsonDataList, (s) => s.type == 'metric').length == 0)
      //minimum one metric should be selected
      areAllDropDownsValid.valid = false;
    else {
      for (var i in jsonDataList) {
        if (
          jsonDataList != null &&
          jsonDataList != undefined &&
          jsonDataList.length > 0 &&
          jsonDataList[i].type == 'metric'
        ) {
          if (
            Object.keys(jsonDataList[i].oneObject).length === 0 ||
            jsonDataList[i].oneObject.Data.length == 0
          ) {
            areAllDropDownsValid.valid = false;
            break;
          }
        }
      }
    }
    return areAllDropDownsValid;
  }

  saveCustomGroupSelection() {
    var validationResult = this.validateCustomGroupSummary();
    var parenthesisCheck = this.ParenthesisCheck();
    var areAllDropDownsValid = this.validateCustomGroupDropDowns();

    if (
      validationResult.isSelectionValid &&
      parenthesisCheck &&
      areAllDropDownsValid.valid
    ) {
      if (this.isUpdateCustomGroup) {
        //to update
        this.CustomGroupSaveSelection({
          isValidationPass: true,
          selectionName: this.selectedCustomGroupName,
          isUpdate: this.isUpdateCustomGroup,
          isDuplicate: this.isDuplicateCustomGroup,
        });
      } else this.isSaveCustomGroupSelectionClick = true; //to open save popup
    } else {
      if (!validationResult.isSelectionValid) {
        this.errorPopup(
          "Percentage Variable doesn't seem to be correct.Kindly check the selection."
        );
      } else if (!parenthesisCheck) {
        {
          this.errorPopup(
            "Percentage Variable doesn't seem to be correct.Kindly check if the Parenthesis are placed in the right place."
          );
        }
      } else {
        this.errorPopup(
          "Percentage Variable doesn't seem to be correct.Kindly check the selection."
        );
      }
    }
  }

  generateKibanaFilterScript(queryInput: string) {
    var commandsList = queryInput.trim().split(' ');
    var result = '';

    for (var index = 0; index < commandsList.length; index++) {
      switch (commandsList[index].trim()) {
        case '(':
          result += ' (';
          break;
        case ')':
          result += ' )';
          break;
        case 'AND':
          result += ' &&';
          break;
        case 'OR':
          result += ' ||';
          break;
        case 'NOT':
          result += '!';
          break;
        default: {
          //to handle commands
          var variableCode = commandsList[index].split(':')[0];
          var responseCode = commandsList[index].split(':')[1].split(',');

          result += ' (';
          for (var r = 0; r < responseCode.length; r++) {
            if (r != 0) result += ' || ';
            result += `(doc.containsKey('${variableCode}') && doc['${variableCode}'].size() > 0 
              &&  (((doc['${variableCode}'].value instanceof double||doc['${variableCode}'].value instanceof float) && doc['${variableCode}'].value==Double.parseDouble('${responseCode[r]}'))
                      || ((doc['${variableCode}'].value instanceof long||doc['${variableCode}'].value instanceof int) && doc['${variableCode}'].value==Long.parseLong('${responseCode[r]}'))
                      || (doc['${variableCode}'].value instanceof String && doc['${variableCode}'].value=='${responseCode[r]}')))`;
          }
          result += ')';
        }
      }
    }
    return result;
  }

  generateCustomGroupQueryInput(input: any): any {
    let result = '';
    input.forEach((row: any) => {
      if (row.type === 'metric') {
        if (row.isMultipunch) {
          result += '( ';
          row.variableCode
            // .split('|')
            .split('OR')
            .forEach((variableCode: string, index: number) => {
              const responseCodes = row.responseCode.split('|');
              result += variableCode.trim() + ':' + responseCodes[index];
              if (index !== row.variableCode.split('OR').length - 1)
                result += ' OR ';
            });
          result += ' ) ';
        } else {
          const variableCodes =
            row.variableCode.indexOf('OR') > -1
              ? row.variableCode.trim().split('OR')
              : row.variableCode.split('|');
          const responseCodes = row.responseCode.split('|');
          variableCodes.forEach((variableCode: string, index: number) => {
            //dont add OR to the last variable code
            if (
              row.variableCode.indexOf('OR') > -1 &&
              variableCodes.length - 1 != index
            ) {
              result +=
                variableCode.trim() + ':' + responseCodes[index] + ' OR ';
            } else {
              result += variableCode.trim() + ':' + responseCodes[index] + ' ';
            }
          });

        }
      } else if (row.type === 'operator') {
        result += row.operatorValue + ' ';
      }
    });

    return result;
  }

  generateCustomGroupHtml() {
    this.customGroupSelectionsObj.forEach((r) => {
      this.customGroupHtml += '<div>' + r.displayString + '</div>';
    });
  }

  CustomGroupSaveSelection(eventData: {
    isValidationPass: boolean;
    selectionName: string;
    isUpdate: boolean;
    isDuplicate: boolean;
  }): void {
    if (eventData.isValidationPass) {
      this.Loader.showLoader();

      //generate custom group html to bind in summary
      this.generateCustomGroupHtml();
      const queryInput = this.generateCustomGroupQueryInput(
        this.customGroupSelectionsObj
      );

      //const kibanaQuery = this.generateKibanaQuery(queryInput);
      const kibanaFilterScript = this.generateKibanaFilterScript(queryInput);

      //create selection objects to bind back all the selections
      var tempObj = {
        listCustomGroupItemsList: this.listCustomGroupItemsList, //drag and drop object
        customGroupSelectionsObj: this.customGroupSelectionsObj, //custom group selecito object
        customGroupDataObj: this.dataObject['CustomGroup'],
      };

      this.$unSubscribeSubmitData2 = this.customVariableService
        .saveCustomGroupSelectionData(
          COMMON_CONSTANTS.SnapshotModuleId,
          eventData.selectionName,
          this.selected_client,
          this.selected_study,
          JSON.stringify(kibanaFilterScript),
          /*this.customGroupHtml.toString(),*/
          JSON.stringify(tempObj),
          '',
          '',
          true,
          false,
          0,
          eventData.isUpdate, //true to update , false to save,
          eventData.isDuplicate
        )
        .subscribe((result: results) => {
          // Response is valid
          if (result.HasValidData) {
            let JsonData = JSON.parse(result.Data);

            if (JsonData == 'Updated Successfully') {
              this.isSaveCustomGroupSelectionClick = false; //close popup

              if (this.isUpdateCustomGroup) {
                //on update
                this.errorPopup('Percentage Variable Updated Successfully.', 'Alert');
              }
            } else if (JsonData == 'Update Failed') {
              this.errorPopup(
                'Percentage Variable Update Failed. Please try again later.',
                'Alert'
              );
            } else if (JsonData == 'Record already exists') {
              //on save
              this.errorPopup('Record already exists.', 'Alert');
              this.isSaveCustomGroupSelectionClick = false;
            } else if (JsonData == 'Saved Successfully') {
              this.errorPopup('Saved Successfully.', 'Alert');
              this.isSaveCustomGroupSelectionClick = false;
            } else if (JsonData == 'Save Failed') {
              this.errorPopup('Saved Failed. Please try again later', 'Alert');
              this.isSaveCustomGroupSelectionClick = false;
            } else if (this.isDuplicateCustomGroup) {
              //update newly inserted list in the front end object
              var duplicatedNewRow = JSON.parse(JsonData);
              //if (this.selectedCustomGroupName_Bucket == 'User Defined')
              this.loadCustomGroupUserDefinedData.unshift(duplicatedNewRow);
              // else
              //   this.loadCustomGroupSharedWithMeData.unshift(
              //     duplicatedNewRow
              //   );
              this.errorPopup('Saved Successfully.', 'Alert');
              this.isSaveCustomGroupSelectionClick = false;
            } else {
              this.errorPopup(
                'Error Occured. Please try again later.',
                'Alert'
              );
            }
          }
          //hide the loader
          this.Loader.hideLoader();
          this.usageDataLog(Feature.SaveQuery);
        });
    }
  }

  usageDataLog(feature: string) {
    let summarytext = '';
    if (this.summaryText != undefined && this.summaryText != null)
      summarytext = this.summaryText?.nativeElement.innerText;
    else summarytext = this.selectionSummary.replace(/<[^>]*>/g, '');
    this._usermanagementService.UsageData(
      COMMON_CONSTANTS.SnapshotModuleId.toString(),
      this.selected_client,
      this.selected_study,
      feature,
      summarytext,
      ''
    );
  }

  clearCustomGroupSelection() {
    this.customGroupHtml = '';
    this.customGroupQuery = '';
    this.customGroupRowOrder = 1;
    this.customGroupSelectionsObj = [];
    this.dataObject['CustomGroup'] = [];
    this.listCustomGroupItemsList = [];
    this.disabledCustomGroupOperatorList = [];
    let event: Event = new Event('');
    this.customGroupItemIndex = -1;
    this.bindCustomGroupOperatorClick(event, '(');
  }

  saveCustomCalculationSelection() {

  }

  customCalculationSelection() {

  }

  clearCustomCalculationSelection() {

  }

  removeCustomCalculation(
    variableCode: string[],
    operatorValue: string,
    index: number,
    customCalculationRowOrder?: number
  ) {

  }

  selectedVariableItem(index: number, operatorValue: string) {
    this.customGroupItemIndex = index;
    if (operatorValue != null) {
      let operatorType = operatorValue == '' ? 'metric' : operatorValue;
      this.invokeValidationRules(operatorType);
    }
  }

  bindCustomCalculationOperatorClick(event: Event, operator: string) {

  }

  clearSelection(seletion: string) {
    const entryPointKey =
      seletion === 'CustomCalculation'
        ? 'CustomCalculation'
        : seletion === 'CustomGroup'
          ? 'CustomGroup'
          : seletion === 'Row'
            ? 'Row'
            : seletion === 'RowNesting'
              ? 'RowNesting'
              : seletion === 'Column'
                ? 'Column'
                : seletion == 'ColNesting'
                  ? 'ColNesting'
                  : 'Filter';

    this.selectionObjects.forEach((entry) => {
      if (entry[entryPointKey]) {
        entry[entryPointKey].forEach((data: Data) => {
          data.Data.forEach((child: Child) => {
            child.CheckboxStatus = false;
          });
        });
      }
    });

    if (seletion == 'CustomCalculation') {
      this.listCustomCalculationItemsList = [];
      this.clearCustomCalculationSelection();
    }
    if (seletion == 'CustomGroup') {
      //this.dataObject.CustomGroup = [];
      this.listCustomGroupItemsList = [];
      this.clearCustomGroupSelection();
      //this.getLimitedDataPreview();
    }
    if (seletion == 'Row') {
      this.dataObject.Row = [];
      this.dataObject.RowNesting = [];
      this.getLimitedDataPreview();
    }
    if (seletion == 'Column') {
      this.dataObject.Column = [];
      this.dataObject.ColNesting = [];
      this.getLimitedDataPreview();
    }
    if (seletion == 'Filter') {
      this.dataObject.Filter = [];
      this.getLimitedDataPreview();
    }
    const existingIndex = this.selectionObjects.findIndex(
      (entry) => entry[entryPointKey]
    );
    if (existingIndex !== -1) {
      // Remove the entire entry based on entryPointKey
      this.selectionObjects.splice(existingIndex, 1);
    }

    if (seletion == 'Row') {
      this.listAItemsList = [];
      this.listBItemsList = [];
      this.isSelectAll = false;
      this.sidePanelDisplay = false;
      this.sidePanelLevel1Display = false;
      this.sidePanelLevel2Display = false;
      this.sidePanelLevel3Display = false;
      this.sidePanelLevel4Display = false;
      this.sidePanelLevel5Display = false;

      this.sidePanelNestingDisplay = false;
      this.sidePanelLevel1NestingDisplay = false;
      this.sidePanelLevel2NestingDisplay = false;
      this.sidePanelLevel3NestingDisplay = false;
      this.sidePanelLevel4NestingDisplay = false;
      this.sidePanelLevel5NestingDisplay = false;
    } else if (seletion == 'Column') {
      this.listCItemsList = [];
      this.listDItemsList = [];
      this.isSelectAll = false;

      this.columnPanelDisplay = false;
      this.columnPanelLevel1Display = false;
      this.columnPanelLevel2Display = false;
      this.columnPanelLevel3Display = false;
      this.columnPanelLevel4Display = false;
      this.columnPanelLevel5Display = false;

      this.columnNestingPanelDispaly = false;
      this.columnPanelNestLeve11Display = false;
      this.columnPanelNestLeve12Display = false;
      this.columnPanelNestLeve13Display = false;
      this.columnPanelNestLeve14Display = false;
      this.columnPanelNestLeve15Display = false;
    } else if (seletion == 'Filter') {
      this.listEItemsList = [];
      this.isSelectAll = false;
      this.filterPanelDisplay = false;
      this.filterPanelLevel1Display = false;
      this.filterPanelLevel2Display = false;
      this.filterPanelLevel3Display = false;
      this.filterPanelLevel4Display = false;
      this.filterPanelLevel5Display = false;
    }
  }

  onSearchLevel(level: string) {

  }



  getHighlightStatusNew(
    parent: string,
    child: string,
    rowKey: string,
    headerText: string,
    customGroupRowOrder?: number
  ): boolean {
    return false;
  }

  isCreateOrEditCustomCalculation() {
    if (
      this.isCreateCustomCalculation ||
      this.isUpdateCustomCalculation ||
      this.isDuplicateCustomCalculation
    )
      return true;
    else return false;
  }

  getCheckboxStatusNew(
    parent: string,
    child: string,
    rowKey: string,
    headerText: string,
    customCalculationRowOrder?: number,
    customGroupRowOrder?: number,
  ): boolean {
    const entryPointKey = this.getEntryPointKey(rowKey);

    var entry;
    if (this.isCreateOrEditCustomCalculation()) {
      entry = this.dataObject[entryPointKey].find(
        (entry) =>
          entry &&
          entry.Data &&
          entry.Data.length > 0 &&
          entry.Parent === headerText &&
          (customCalculationRowOrder === undefined
            ? true
            : entry.customCalculationRowOrder === customCalculationRowOrder)
      );
    }
    else if (this.isCreateOrEditCustomGroup()) {
      entry = this.dataObject[entryPointKey].find(
        (entry) =>
          entry &&
          entry.Data &&
          entry.Data.length > 0 &&
          entry.Parent === headerText &&
          (customGroupRowOrder === undefined
            ? true
            : entry.customGroupRowOrder === customGroupRowOrder)
      );
    } else {
      entry = this.dataObject[entryPointKey].find(
        (entry: checkboxSelectionWithParent) =>
          entry &&
          entry.Data &&
          entry.Data.length > 0 &&
          entry.Parent === headerText
      );
    }
    if (entry && entry.Data) {
      return entry.Data.some(
        (data: checkboxSelection) => data.Child === child && data.CheckboxStatus
      );
    }
    return false;
  }

  invokeValidationRulesCustomCalc(latestSelection: string, firsttime: number = 0): void {
    const newValue = latestSelection;
    //$('#DummyForSelectionWatching').attr('latestSelection');

    for (const rule of customCalculationvalidationRules) {
      if (
        rule.latestSelection.toLowerCase() === latestSelection?.toLowerCase()
      ) {
        //const enabledItems = rule.itemsToBeEnabled;
        let disabledItems = rule.itemsToBeDisabled;
        if (firsttime == 1)
          disabledItems.push("NUMERAL")

        // this.updateButtonState(enabledItems, false);
        this.updateButtonStateCustomCalc(disabledItems, true);
      }
    }
  }

  updateButtonStateCustomCalc(items: string[], isDisabled: boolean): void {
    this.disabledCustomCalculationOperatorList = [];

    for (const item of items) {
      const buttonClass = this.buttonCssClassMap[item];

      this.disabledCustomCalculationOperatorList.push(buttonClass);
     
    }
  }

  private setCustomCalculationRowOrder(
    headerText: string,
    ismultipunch: boolean,
    currentVariableCode: string,
    CustomCalculationRowOrder?: number
  ) {
    var filteredObj: any = [];

    //if (headerText != null && headerText != '' && ismultipunch) {
    filteredObj = _.filter(
      this.customCalculationSelectionsObj,
      (data) =>
        data.headerText == headerText &&
        data.rowOrder == CustomCalculationRowOrder
    );


    // Get the maximum rowOrder value from customGroupSelectionsObj
    const maxRowOrder =
      _.maxBy(this.customCalculationSelectionsObj, 'rowOrder')?.rowOrder ||
      this.customCalculationRowOrder;

    var lastRowOrder = maxRowOrder;

    lastRowOrder = lastRowOrder == undefined ? 0 : lastRowOrder;
    //if this object already exists then take the existing customGroup order if not assign based on maximum value
    this.customCalculationRowOrder =
      filteredObj.length > 0 ? filteredObj[0].rowOrder : lastRowOrder + 1;
  }

  getPanelDropList(entryPointKey: string) {
    if (entryPointKey == 'Row') return this.listAItemsList;
    if (entryPointKey == 'Column') return this.listCItemsList;
    if (entryPointKey == 'RowNesting') return this.listBItemsList;
    if (entryPointKey == 'ColNesting') return this.listDItemsList;
    if (entryPointKey == 'Filter') return this.listEItemsList;
    if (entryPointKey == 'CustomGroup') return this.listCustomGroupItemsList;
    if (entryPointKey == 'CustomCalculation')
      return this.listCustomCalculationItemsList;
    return this.listDItemsList;
  }

  sortingDataObject(entryPointKey: string) {
    var DroppedList = this.getPanelDropList(entryPointKey);
    // Create a map for quick lookup of index based on name in list1
    const indexMap = new Map(
      DroppedList.map((item, index) => [item.Level1, index])
    );
    // Sort list2 based on the order of names in list1
    this.dataObject[entryPointKey as keyof DataObject] = [
      ...this.dataObject[entryPointKey as keyof DataObject],
    ].sort((a, b) => {
      const indexA = indexMap.get(a.Parent);
      const indexB = indexMap.get(b.Parent);
      if (indexA !== undefined && indexB !== undefined) {
        return indexA - indexB;
      } else {
        return 0; // handle cases where names in list2 do not exist in list1
      }
    });
  }

  getCustomGroupVariableCode(filteredDataObj: any) {
    let result: any = {
      variableCode: '',
      responseCode: '',
    };

    if (filteredDataObj != undefined && filteredDataObj.length > 0) {
      var filteredData = filteredDataObj;
      var distinctVariableCode: any = [];
      filteredData.forEach((element: any) => {
        if (element && Array.isArray(element.Data)) {
          element.Data.forEach((item: any) => {
            if (item && item.variablecode) {
              distinctVariableCode.push(item.variablecode);
            }
          });
        }
      });
      distinctVariableCode = _.uniq(distinctVariableCode);
      distinctVariableCode.forEach((variableCode: any) => {
        filteredData.forEach((element: any) => {
          if (element && Array.isArray(element.Data)) {
            const responseCodes = element.Data.filter(
              (obj: any) => obj.variablecode === variableCode
            )
              .map((obj: any) => obj.responsecode)
              .join(',');
            if (!result.variableCode) {
              result.variableCode = variableCode;
              result.responseCode = responseCodes;
            } else {
              result.variableCode += ' OR ' + variableCode;
              result.responseCode += '|' + responseCodes;
            }
          }
        });
      });
    }

    return result; // Add return statement here
  }

  getShortNameOfTextForcustomGroups(targetText: string): string {
    let shortcetegoryName = '';
    if (targetText == null) return '';
    const targetTextArr = targetText.split(' ');

    if (targetTextArr && targetTextArr.length > 2) {
      if (
        targetTextArr[0] &&
        targetTextArr[0] === targetTextArr[0].toUpperCase()
      ) {
        shortcetegoryName += targetTextArr[0];
      } else {
        for (const word of targetTextArr) {
          shortcetegoryName += word.charAt(0);
        }
      }
    } else {
      shortcetegoryName = targetText;
    }

    return shortcetegoryName;
  }


  private generateShortNamesForChild(
    child: string,
    transformedLastValues: any[]
  ) {
    var childValue = child.split('|');
    childValue.splice(-1, 1); //remove last selected value

    const shortNameChildList = childValue.map((value: any) =>
      this.getShortNameOfTextForcustomGroups(value)
    );

    const shortNameLastValueList = transformedLastValues.map(
      (value: any) => value[0]
    );
    var transformedValue = [
      ...shortNameChildList,
      shortNameLastValueList.join(', '),
    ].join(' > ');
    return transformedValue;
  }

  getCustomGroupsDisplayString(rowsObj: any, child: string): string {
    var finalResult = '';

    if (rowsObj != undefined && rowsObj.length > 0) {
      var rows = rowsObj[0];
      // Initialize an object to store groups
      let groupedRows: { [key: string]: any[] } = {};

      // Group rows based on the second place from last after splitting by '|'
      rows.Data.forEach((row: any) => {
        const childValues = row.Child.split('|');
        if (childValues.length >= 2) {
          const groupKey = childValues[childValues.length - 2];
          if (!groupedRows[groupKey]) {
            groupedRows[groupKey] = [];
          }
          groupedRows[groupKey].push(row);
        }
      });

      // Iterate over each group and perform the operations
      for (const groupKey in groupedRows) {
        if (finalResult != '')
          //add seperator if values are already there
          finalResult = finalResult + ' | ';
        let filteredRows = groupedRows[groupKey];
        //get all selected values option
        let transformedLastValues = filteredRows.map((row: any) => {
          const childValues = row.Child.split('|');

          const lastValue = childValues.pop()?.split(',') || [];
          return lastValue;
        });

        //assuming in all rows child only last value will vary
        // var childValue = rows.Data[0].Child.split('|');
        var transformedValue = this.generateShortNamesForChild(
          filteredRows[0].Child,
          transformedLastValues
        );
        finalResult += transformedValue;
      }
    } else {
      finalResult = this.generateShortNamesForChild(child, []);
    }
    return finalResult;
  }


  private generateCustomCalculationObjects(
    parent: string,
    child: string,
    responseCode: string,
    currentVariableCode: string,
    headerText: string,
    ismultipunch: boolean,
    customCalculationRowOrder?: number
  ) {
    //custom group row order =0 by default if it is added newly...if it is zero ,assign newly generated row order
    customCalculationRowOrder =
      customCalculationRowOrder == 0
        ? this.customCalculationRowOrder
        : customCalculationRowOrder;
    this.invokeValidationRulesCustomCalc('metric');
    //each object of customgroup selection has seperate row order
    const rowOrderIndex = this.customCalculationSelectionsObj.findIndex(
      (entry) => entry.rowOrder === customCalculationRowOrder
    );

    //filter parent data..(selected data of current metirc)
    var filteredParentData = [...this.dataObject['CustomCalculation']].filter(
      (s) => {
        if (
          s.Parent === parent &&
          s.customCalculationRowOrder == customCalculationRowOrder
        )
          return true; // Keep the element in the filtered array

        return false; // Exclude the element from the filtered array
      }
    );

    //update droped custom group metrics...unique id for metric which is added newly with default custom group row order
    this.listCustomCalculationItemsList.map((s) => {
      if (s.Level1 === parent && s.customCalculationRowOrder == 0) {
        s.customCalculationRowOrder = customCalculationRowOrder;
        return true; // Keep the element in the filtered array
      }
      return false; // Exclude the element from the filtered array
    });

    // var filteredParentData = _.filter(
    //   this.dataObject['CustomGroup'],
    //   (s) => s.Parent == parent
    // );

    var currentLevelFiteredData;
    if (filteredParentData.length == 0) {
      //if all options removed then remove parent also
      //this.customGroupSelectionsObj.splice(rowOrderIndex, 1);
      //var parentIndex = -1;
      // for (let i = 0; i < this.dataObject['CustomCalculation'].length; i++) {
      //   let entry = this.dataObject['CustomCalculation'][i];

      var index = this.dataObject['CustomCalculation'].findIndex(
        (entry) => entry.customCalculationRowOrder === customCalculationRowOrder
      );

      if (index > -1) {
        this.dataObject['CustomCalculation'].splice(index, 1);
      }

      // // Find the index of the object in the 'data' array with the specified customGroupRowOrder
      // let index = entry.Data.findIndex(
      //   (subEntry) =>
      //     subEntry.customCalculationRowOrder === customCalculationRowOrder
      // );

      // if (index !== -1) {
      //   // foundIndex = index;
      //   parentIndex = i;
      //   break;
      // }
      // }
      // this.listCustomCalculationItemsList.splice(parentIndex, 1);---------->
      // // Remove selected object from dragged metric
      // var index = this.dataObject['CustomCalculation'].findIndex(
      //   (entry) => entry.customCalculationRowOrder === customCalculationRowOrder
      // );

      // if (parentIndex > -1) {
      //   this.dataObject['CustomCalculation'].splice(parentIndex, 1);
      // }

      //check wethere there are extra unselected duplicate metrics

      var filteredMetricData = this.listCustomCalculationItemsList.filter(
        (entry) => entry.Level1 === headerText
      );
      var filteredSelectedMetricData = this.dataObject[
        'CustomCalculation'
      ].filter((entry) => entry.Parent === headerText);

      //if more than 1 unselected duplicated metric is present remove it from the dragged list
      if (filteredMetricData.length - filteredSelectedMetricData.length > 1) {
        //remove from dragged metric list
        index = this.listCustomCalculationItemsList.findIndex(
          (entry) =>
            entry.customCalculationRowOrder === customCalculationRowOrder
        );
        this.listCustomCalculationItemsList.splice(index, 1);
        this.errorPopup(
          'Removing a duplicate metric which doesnot have a selection in it'
        );
        return;
      }
    } else {
      currentLevelFiteredData = { ...filteredParentData[0] };
      // //filteredParentData[0].Data = this.filterDataByLastElement(
      // currentLevelFiteredData.Data = this.filterDataByLastElement(
      //   filteredParentData[0].Data,
      //   child
      // );
    }

    var currentSelectionObj = { ...filteredParentData[0] };

    if (filteredParentData.length == 0) {
      //as custom selections are single select remove the deselcted metric from object
      var index = this.customCalculationSelectionsObj.findIndex(
        (subEntry) => subEntry.rowOrder === customCalculationRowOrder
      );
      this.customCalculationSelectionsObj.splice(index, 1);
      return;
    }

    var codeObj = this.getCustomGroupVariableCode(filteredParentData);
    //generate displayString
    var displayString = this.getCustomGroupsDisplayString(
      filteredParentData,
      child
    );
    var tempVariableCode = codeObj.variableCode;
    var tempResponseCode = codeObj.responseCode;
    // var responseCode = this.getCustomGroupResponseCode(
    //   filteredParentData[0],
    //   tempVariableCode
    // );
    // var displayString = this.getCustomGroupsDisplayString(currentSelectionObj);
    // var responseCode = this.getCustomGroupResponseCode(
    //   currentSelectionObj,
    //   tempVariableCode
    // );

    const newEntry = {
      displayString: displayString,
      oneObject: currentSelectionObj,
      operatorValue: '',
      type: 'metric',
      rowOrder: customCalculationRowOrder,
      variableCode: tempVariableCode,
      responseCode: tempResponseCode,
      headerText: headerText,
      isMultipunch: ismultipunch,
    };

    //if its entry os not there insert as a new row
    if (rowOrderIndex == -1) {
      if (this.customCalculationItemIndex == -1) {
        this.customCalculationSelectionsObj.push(newEntry);
        this.customCalculationItemIndex =
          this.customCalculationSelectionsObj.length - 1;
      } else {
        this.customCalculationItemIndex = this.customCalculationItemIndex + 1;
        this.customCalculationSelectionsObj.splice(
          this.customCalculationItemIndex,
          0,
          newEntry
        );
      }
    } //else overwrite exisinting row
    else {
      this.customCalculationSelectionsObj[rowOrderIndex] = newEntry;
      this.customCalculationItemIndex =
        this.customCalculationSelectionsObj.length - 1;
    }
  }

  public updateResponseDataRowOrderCustomCalc(
    responseData: any,
    customCalculationRowOrder?: number
  ) {
    if (responseData.length > 0) {
      //custom group row order =0 by default if it is added newly...if it is zero ,assign newly generated row order
      var tempCustomCalculationOrder =
        customCalculationRowOrder != undefined && customCalculationRowOrder > 0
          ? customCalculationRowOrder
          : this.customCalculationRowOrder;
      //update current custom group row order to the leftpanel response data only then click even
      responseData.map((r: any) => {
        r.customCalculationRowOrder = tempCustomCalculationOrder;
        return r;
      });
    }
  }

  getCheckedCount(
    headerText: string,
    selection: string,
    child: string,
    customGroupRowOrder?: number,
    customCalculationRowOrder?: number
  ): number {
    const entryPointKey = this.getEntryPointKey(selection);

    var existingIndex;
    if (selection == 'CustomCalculation') {
      existingIndex = this.dataObject[
        entryPointKey as keyof DataObject
      ].findIndex(
        (entry) =>
          entry.Parent === headerText &&
          entry.customCalculationRowOrder == customCalculationRowOrder
      );
    } else if (selection == 'CustomGroup') {
      existingIndex = this.dataObject[
        entryPointKey as keyof DataObject
      ].findIndex(
        (entry) =>
          entry.Parent === headerText &&
          entry.customGroupRowOrder == customGroupRowOrder
      );
    } else {
      existingIndex = this.dataObject[
        entryPointKey as keyof DataObject
      ].findIndex((entry) => entry.Parent === headerText);
    }
    let checkedCount = 0;

    if (existingIndex !== -1) {
      const existingEntry =
        this.dataObject[entryPointKey as keyof DataObject][existingIndex];

      const filteredData = existingEntry.Data.filter((childEntry) => {
        if (child.split('|').length - 1 == 3) {
          return (
            childEntry.Child.split('|')[0] === child.split('|')[0] &&
            childEntry.Child.split('|')[1] === child.split('|')[1] &&
            childEntry.Child.split('|')[2] === child.split('|')[2]
          );
        } else if (child.split('|').length - 1 == 2) {
          return (
            childEntry.Child.split('|')[0] === child.split('|')[0] &&
            childEntry.Child.split('|')[1] === child.split('|')[1]
          );
        } else if (child.split('|').length - 1 == 1) {
          return childEntry.Child.split('|')[0] === child.split('|')[0];
        } else {
          return childEntry.Child.split('|')[0] === child.split('|')[0];
        }
      });

      for (const childEntry of filteredData) {
        if (childEntry.CheckboxStatus) {
          checkedCount++;
        }
      }
    }

    return checkedCount;
  }

  private generateCustomGroupObjects(
    parent: string,
    child: string,
    responseCode: string,
    currentVariableCode: string,
    headerText: string,
    ismultipunch: boolean,
    customGroupRowOrder?: number
  ) {
    //custom group row order =0 by default if it is added newly...if it is zero ,assign newly generated row order
    customGroupRowOrder =
      customGroupRowOrder == 0 ? this.customGroupRowOrder : customGroupRowOrder;
    this.invokeValidationRules('metric');
    //each object of customgroup selection has seperate row order
    const rowOrderIndex = this.customGroupSelectionsObj.findIndex(
      (entry) => entry.rowOrder === customGroupRowOrder
    );

    //filter parent data..(selected data of current metirc)
    var filteredParentData = [...this.dataObject['CustomGroup']].filter((s) => {
      if (s.Parent === parent && s.customGroupRowOrder == customGroupRowOrder)
        return true; // Keep the element in the filtered array

      return false; // Exclude the element from the filtered array
    });

    //update droped custom group metrics...unique id for metric which is added newly with default custom group row order
    this.listCustomGroupItemsList.map((s) => {
      if (s.Level1 === parent && s.customGroupRowOrder == 0) {
        s.customGroupRowOrder = customGroupRowOrder;
        return true; // Keep the element in the filtered array
      }
      return false; // Exclude the element from the filtered array
    });

    // var filteredParentData = _.filter(
    //   this.dataObject['CustomGroup'],
    //   (s) => s.Parent == parent
    // );

    var currentLevelFiteredData;
    if (filteredParentData.length == 0) {
      //if all options removed then remove parent also
      //this.customGroupSelectionsObj.splice(rowOrderIndex, 1);

      // Remove selected object from dragged metric
      var index = this.dataObject['CustomGroup'].findIndex(
        (entry) => entry.customGroupRowOrder === customGroupRowOrder
      );

      if (index > -1) {
        this.dataObject['CustomGroup'].splice(index, 1);
      }

      //check wethere there are extra unselected duplicate metrics

      var filteredMetricData = this.listCustomGroupItemsList.filter(
        (entry) => entry.Level1 === headerText
      );
      var filteredSelectedMetricData = this.dataObject['CustomGroup'].filter(
        (entry) => entry.Parent === headerText
      );

      //if more than 1 unselected duplicated metric is present remove it from the dragged list
      if (filteredMetricData.length - filteredSelectedMetricData.length > 1) {
        //remove from dragged metric list
        index = this.listCustomGroupItemsList.findIndex(
          (entry) => entry.customGroupRowOrder === customGroupRowOrder
        );
        this.listCustomGroupItemsList.splice(index, 1);
        this.errorPopup(
          'Removing a duplicate metric which doesnot have a selection in it'
        );
        return;
      }
    } else {
      currentLevelFiteredData = { ...filteredParentData[0] };
      // //filteredParentData[0].Data = this.filterDataByLastElement(
      // currentLevelFiteredData.Data = this.filterDataByLastElement(
      //   filteredParentData[0].Data,
      //   child
      // );
    }

    var currentSelectionObj = { ...filteredParentData[0] };
    // //current selected object
    // var currentSelectionObj =
    //   rowOrderIndex > -1
    //     ? currentLevelFiteredData
    //     : this.dataObject['CustomGroup'][
    //         this.dataObject['CustomGroup'].length - 1
    //       ];

    //generate variableCode to add to every row of customgroupSelectionObj
    // var tempVariableCode: string[] = [];
    // if (ismultipunch) {
    //   //to handle multipunchCases
    //   if (rowOrderIndex == -1)
    //     tempVariableCode = _.map(
    //       currentSelectionObj.Data,
    //       (row) => row.variablecode
    //     );
    //   else {
    //     tempVariableCode =
    //       this.customGroupSelectionsObj[rowOrderIndex].variableCode;
    //     tempVariableCode.push(currentVariableCode);
    //   }
    // }
    // else
    // {
    //     tempVariableCode = this.getCustomGroupVariableCode(
    //       filteredParentData[0],
    //     );
    //   // tempVariableCode = this.getCustomGroupVariableCode(
    //   //   filteredParentData[0],
    //   //   tempVariableCode
    //   // );
    // }
    // // } else tempVariableCode.push(currentVariableCode);

    var codeObj = this.getCustomGroupVariableCode(filteredParentData);
    //generate displayString
    var displayString = this.getCustomGroupsDisplayString(
      filteredParentData,
      child
    );
    var tempVariableCode = codeObj.variableCode;
    var tempResponseCode = codeObj.responseCode;
    // var responseCode = this.getCustomGroupResponseCode(
    //   filteredParentData[0],
    //   tempVariableCode
    // );
    // var displayString = this.getCustomGroupsDisplayString(currentSelectionObj);
    // var responseCode = this.getCustomGroupResponseCode(
    //   currentSelectionObj,
    //   tempVariableCode
    // );

    const newEntry = {
      displayString: displayString,
      oneObject: currentSelectionObj,
      operatorValue: '',
      type: 'metric',
      rowOrder: customGroupRowOrder,
      variableCode: tempVariableCode,
      responseCode: tempResponseCode,
      headerText: headerText,
      isMultipunch: ismultipunch,
    };

    //if its entry os not there insert as a new row
    if (rowOrderIndex == -1) {
      if (this.customGroupItemIndex == -1) {
        this.customGroupSelectionsObj.push(newEntry);
        this.customGroupItemIndex = this.customGroupSelectionsObj.length - 1;
      } else {
        this.customGroupItemIndex = this.customGroupItemIndex + 1;
        this.customGroupSelectionsObj.splice(
          this.customGroupItemIndex,
          0,
          newEntry
        );
      }
    } //else overwrite exisinting row
    else {
      this.customGroupSelectionsObj[rowOrderIndex] = newEntry;
      // if(rowOrderIndex!=this.customGroupItemIndex){
      //  this.customGroupItemIndex = this.customGroupSelectionsObj.length - 1;
      this.customGroupItemIndex = rowOrderIndex;
      //}
    }
  }

  public updateResponseDataRowOrder(
    responseData: any,
    customGroupRowOrder?: number
  ) {
    if (responseData.length > 0) {
      //custom group row order =0 by default if it is added newly...if it is zero ,assign newly generated row order
      var tempCustomGroupOrder =
        customGroupRowOrder != undefined && customGroupRowOrder > 0
          ? customGroupRowOrder
          : this.customGroupRowOrder;
      //update current custom group row order to the leftpanel response data only then click even
      responseData.map((r: any) => {
        r.customGroupRowOrder = tempCustomGroupOrder;
        return r;
      });
    }
  }

  public checkboxClick(
    selection: string,
    is_entrypoint: boolean,
    parent: string,
    child: string,
    status: string,
    headerText: string,
    isSelectAllClick: boolean,
    variableCode: string,
    responseCode: string,
    derivedVariable: boolean,
    calcType: string,
    sort_id: number,
    isMultiPunch: boolean,
    year_code: string,
    date: string,
    objectSortId: number,
    multiPunchMetric: string,
    is_dimension: boolean,
    isCustomGroup: boolean,
    kibanaFilterScript: string,
    customGroupId: string,
    dataYearCode: string,
    MultiPunchNumber: number,
    isCustomCalculation: boolean,
    customCalculationId: string,
    customCalculationRowOrder?: number,
    customGroupRowOrder?: number
  ): void {
    if (
      (selection == 'Filter' && this.selectedDataFormat == 'Aggregated') ||
      (selection == 'CustomCalculation')
    ) {
      const entryPointKey = this.getEntryPointKey(selection);
      let existingIndex = -1;
      if (selection == 'CustomCalculation') {
        existingIndex = this.dataObject[
          entryPointKey as keyof DataObject
        ].findIndex((entry) => entry.Parent === headerText && (entry.customCalculationRowOrder === customCalculationRowOrder));
      }
      else {
        existingIndex = this.dataObject[
          entryPointKey as keyof DataObject
        ].findIndex((entry) => entry.Parent === headerText);
      }
      if (existingIndex != -1) {
        const existingEntry =
          this.dataObject[entryPointKey as keyof DataObject][existingIndex];
        let existingChildIndex = -1;
        existingChildIndex = existingEntry.Data.findIndex(
          (existingChild) => existingChild.Child === child
        );
        if (existingChildIndex !== -1) {
          existingEntry.Data[existingChildIndex].CheckboxStatus =
            !existingEntry.Data[existingChildIndex].CheckboxStatus;

          if (!existingEntry.Data[existingChildIndex].CheckboxStatus) {
            existingEntry.Data.splice(existingChildIndex, 1);

            if (existingEntry.Data.length === 0) {
              this.dataObject[entryPointKey as keyof DataObject].splice(
                existingIndex,
                1
              );

              if (
                this.dataObject[entryPointKey as keyof DataObject].length === 0
              ) {
                this.dataObject[entryPointKey as keyof DataObject] = [];
              }
            }
          }
        } else {
          existingEntry.Data = [];
          existingEntry.Data.push({
            Child: child,
            CheckboxStatus: true,
            responsecode: responseCode,
            variablecode: variableCode,
            isDerviedVariable: derivedVariable,
            CalculationType: calcType,
            sort_id: sort_id,
            year_response_code: year_code == null ? '' : year_code,
            Date: date,
            year_variable_code: dataYearCode,
            isMultiPunch: MultiPunchNumber,
            isTimePeriod: parent == 'Time Period' ? true : false,
            isMarket:
              parent.toLowerCase() == 'markets' && is_dimension
                ? false
                : parent.toLowerCase() == 'markets'
                  ? true
                  : false,
            multiPunchMetric: multiPunchMetric,
            MultiPunchNumber: MultiPunchNumber,
            isCustomGroup: isCustomGroup,
            kibanaFilterScript: kibanaFilterScript,
            customGroupId: customGroupId,
            customGroupRowOrder: customGroupRowOrder,
            isRolling:
              child.indexOf('3MMA') > -1 ||
                child.indexOf('4MMA') > -1 ||
                child.indexOf('6MMA') > -1 ||
                child.indexOf('12MMA') > -1
                ? true
                : false,
            isCustomCalculation: isCustomCalculation,
            customCalculationId: customCalculationId,
            customCalculationRowOrder: customCalculationRowOrder,
          });
          existingEntry.Data.sort((a, b) => a.sort_id - b.sort_id);
          if (parent == 'Time Period') {
            existingEntry.Data.sort((a, b) =>
              a.year_response_code.localeCompare(b.year_response_code)
            );
          }
        }
      } else {
        if (this.isCreateCustomCalculation) {
          //if(existingIndex==-1){//if it is newly added then find new custom calculation row order

          this.setCustomCalculationRowOrder(
            headerText,
            isMultiPunch,
            variableCode,
            customCalculationRowOrder
          );
          //}
        }

        const tempEntry: checkboxSelectionWithParent = {
          customGroupRowOrder: this.customGroupRowOrder,
          customCalculationRowOrder: this.customCalculationRowOrder,
          Parent: headerText,
          SortId: objectSortId,
          isMultiPunch: isMultiPunch,
          MultiPunchNumber: MultiPunchNumber,
          isCustomGroup: isCustomGroup,
          isCustomCalculation: isCustomCalculation,
          customGroupId: customGroupId,
          customCalculationId: customCalculationId,
          brandanalysisCode: 0,
          is_entrypoint: is_entrypoint,
          Data: [
            {
              Child: child,
              CheckboxStatus: true,
              responsecode: responseCode,
              variablecode: variableCode,
              isDerviedVariable: derivedVariable,
              CalculationType: calcType,
              sort_id: sort_id,
              year_response_code: year_code == null ? '' : year_code,
              Date: date,
              year_variable_code: dataYearCode,
              isMultiPunch: MultiPunchNumber,
              isTimePeriod: parent == 'Time Period' ? true : false,
              isMarket:
                parent.toLowerCase() == 'markets' && is_dimension
                  ? false
                  : parent.toLowerCase() == 'markets'
                    ? true
                    : false,
              multiPunchMetric: multiPunchMetric,
              MultiPunchNumber: MultiPunchNumber,
              isCustomGroup: isCustomGroup,
              kibanaFilterScript: kibanaFilterScript,
              customGroupId: customGroupId,
              customGroupRowOrder: customGroupRowOrder,
              isRolling:
                child.indexOf('3MMA') > -1 ||
                  child.indexOf('4MMA') > -1 ||
                  child.indexOf('6MMA') > -1 ||
                  child.indexOf('12MMA') > -1
                  ? true
                  : false,
              isCustomCalculation: isCustomCalculation,
              customCalculationId: customCalculationId,
              customCalculationRowOrder: this.customCalculationRowOrder,
            },
          ],
        };
        tempEntry.Data.sort((a, b) => a.sort_id - b.sort_id);
        if (parent == 'Time Period') {
          tempEntry.Data.sort((a, b) =>
            a.year_response_code.localeCompare(b.year_response_code)
          );
        }
        this.dataObject[entryPointKey as keyof DataObject].push(tempEntry);
      }

      this.sortingDataObject(entryPointKey);
      this.getLimitedDataPreview();
      // if (this.isCreateCustomCalculation) {
      //   this.setCustomCalculationRowOrder(
      //     headerText,
      //     isMultiPunch,
      //     variableCode,
      //     customCalculationRowOrder
      //   );
      // }
      //   if (selection == 'CustomCalculation') {


      if (selection == 'CustomCalculation') {
        //generate unique id for each selection
        // this.setCustomGroupRowOrder(headerText, isMultiPunch, variableCode);
        this.generateCustomCalculationObjects(
          headerText,
          child,
          responseCode,
          variableCode,
          headerText,
          isMultiPunch,
          customCalculationRowOrder
        );

        this.updateResponseDataRowOrderCustomCalc(
          this.responseData1,
          customCalculationRowOrder
        );
        this.updateResponseDataRowOrderCustomCalc(
          this.responseData2,
          customCalculationRowOrder
        );
        this.updateResponseDataRowOrderCustomCalc(
          this.responseData3,
          customCalculationRowOrder
        );
        this.updateResponseDataRowOrderCustomCalc(
          this.responseData4,
          customCalculationRowOrder
        );
        this.updateResponseDataRowOrderCustomCalc(
          this.responseData5,
          customCalculationRowOrder
        );
      }

    }
    else {
      if (isSelectAllClick == false) {
        this.checkStatus = false;
      }
      const entryPointKey = this.getEntryPointKey(selection);
      let existingIndex = -1;
      if (isCustomCalculation || this.isCreateCustomCalculation) {
        existingIndex = this.dataObject[
          entryPointKey as keyof DataObject
        ].findIndex(
          (entry) =>
            entry.Parent === headerText &&
            entry.customCalculationId == customCalculationId &&
            entry.customCalculationRowOrder === customCalculationRowOrder
        );
      } else if (this.isCreateCustomGroup || isCustomGroup) {
        existingIndex = this.dataObject[
          entryPointKey as keyof DataObject
        ].findIndex(
          (entry) =>
            entry.Parent === headerText &&
            entry.customGroupId == customGroupId &&
            entry.customGroupRowOrder === customGroupRowOrder
        );
      } else {
        existingIndex = this.dataObject[
          entryPointKey as keyof DataObject
        ].findIndex(
          (entry) => entry.Parent === headerText //&&
          //entry.customGroupRowOrder === customGroupRowOrder
        );
      }
      if (this.isCreateCustomCalculation) {
        this.setCustomCalculationRowOrder(
          headerText,
          isMultiPunch,
          variableCode,
          customCalculationRowOrder
        );
      } else {
        //generate unique id for each selection
        this.setCustomGroupRowOrder(
          headerText,
          isMultiPunch,
          variableCode,
          customGroupRowOrder
        );
      }

      if (existingIndex != -1) {
        const existingEntry =
          this.dataObject[entryPointKey as keyof DataObject][existingIndex];
        let existingChildIndex = -1;
        if (isCustomGroup) {
          existingChildIndex = existingEntry.Data.findIndex(
            (existingChild) =>
              existingChild.Child === child &&
              existingChild.customGroupId == customGroupId
          );
        } else {
          existingChildIndex = existingEntry.Data.findIndex(
            (existingChild) => existingChild.Child === child
          );
        }
        if (existingChildIndex !== -1) {
          if (isSelectAllClick) {
            existingEntry.Data[existingChildIndex].CheckboxStatus = true;
          }
          else if (status === 'false') {
            existingEntry.Data[existingChildIndex].CheckboxStatus =
              !existingEntry.Data[existingChildIndex].CheckboxStatus;
          } else if (!this.checkStatus) {
            existingEntry.Data[existingChildIndex].CheckboxStatus = false;
          } else {
            existingEntry.Data[existingChildIndex].CheckboxStatus = true;
          }

          if (!existingEntry.Data[existingChildIndex].CheckboxStatus) {
            existingEntry.Data.splice(existingChildIndex, 1);

            if (existingEntry.Data.length === 0) {
              this.dataObject[entryPointKey as keyof DataObject].splice(
                existingIndex,
                1
              );

              if (
                this.dataObject[entryPointKey as keyof DataObject].length === 0
              ) {
                this.dataObject[entryPointKey as keyof DataObject] = [];
              }
            }
          }
        } else {
          existingEntry.Data.push({
            Child: child,
            CheckboxStatus: true,
            responsecode: responseCode,
            variablecode: variableCode,
            isDerviedVariable: derivedVariable,
            CalculationType: calcType,
            sort_id: sort_id,
            year_response_code: year_code == null ? '' : year_code,
            Date: date,
            year_variable_code: dataYearCode,
            isMultiPunch: MultiPunchNumber,
            isTimePeriod: parent == 'Time Period' ? true : false,
            isMarket:
              parent.toLowerCase() == 'markets' && is_dimension
                ? false
                : parent.toLowerCase() == 'markets'
                  ? true
                  : false,
            multiPunchMetric: multiPunchMetric,
            MultiPunchNumber: MultiPunchNumber,
            isCustomGroup: isCustomGroup,
            kibanaFilterScript: kibanaFilterScript,
            customGroupId: customGroupId,
            customGroupRowOrder: customGroupRowOrder,
            isRolling:
              child.indexOf('3MMA') > -1 ||
                child.indexOf('4MMA') > -1 ||
                child.indexOf('6MMA') > -1 ||
                child.indexOf('12MMA') > -1
                ? true
                : false,
            isCustomCalculation: isCustomCalculation,
            customCalculationId: customCalculationId,
            customCalculationRowOrder: customCalculationRowOrder,
          });
          existingEntry.Data.sort((a, b) => a.sort_id - b.sort_id);
          if (parent == 'Time Period') {
            existingEntry.Data.sort((a, b) =>
              a.year_response_code.localeCompare(b.year_response_code)
            );
          }
        }
      } else {
        const tempEntry: checkboxSelectionWithParent = {
          customGroupRowOrder: this.customGroupRowOrder,
          customCalculationRowOrder: this.customCalculationRowOrder,
          Parent: headerText,
          SortId: objectSortId,
          isMultiPunch: isMultiPunch,
          MultiPunchNumber: MultiPunchNumber,
          isCustomGroup: isCustomGroup,
          customGroupId: customGroupId,
          isCustomCalculation: isCustomCalculation,
          customCalculationId: customCalculationId,
          brandanalysisCode: 0,
          is_entrypoint: is_entrypoint,
          Data: [
            {
              Child: child,
              CheckboxStatus: true,
              responsecode: responseCode,
              variablecode: variableCode,
              isDerviedVariable: derivedVariable,
              CalculationType: calcType,
              sort_id: sort_id,
              year_response_code: year_code == null ? '' : year_code,
              Date: date,
              year_variable_code: dataYearCode,
              isMultiPunch: MultiPunchNumber,
              isTimePeriod: parent == 'Time Period' ? true : false,
              isMarket:
                parent.toLowerCase() == 'markets' && is_dimension
                  ? false
                  : parent.toLowerCase() == 'markets'
                    ? true
                    : false,
              multiPunchMetric: multiPunchMetric,
              MultiPunchNumber: MultiPunchNumber,
              isCustomGroup: isCustomGroup,
              kibanaFilterScript: kibanaFilterScript,
              customGroupId: customGroupId,
              customGroupRowOrder: customGroupRowOrder,
              isRolling:
                child.indexOf('3MMA') > -1 ||
                  child.indexOf('4MMA') > -1 ||
                  child.indexOf('6MMA') > -1 ||
                  child.indexOf('12MMA') > -1
                  ? true
                  : false,
              isCustomCalculation: isCustomCalculation,
              customCalculationId: customCalculationId,
              customCalculationRowOrder: this.customCalculationRowOrder,
            },
          ],
        };
        tempEntry.Data.sort((a, b) => a.sort_id - b.sort_id);
        if (parent == 'Time Period') {
          tempEntry.Data.sort((a, b) =>
            a.year_response_code.localeCompare(b.year_response_code)
          );
        }
        this.dataObject[entryPointKey as keyof DataObject].push(tempEntry);
      }

      if (!(isCustomGroup || isCustomCalculation)) {
        const is_multi_punch = this.GetMultiPunchValue(
          _.uniq(
            _.map(
              _.filter(
                this.variableTabData,
                (data) =>
                  data.Level0 == parent && data.Level1 === child.split('|')[0]
              ),
              'is_multi_punch'
            )
          )
        );
        const calc_type = _.uniq(
          _.map(
            _.filter(
              this.variableTabData,
              (data) =>
                data.Level0 == parent && data.Level1 === child.split('|')[0]
            ),
            'calculation_type'
          )
        );
        const isdervied = _.uniq(
          _.map(
            _.filter(
              this.variableTabData,
              (data) =>
                data.Level0 == parent && data.Level1 === child.split('|')[0]
            ),
            'is_derived_variable'
          )
        );

        const Level3Data =
          is_multi_punch.toString() == 'true' ||
            (calc_type.toString() == 'mean' && isdervied.toString() == 'true')
            ? _.filter(
              this.variableTabData,
              (data) =>
                data.Level0 == parent &&
                child.split('|').length > 2 &&
                data.Level1 === child.split('|')[0] &&
                data.Level2 == child.split('|')[1]
            )
            : _.filter(
              this.variableTabData,
              (data) =>
                data.Level0 == parent &&
                child.split('|').length > 2 &&
                data.Level1 === child.split('|')[0] &&
                data.Level2 == child.split('|')[1] &&
                data.Level3 == child.split('|')[2]
            );
        if (Level3Data.length == 0) {
          const is_multi_punch = this.GetMultiPunchValue(
            _.uniq(
              _.map(
                _.filter(
                  this.variableTabData,
                  (data) =>
                    data.Level0 == parent && data.Level1 === child.split('|')[0]
                ),
                'is_multi_punch'
              )
            )
          );
          const calc_type = _.uniq(
            _.map(
              _.filter(
                this.variableTabData,
                (data) =>
                  data.Level0 == parent && data.Level1 === child.split('|')[0]
              ),
              'calculation_type'
            )
          );
          const isdervied = _.uniq(
            _.map(
              _.filter(
                this.variableTabData,
                (data) =>
                  data.Level0 == parent && data.Level1 === child.split('|')[0]
              ),
              'is_derived_variable'
            )
          );

          const Level2Data =
            is_multi_punch.toString() == 'true' ||
              (calc_type.toString() == 'mean' && isdervied.toString() == 'true')
              ? _.filter(
                this.variableTabData,
                (data) =>
                  data.Level0 == parent &&
                  child.split('|').length > 1 &&
                  data.Level1 === child.split('|')[0]
              )
              : _.filter(
                this.variableTabData,
                (data) =>
                  data.Level0 == parent &&
                  child.split('|').length > 1 &&
                  data.Level1 === child.split('|')[0] &&
                  data.Level2 == child.split('|')[1]
              );

          if (Level2Data.length == 0) {
            const Level1Data = _.filter(
              this.variableTabData,
              (data) =>
                data.Level0 == parent && data.Level1 === child.split('|')[0]
            );
            if (Level1Data.length > 0) {
              const count = this.getCheckedCount(
                headerText,
                selection,
                child,
                this.customGroupRowOrder,
                this.customCalculationRowOrder
              );
              if (!isSelectAllClick) {
                if (Level1Data.length == count) {
                  this.isSelectAll = true;
                } else {
                  this.isSelectAll = false;
                }
              }
            } else {
              const Level1Data = _.filter(
                this.variableTabData,
                (data) => data.Level0 == parent
              );
              if (Level1Data.length > 0) {
                const count = this.getCheckedCount(
                  headerText,
                  selection,
                  child,
                  this.customGroupRowOrder,
                  this.customCalculationRowOrder
                );
                if (!isSelectAllClick) {
                  if (Level1Data.length == count) {
                    this.isSelectAll = true;
                  } else {
                    this.isSelectAll = false;
                  }
                }
              }
            }
          } else {
            if (is_multi_punch.toString() == 'true') {
              const Level1Variables = _.uniq(
                _.map(
                  _.filter(
                    this.variableTabData,
                    (data) =>
                      data.Level0 == parent &&
                      data.Level1 == child.split('|')[0] &&
                      data.Level2 != ''
                  ),
                  'Level2'
                )
              );
              if (Level1Variables.length > 0) {
                const Level2Variables = _.uniq(
                  _.map(
                    _.filter(
                      this.variableTabData,
                      (data) =>
                        data.Level0 == parent &&
                        data.Level1 == child.split('|')[0] &&
                        data.Level2 == child.split('|')[1] &&
                        data.Level3 != ''
                    ),
                    'Level3'
                  )
                );
                if (Level2Variables.length > 0) {
                  const count = this.getCheckedCount(
                    headerText,
                    selection,
                    child,
                    this.customGroupRowOrder,
                    this.customCalculationRowOrder
                  );
                  if (!isSelectAllClick) {
                    if (Level1Variables.length == count) {
                      this.isSelectAll = true;
                    } else {
                      this.isSelectAll = false;
                    }
                  }
                } else {
                  const count = this.getCheckedCount(
                    headerText,
                    selection,
                    child,
                    this.customGroupRowOrder,
                    this.customCalculationRowOrder
                  );
                  if (!isSelectAllClick) {
                    if (Level1Variables.length == count) {
                      this.isSelectAll = true;
                    } else {
                      this.isSelectAll = false;
                    }
                  }
                }
              }
            } else {
              const count = this.getCheckedCount(
                headerText,
                selection,
                child,
                this.customGroupRowOrder,
                this.customCalculationRowOrder
              );
              if (!isSelectAllClick) {
                if (Level2Data.length == count) {
                  this.isSelectAll = true;
                } else {
                  this.isSelectAll = false;
                }
              }
            }
          }
        } else {
          const count = this.getCheckedCount(
            headerText,
            selection,
            child,
            this.customGroupRowOrder,
            this.customCalculationRowOrder
          );
          if (!isSelectAllClick) {
            if (Level3Data.length == count) {
              this.isSelectAll = true;
            } else {
              this.isSelectAll = false;
            }
          }
        }

        if (selection === 'Row') {
          if (!this.isSelectAll) {
            this.disableStackChart = false;
            this.isVerticalStackChartClick = false;
          } else if (this.isSelectAll) {
            this.disableStackChart = true;
            this.isVerticalStackChartClick = false;
          }
        }
      }
      if (selection == 'CustomGroup') {
        //generate unique id for each selection
        // this.setCustomGroupRowOrder(headerText, isMultiPunch, variableCode);
        this.generateCustomGroupObjects(
          headerText,
          child,
          responseCode,
          variableCode,
          headerText,
          isMultiPunch,
          customGroupRowOrder
        );

        this.updateResponseDataRowOrder(
          this.responseData1,
          customGroupRowOrder
        );
        this.updateResponseDataRowOrder(
          this.responseData2,
          customGroupRowOrder
        );
        this.updateResponseDataRowOrder(
          this.responseData3,
          customGroupRowOrder
        );
        this.updateResponseDataRowOrder(
          this.responseData4,
          customGroupRowOrder
        );
        this.updateResponseDataRowOrder(
          this.responseData5,
          customGroupRowOrder
        );
      } else if (selection == 'CustomCalculation') {
        //generate unique id for each selection
        // this.setCustomGroupRowOrder(headerText, isMultiPunch, variableCode);
        this.generateCustomCalculationObjects(
          headerText,
          child,
          responseCode,
          variableCode,
          headerText,
          isMultiPunch,
          customCalculationRowOrder
        );

        this.updateResponseDataRowOrderCustomCalc(
          this.responseData1,
          customCalculationRowOrder
        );
        this.updateResponseDataRowOrderCustomCalc(
          this.responseData2,
          customCalculationRowOrder
        );
        this.updateResponseDataRowOrderCustomCalc(
          this.responseData3,
          customCalculationRowOrder
        );
        this.updateResponseDataRowOrderCustomCalc(
          this.responseData4,
          customCalculationRowOrder
        );
        this.updateResponseDataRowOrderCustomCalc(
          this.responseData5,
          customCalculationRowOrder
        );
      }

      //this.updatePreviewData();
      // this.dataObject[entryPointKey as keyof DataObject].sort((a, b) => a.SortId - b.SortId);
      this.updateSelectAllMetricStatus(selection, parent, headerText);
      this.sortingDataObject(entryPointKey);
      this.getLimitedDataPreview();

    }
  }

  onEntryListChange_DataArray(dataarray: any[]): void {
    this.leftpanelDataArray = dataarray;
    // You can assign it to a property or perform any other actions
  }

  removeItem(
    destination: string,
    item: {
      Level0: string;
      Level1: string;
      customGroupRowOrder?: number;
      customGroupId: string;
      is_custmGrpPresent: boolean;
      customCalculationId: string;
      is_custmCalcPresent: boolean;
      customCalculationRowOrder?: number;
    },
    panel: string
  ) {
    let listToUpdate;
    switch (destination) {
      case 'listAItemsList':
        listToUpdate = this.listAItemsList;
        break;
      case 'listBItemsList':
        listToUpdate = this.listBItemsList;
        break;
      case 'listCItemsList':
        listToUpdate = this.listCItemsList;
        break;
      case 'listDItemsList':
        listToUpdate = this.listDItemsList;
        break;
      case 'listEItemsList':
        listToUpdate = this.listEItemsList;
        break;
      case 'listCustomGroupItemsList':
        {
          //writtern here because custom row order conditions are specific to custom groups
          item.is_custmGrpPresent = true;
          listToUpdate = this.listCustomGroupItemsList;
          listToUpdate = listToUpdate.filter(
            (a) =>
              a.Level0 !== item.Level0 ||
              a.Level1 !== item.Level1 ||
              (a.customGroupRowOrder !== undefined &&
                a.customGroupRowOrder !== item.customGroupRowOrder)
          );
        }
        break;
      case 'listCustomCalculationItemsList':
        {
          //writtern here because custom row order conditions are specific to custom groups
          item.is_custmCalcPresent = true;
          listToUpdate = this.listCustomCalculationItemsList;
          listToUpdate = listToUpdate.filter(
            (a) =>
              a.Level0 !== item.Level0 ||
              a.Level1 !== item.Level1 ||
              (a.customCalculationRowOrder !== undefined &&
                a.customCalculationRowOrder !== item.customCalculationRowOrder)
          );
        }
        break;
      default:
        // Handle unexpected destination
        return;
    }

    if (
      destination !== 'listCustomGroupItemsList' &&
      destination !== 'listCustomCalculationItemsList'
    ) {
      if (item.is_custmCalcPresent) {
        listToUpdate = listToUpdate.filter(
          (a) =>
            a.Level0 !== item.Level0 ||
            a.Level1 !== item.Level1 ||
            a.customCalculationId !== item.customCalculationId
        );
      } else if (item.is_custmGrpPresent) {
        listToUpdate = listToUpdate.filter(
          (a) =>
            a.Level0 !== item.Level0 ||
            a.Level1 !== item.Level1 ||
            a.customGroupId !== item.customGroupId
        );
      } else {
        listToUpdate = listToUpdate.filter(
          (a) => a.Level0 !== item.Level0 || a.Level1 !== item.Level1
        );
      }
    }

    switch (destination) {
      case 'listAItemsList':
        this.listAItemsList = listToUpdate;
        break;
      case 'listBItemsList':
        this.listBItemsList = listToUpdate;
        break;
      case 'listCItemsList':
        this.listCItemsList = listToUpdate;
        break;
      case 'listDItemsList':
        this.listDItemsList = listToUpdate;
        break;
      case 'listEItemsList':
        this.listEItemsList = listToUpdate;
        break;
      case 'listCustomGroupItemsList':
        this.listCustomGroupItemsList = listToUpdate;
        break;
      case 'listCustomCalculationItemsList':
        this.listCustomCalculationItemsList = listToUpdate;
        break;
      // Add cases for other destinations here...
    }

    // if (panel == 'customCalculation') {
    //   this.updateToggleBasedOnCustomCalculation();
    // } else {
    // disable Answer base if custom group is selected in row,column or filter
    this.updateToggleBasedOnCustomGroup(); //custom group and custom calculation share same function
    //}
    this.updateDisplayAndResetSelection(destination);
    this.selectedLevel1Item = '';
    this.selectedLevel2Item = '';
    this.selectedLevel3Item = '';
    this.selectedLevel4Item = '';
    // if (destination == 'listCustomCalculationItemsList') {
    //   this.removePanelItemOnSelectionObjectCustomCalc(
    //     panel,
    //     item.Level1,
    //     item.customCalculationId,
    //     item.is_custmCalcPresent,
    //     item.customCalculationRowOrder
    //   );
    // } else {
      this.removePanelItemOnSelectionObject(
        panel,
        item.Level1,
        item.customGroupId,
        item.is_custmGrpPresent,
        item.customGroupRowOrder
      );
   // }
    this.enableOrDisableProfiling();
  }

  removePanelItemOnSelectionObject(
    panel: string,
    parent: string,
    customGroupId: string,
    isCustomGroup: boolean,
    customGroupRowOrder?: number
  ) {
    let index = -1;
    if (isCustomGroup) {
      index = this.dataObject[panel as keyof DataObject].findIndex((x: any) => {
        return (
          x.Parent == parent &&
          (!this.isCreateOrEditCustomGroup() ||
            (this.isCreateOrEditCustomGroup() &&
              x.customGroupRowOrder === customGroupRowOrder))
        );
      });


    } else {
      index = this.dataObject[panel as keyof DataObject].findIndex((x: any) => {
        return (
          x.Parent == parent &&
          (!this.isCreateOrEditCustomGroup() ||
            (this.isCreateOrEditCustomGroup() &&
              (customGroupRowOrder === undefined
                ? true
                : x.customGroupRowOrder === customGroupRowOrder)))
        );
      });
    }
    if (index > -1) {
      this.dataObject[panel as keyof DataObject].splice(index, 1);
      this.getLimitedDataPreview();
    }

    if (panel == 'CustomGroup') {
      let index = this.customGroupSelectionsObj.findIndex((x) => {
        return (
          x.headerText == parent &&
          (!this.isCreateOrEditCustomGroup() ||
            (this.isCreateOrEditCustomGroup() &&
              x.oneObject.customGroupRowOrder === customGroupRowOrder))
        );
      });
      if (index > -1) {
        this.customGroupSelectionsObj.splice(index, 1);
      }
      if (this.customGroupSelectionsObj.length == 0) {
        this.disabledCustomGroupOperatorList = [];
      }
    }
  }

  plotNextLevel(
    item: {
      Level0: string;
      Level1: string;
      customGroupRowOrder?: number;
      customCalculationRowOrder?: number;
      is_entrypoint: boolean;
    },
    entryPointKey: string,
    sortId: number
  ) {
    switch (entryPointKey) {
      case 'CustomCalculation':
        //this.sidePanelDisplay = true;
        if (this.droppedItem == item.Level1 + '|' + entryPointKey) {
          if (this.droppedItemSortId == sortId)
            this.sidePanelDisplay = !this.sidePanelDisplay;
          else
            this.sidePanelDisplay = true
        }
        else
          this.sidePanelDisplay = true;
        this.sidePanelLevel1Display = this.sidePanelDisplay;
        this.droppedItemSortId = sortId;
        this.droppedItem = item.Level1 + '|' + entryPointKey;
        this.selectedLevel1Item = '';
        this.columnPanelDisplay = false;
        //this.sidePanelLevel1Display = true;
        this.sidePanelLevel2Display = false;
        this.sidePanelLevel3Display = false;
        this.sidePanelLevel4Display = false;
        this.sidePanelLevel5Display = false;
        this.sidePanelNestingDisplay = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelDisplay = false;
        break;
      case 'CustomGroup':
        //this.sidePanelDisplay = true;
        if (this.droppedItem == item.Level1 + '|' + entryPointKey) {
          if (this.droppedItemSortId == sortId)
            this.sidePanelDisplay = !this.sidePanelDisplay;
          else
            this.sidePanelDisplay = true
        }
        else
          this.sidePanelDisplay = true;
        this.sidePanelLevel1Display = this.sidePanelDisplay;
        this.droppedItemSortId = sortId;
        this.droppedItem = item.Level1 + '|' + entryPointKey;
        this.selectedLevel1Item = '';
        this.columnPanelDisplay = false;
        // this.sidePanelLevel1Display = true;
        this.sidePanelLevel2Display = false;
        this.sidePanelLevel3Display = false;
        this.sidePanelLevel4Display = false;
        this.sidePanelLevel5Display = false;
        this.sidePanelNestingDisplay = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelDisplay = false;
        break;
      case 'Row':
        //this.sidePanelDisplay = true;
        if (this.droppedItem == item.Level1 + '|' + entryPointKey)
          this.sidePanelDisplay = !this.sidePanelDisplay;
        else
          this.sidePanelDisplay = true;
        this.sidePanelLevel1Display = this.sidePanelDisplay;
        this.droppedItem = item.Level1 + '|' + entryPointKey;
        this.selectedLevel1Item = '';
        this.columnPanelDisplay = false;
        //this.sidePanelLevel1Display = true;
        this.sidePanelLevel2Display = false;
        this.sidePanelLevel3Display = false;
        this.sidePanelLevel4Display = false;
        this.sidePanelLevel5Display = false;
        this.sidePanelNestingDisplay = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelDisplay = false;
        break;
      case 'RowNesting':
        this.sidePanelDisplay = false;
        if (this.droppedItem == item.Level1 + '|' + entryPointKey)
          this.sidePanelNestingDisplay = !this.sidePanelNestingDisplay;
        else
          this.sidePanelNestingDisplay = true;
        this.sidePanelLevel1NestingDisplay = this.sidePanelNestingDisplay;
        this.droppedItem = item.Level1 + '|' + entryPointKey;
        this.selectedLevel1Item = '';
        this.columnPanelDisplay = false;
        this.sidePanelLevel2NestingDisplay = false;
        this.sidePanelLevel3NestingDisplay = false;
        this.sidePanelLevel4NestingDisplay = false;
        this.sidePanelLevel5NestingDisplay = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelDisplay = false;
        break;
      case 'Column':
        this.sidePanelDisplay = false;
        if (this.droppedItem == item.Level1 + '|' + entryPointKey)
          this.columnPanelDisplay = !this.columnPanelDisplay;
        else
          this.columnPanelDisplay = true;
        this.columnPanelLevel1Display = this.columnPanelDisplay;
        this.droppedItem = item.Level1 + '|' + entryPointKey;
        this.selectedLevel1Item = '';
        this.sidePanelNestingDisplay = false;
        //this.columnPanelDisplay = true;
        //this.columnPanelLevel1Display = true;
        this.columnPanelLevel2Display = false;
        this.columnPanelLevel3Display = false;
        this.columnPanelLevel4Display = false;
        this.columnPanelLevel5Display = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelDisplay = false;
        break;
      case 'ColNesting':
        this.sidePanelDisplay = false;
        if (this.droppedItem == item.Level1 + '|' + entryPointKey)
          this.columnNestingPanelDispaly = !this.columnNestingPanelDispaly;
        else
          this.columnNestingPanelDispaly = true;
        this.columnPanelNestLeve11Display = this.columnNestingPanelDispaly;
        this.droppedItem = item.Level1 + '|' + entryPointKey;
        this.selectedLevel1Item = '';
        this.sidePanelNestingDisplay = false;
        this.columnPanelDisplay = false;
        //this.columnNestingPanelDispaly = true;
        //this.columnPanelNestLeve11Display = true;
        this.columnPanelNestLeve12Display = false;
        this.columnPanelNestLeve13Display = false;
        this.columnPanelNestLeve14Display = false;
        this.columnPanelNestLeve15Display = false;
        this.filterPanelDisplay = false;
        break;
      case 'Filter':
        this.sidePanelDisplay = false;
        if (this.droppedItem == item.Level1 + '|' + entryPointKey)
          this.filterPanelDisplay = !this.filterPanelDisplay;
        else
          this.filterPanelDisplay = true;
        this.filterPanelLevel1Display = this.filterPanelDisplay;
        this.droppedItem = item.Level1 + '|' + entryPointKey;
        this.selectedLevel1Item = '';
        //this.filterPanelDisplay = true;
        this.sidePanelNestingDisplay = false;
        this.columnPanelDisplay = false;
        this.columnNestingPanelDispaly = false;
        //this.filterPanelLevel1Display = true;
        this.filterPanelLevel2Display = false;
        this.filterPanelLevel3Display = false;
        this.filterPanelLevel4Display = false;
        this.filterPanelLevel5Display = false;
        break;
    }

    this.isSelectAll = false;
    this.responseData1 = [];
    this.Level3MultiVariables = [];
    this.searchTextLevel1 = '';
    this.searchTextLevel2 = '';
    this.searchTextLevel3 = '';
    this.searchTextLevel4 = '';
    this.searchTextLevel5 = '';
    let listToUpdate;
    const Level0Variables = _.uniq(
      _.map(
        _.filter(this.variableTabData, (data) =>
          item.Level0 == item.Level1
            ? data.Level0 == item.Level0
            : data.Level0 == item.Level0 && data.Level1 == item.Level1
        ),
        item.Level0 == item.Level1 ? 'Level0' : 'Level1'
      )
    );
    const calc_type = _.uniq(
      _.map(
        _.filter(
          this.variableTabData,
          (data) => data.Level0 == item.Level0 && data.Level1 === item.Level1
        ),
        'calculation_type'
      )
    );
    const isdervied = _.uniq(
      _.map(
        _.filter(
          this.variableTabData,
          (data) => data.Level0 == item.Level0 && data.Level1 === item.Level1
        ),
        'is_derived_variable'
      )
    );
    const ismultipunch = this.GetMultiPunchValue(
      _.uniq(
        _.map(
          _.filter(
            this.variableTabData,
            (data) => data.Level0 == item.Level0 && data.Level1 === item.Level1
          ),
          'is_multi_punch'
        )
      )
    );

    if (Level0Variables.length > 0) {
      for (const ele of Level0Variables) {
        const Level2Array: {
          MetricOption: string;
          response_code: string;
          data_variable_code: string;
          dataYearCode: string;
          calculation_type: string;
          tooltip: string;
          isDerviedVariable: boolean;
          sort_id: number;
          isMultiPunch: boolean;
          year_code: string;
          date: string;
          is_dimension: boolean;
          isCustomGroup: boolean;
          isCustomCalculation: boolean;
          kibanaFilterScript: string;
          customGroupId: string;
          customCalculationId: string;
          MultiPunchNumber: number;
          isSelectAllMetrics: boolean;
          isSelectAllMetricsCount: number;
        }[] = [];
        listToUpdate =
          item.Level0 == item.Level1
            ? this.variableTabData.filter((a: any) => a.Level0 === item.Level0)
            : this.variableTabData.filter(
              (a: any) => a.Level0 === item.Level0 && a.Level1 == ele
            );
        listToUpdate =
          item.Level0 == 'Time Period' &&
            this.selectedDataFormat == 'Respondent Level'
            ? _.sortBy(listToUpdate, 'level1_sort_id')
            : _.sortBy(listToUpdate, 'sort_id');

        if (item.Level0) {
          listToUpdate =
            item.Level0 == 'Time Period' &&
              this.selectedDataFormat == 'Respondent Level'
              ? _.sortBy(listToUpdate, 'year_code')
              : _.sortBy(listToUpdate, 'sort_id');
        }

        const Level1Variables = _.uniq(
          _.map(
            _.filter(
              listToUpdate,
              (data) =>
                data.Level0 == item.Level0 &&
                data.Level1 == ele &&
                data.Level2 != ''
            ),
            'Level2'
          )
        );
        if (Level1Variables.length > 0) {
          for (const element of Level1Variables) {
            this.Level3MultiVariables = _.uniq(
              _.map(
                _.filter(
                  this.variableTabData,
                  (data) =>
                    data.Level0 == item.Level0 &&
                    data.Level1 == item.Level1 &&
                    data.Level2 == element &&
                    data.Level3 != ''
                ),
                'Level3'
              )
            );

            if (
              calc_type.toString() == 'mean' &&
              isdervied.toString() == 'true'
            ) {
              const MetricOptions = _.uniq(
                _.filter(
                  listToUpdate,
                  (data) =>
                    data.Level0 == item.Level0 &&
                    data.Level1 == item.Level1 &&
                    data.Level2 == element
                )
              );
              if (MetricOptions.length > 0) {
                Level2Array.push({
                  MetricOption: MetricOptions[0].Level2,
                  response_code:
                    MetricOptions[0].calculation_type == 'RespGrp' &&
                      MetricOptions[0].is_derived_variable == true
                      ? MetricOptions[0].derived_metric_calculation
                      : MetricOptions[0].response_code,
                  data_variable_code: MetricOptions[0].data_variable_code,
                  dataYearCode: MetricOptions[0].dataYearCode,
                  calculation_type: MetricOptions[0].calculation_type,
                  tooltip: MetricOptions[0].MetricOption,
                  isDerviedVariable: MetricOptions[0].is_derived_variable,
                  sort_id: MetricOptions[0].sort_id,
                  isMultiPunch: this.GetMultiPunchValue(
                    MetricOptions[0].is_multi_punch
                  ),
                  MultiPunchNumber: MetricOptions[0].is_multi_punch,
                  year_code: MetricOptions[0].year_code,
                  date: MetricOptions[0].date,
                  is_dimension: false,
                  isCustomGroup: MetricOptions[0].is_CustomGroup,
                  isCustomCalculation: MetricOptions[0].is_CustomCalculation,
                  kibanaFilterScript:
                    MetricOptions[0].is_CustomGroup.toString() == 'true'
                      ? MetricOptions[0].customGroupQuery
                      : MetricOptions[0].is_CustomCalculation.toString() ==
                        'true'
                        ? MetricOptions[0].customCalculationQuery
                        : '',
                  customGroupId: MetricOptions[0].customGroupId,
                  customCalculationId: MetricOptions[0].customCalculationId,
                  isSelectAllMetrics: false,
                  isSelectAllMetricsCount: 0,
                });
              }
            } else {
              const MetricOptions = _.uniq(
                _.filter(
                  listToUpdate,
                  (data) =>
                    data.Level0 == item.Level0 &&
                    data.Level1 == item.Level1 &&
                    data.Level2 == element
                )
              );

              if (MetricOptions.length > 0) {
                Level2Array.push({
                  MetricOption: MetricOptions[0].Level2,
                  response_code:
                    MetricOptions[0].calculation_type == 'RespGrp' &&
                      MetricOptions[0].is_derived_variable == true
                      ? MetricOptions[0].derived_metric_calculation
                      : MetricOptions[0].response_code,
                  data_variable_code: MetricOptions[0].data_variable_code,
                  dataYearCode: MetricOptions[0].dataYearCode,
                  calculation_type: MetricOptions[0].calculation_type,
                  tooltip: MetricOptions[0].Level2,
                  isDerviedVariable: false,
                  sort_id: MetricOptions[0].sort_id,
                  isMultiPunch: this.GetMultiPunchValue(
                    MetricOptions[0].is_multi_punch
                  ),
                  MultiPunchNumber: MetricOptions[0].is_multi_punch,
                  year_code: MetricOptions[0].year_code,
                  date: MetricOptions[0].date,
                  is_dimension: false,
                  isCustomGroup: MetricOptions[0].is_CustomGroup,
                  isCustomCalculation: MetricOptions[0].is_CustomCalculation,
                  kibanaFilterScript:
                    MetricOptions[0].is_CustomGroup.toString() == 'true'
                      ? MetricOptions[0].customGroupQuery
                      : MetricOptions[0].is_CustomCalculation.toString() ==
                        'true'
                        ? MetricOptions[0].customCalculationQuery
                        : '',
                  customGroupId: MetricOptions[0].customGroupId,
                  customCalculationId: MetricOptions[0].customCalculationId,
                  isSelectAllMetrics: false,
                  isSelectAllMetricsCount: 0,
                });
              }
            }
          }
          this.responseData1.push({
            Level0: item.Level0,
            Level1: item.Level1,
            is_entryPoint: item.is_entrypoint,
            isCheckbox: !!(
              (calc_type.toString() == 'mean' &&
                isdervied.toString() == 'true') ||
              (this.Level3MultiVariables.length === 0 &&
                ismultipunch.toString() == 'true')
            ),
            Level2: Level2Array,
            sortId: sortId,
            customGroupRowOrder: item.customGroupRowOrder,
            customCalculationRowOrder: item.customCalculationRowOrder,
          });
        } else {
          const LevelMetricOptions = _.uniq(
            _.map(
              _.filter(listToUpdate, (data) =>
                item.Level0 == item.Level1
                  ? data.Level0 == item.Level0
                  : data.Level0 == item.Level0 &&
                  data.Level1 == item.Level1 &&
                  data.MetricOption != ''
              ),
              'MetricOption'
            )
          );
          if (LevelMetricOptions.length > 0) {
            for (const element of LevelMetricOptions) {
              const MetricOptions = _.uniq(
                item.Level0 == item.Level1
                  ? _.filter(
                    listToUpdate,
                    (data) =>
                      data.Level0 == item.Level0 &&
                      data.MetricOption == element
                  )
                  : _.filter(
                    listToUpdate,
                    (data) =>
                      data.Level0 == item.Level0 &&
                      data.Level1 == item.Level1 &&
                      data.MetricOption == element
                  )
              );

              if (MetricOptions.length > 0) {
                Level2Array.push({
                  MetricOption: MetricOptions[0].MetricOption,
                  response_code:
                    MetricOptions[0].calculation_type == 'RespGrp' &&
                      MetricOptions[0].is_derived_variable == true
                      ? MetricOptions[0].derived_metric_calculation
                      : MetricOptions[0].response_code,
                  data_variable_code: MetricOptions[0].data_variable_code,
                  dataYearCode: MetricOptions[0].dataYearCode,
                  calculation_type: MetricOptions[0].calculation_type,
                  tooltip: MetricOptions[0].MetricOption,
                  isDerviedVariable: MetricOptions[0].is_derived_variable,
                  sort_id:
                    MetricOptions[0].Level0 == 'Time Period'
                      ? MetricOptions[0].level1_sort_id
                      : MetricOptions[0].sort_id,
                  isMultiPunch: this.GetMultiPunchValue(
                    MetricOptions[0].is_multi_punch
                  ),
                  MultiPunchNumber: MetricOptions[0].is_multi_punch,
                  year_code: MetricOptions[0].year_code,
                  date: MetricOptions[0].date,
                  is_dimension: MetricOptions[0].is_dimension,
                  isCustomGroup: MetricOptions[0].is_CustomGroup,
                  isCustomCalculation: MetricOptions[0].is_CustomCalculation,
                  kibanaFilterScript:
                    MetricOptions[0].is_CustomGroup.toString() == 'true'
                      ? MetricOptions[0].customGroupQuery
                      : MetricOptions[0].is_CustomCalculation.toString() ==
                        'true'
                        ? MetricOptions[0].customCalculationQuery
                        : '',
                  customGroupId: MetricOptions[0].customGroupId,
                  customCalculationId: MetricOptions[0].customCalculationId,
                  isSelectAllMetrics: false,
                  isSelectAllMetricsCount: 0,
                });
              }
            }
            this.responseData1.push({
              Level0: item.Level0,
              Level1: item.Level1,
              is_entryPoint: item.is_entrypoint,
              isCheckbox: true,
              Level2: Level2Array,
              sortId: sortId,
              customGroupRowOrder: item.customGroupRowOrder,
              customCalculationRowOrder: item.customCalculationRowOrder,
            });
          }
        }
      }
    }

    var existingIndex;
    if (entryPointKey == 'CustomCalculation') {
      existingIndex = this.dataObject[
        entryPointKey as keyof DataObject
      ].findIndex(
        (entry: checkboxSelectionWithParent) =>
          entry &&
          entry.Data &&
          entry.Data.length > 0 &&
          entry.Parent === item.Level1 &&
          entry.customCalculationRowOrder === item.customGroupRowOrder
      );
    } else if (entryPointKey == 'CustomGroup') {
      existingIndex = this.dataObject[
        entryPointKey as keyof DataObject
      ].findIndex(
        (entry: checkboxSelectionWithParent) =>
          entry &&
          entry.Data &&
          entry.Data.length > 0 &&
          entry.Parent === item.Level1 &&
          entry.customGroupRowOrder === item.customGroupRowOrder
      );
    } else {
      existingIndex = this.dataObject[
        entryPointKey as keyof DataObject
      ].findIndex(
        (entry: checkboxSelectionWithParent) =>
          entry &&
          entry.Data &&
          entry.Data.length > 0 &&
          entry.Parent === item.Level1
      );
    }
    if (existingIndex !== -1) {
      const existingEntry: checkboxSelectionWithParent =
        this.dataObject[entryPointKey as keyof DataObject][existingIndex];
      const dataLengthForParent = existingEntry.Data.length;

      if (dataLengthForParent === this.responseData1[0].Level2.length) {
        this.isSelectAll = true;
      }
    }
  }

  plotNextLevel2(
    entryPointKey: string,
    Level0: string,
    Level1: string,
    Level2: string,
    sortid: number,
    is_entryPoint: boolean,
    customGroupRowOrder?: number,
    customCalculationRowOrder?: number,

  ) {
    let name = Level0 + '|' + Level1 + "|" + Level2 + "|" + entryPointKey
    switch (entryPointKey) {
      case 'Row':
      case 'CustomGroup':
        this.sidePanelDisplay = true;
        this.selectedLevel1Item = Level2;
        this.columnPanelDisplay = false;
        this.sidePanelLevel1Display = true;

        if (this.droppedLevel2Item == name) {
          if (this.droppedLevel2ItemSortId == sortid)
            this.sidePanelLevel2Display = !this.sidePanelLevel2Display;
          else
            this.sidePanelLevel2Display = true
        }
        else
          this.sidePanelLevel2Display = true;
        this.droppedLevel2ItemSortId = sortid;
        this.droppedLevel2Item = name;

        //this.sidePanelLevel2Display = true;
        this.sidePanelLevel3Display = false;
        this.sidePanelLevel4Display = false;
        this.sidePanelLevel5Display = false;
        this.sidePanelNestingDisplay = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelDisplay = false;
        break;
      case 'CustomCalculation':
        this.sidePanelDisplay = true;
        this.selectedLevel1Item = Level2;
        this.columnPanelDisplay = false;
        this.sidePanelLevel1Display = true;
        if (this.droppedLevel2Item == name) {
          if (this.droppedLevel2ItemSortId == sortid)
            this.sidePanelLevel2Display = !this.sidePanelLevel2Display;
          else
            this.sidePanelLevel2Display = true
        }
        else
          this.sidePanelLevel2Display = true;
        this.droppedLevel2ItemSortId = sortid;
        this.droppedLevel2Item = name;
        //this.sidePanelLevel2Display = true;
        this.sidePanelLevel3Display = false;
        this.sidePanelLevel4Display = false;
        this.sidePanelLevel5Display = false;
        this.sidePanelNestingDisplay = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelDisplay = false;
        break;
      case 'RowNesting':
        this.sidePanelDisplay = false;
        this.selectedLevel1Item = Level2;
        this.columnPanelDisplay = false;
        this.sidePanelNestingDisplay = true;
        this.sidePanelLevel1NestingDisplay = true;

        if (this.droppedLevel2Item == name) {
          if (this.droppedLevel2ItemSortId == sortid)
            this.sidePanelLevel2NestingDisplay = !this.sidePanelLevel2NestingDisplay;
          else
            this.sidePanelLevel2NestingDisplay = true
        }
        else
          this.sidePanelLevel2NestingDisplay = true;
        this.droppedLevel2ItemSortId = sortid;
        this.droppedLevel2Item = name;
        //this.sidePanelLevel2NestingDisplay = true;
        this.sidePanelLevel3NestingDisplay = false;
        this.sidePanelLevel4NestingDisplay = false;
        this.sidePanelLevel5NestingDisplay = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelDisplay = false;
        break;
      case 'Column':
        this.sidePanelDisplay = false;
        this.selectedLevel1Item = Level2;
        this.sidePanelNestingDisplay = false;
        this.columnPanelDisplay = true;
        this.columnPanelLevel1Display = true;

        if (this.droppedLevel2Item == name) {
          if (this.droppedLevel2ItemSortId == sortid)
            this.columnPanelLevel2Display = !this.columnPanelLevel2Display;
          else
            this.columnPanelLevel2Display = true
        }
        else
          this.columnPanelLevel2Display = true;
        this.droppedLevel2ItemSortId = sortid;
        this.droppedLevel2Item = name;
        //this.columnPanelLevel2Display = true;
        this.columnPanelLevel3Display = false;
        this.columnPanelLevel4Display = false;
        this.columnPanelLevel5Display = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelDisplay = false;
        break;
      case 'ColNesting':
        this.sidePanelDisplay = false;
        this.selectedLevel1Item = Level2;
        this.sidePanelNestingDisplay = false;
        this.columnPanelDisplay = false;
        this.columnNestingPanelDispaly = true;
        this.columnPanelNestLeve11Display = true;
        if (this.droppedLevel2Item == name) {
          if (this.droppedLevel2ItemSortId == sortid)
            this.columnPanelNestLeve12Display = !this.columnPanelNestLeve12Display;
          else
            this.columnPanelNestLeve12Display = true
        }
        else
          this.columnPanelNestLeve12Display = true;
        this.droppedLevel2ItemSortId = sortid;
        this.droppedLevel2Item = name;
        //this.columnPanelNestLeve12Display = true;
        this.columnPanelNestLeve13Display = false;
        this.columnPanelNestLeve14Display = false;
        this.columnPanelNestLeve15Display = false;
        this.filterPanelDisplay = false;
        break;

      case 'Filter':
        this.sidePanelDisplay = false;
        this.selectedLevel1Item = Level2;
        this.filterPanelDisplay = true;
        this.sidePanelNestingDisplay = false;
        this.columnPanelDisplay = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelLevel1Display = true;

        if (this.droppedLevel2Item == name) {
          if (this.droppedLevel2ItemSortId == sortid)
            this.filterPanelLevel2Display = !this.filterPanelLevel2Display;
          else
            this.filterPanelLevel2Display = true
        }
        else
          this.filterPanelLevel2Display = true;
        this.droppedLevel2ItemSortId = sortid;
        this.droppedLevel2Item = name;
        // this.filterPanelLevel2Display = true;
        this.filterPanelLevel3Display = false;
        this.filterPanelLevel4Display = false;
        this.filterPanelLevel5Display = false;
        break;
    }

    this.responseData2 = [];
    this.isSelectAll = false;
    this.searchTextLevel1 = '';
    this.searchTextLevel2 = '';
    this.searchTextLevel3 = '';
    this.searchTextLevel4 = '';
    this.searchTextLevel5 = '';
    let listToUpdate;
    const Level0Variables = _.uniq(
      _.map(
        _.filter(
          this.variableTabData,
          (data) =>
            data.Level0 == Level0 &&
            data.Level1 == Level1 &&
            data.Level2 == Level2
        ),
        'Level2'
      )
    );
    const calc_type = _.uniq(
      _.map(
        _.filter(
          this.variableTabData,
          (data) =>
            data.Level0 == Level0 &&
            data.Level1 === Level1 &&
            data.Level2 == Level2
        ),
        'calculation_type'
      )
    );
    const isdervied = _.uniq(
      _.map(
        _.filter(
          this.variableTabData,
          (data) =>
            data.Level0 == Level0 &&
            data.Level1 === Level1 &&
            data.Level2 == Level2
        ),
        'is_derived_variable'
      )
    );
    const ismultipunch = this.GetMultiPunchValue(
      _.uniq(
        _.map(
          _.filter(
            this.variableTabData,
            (data) =>
              data.Level0 == Level0 &&
              data.Level1 === Level1 &&
              data.Level2 == Level2
          ),
          'is_multi_punch'
        )
      )
    );

    if (Level0Variables.length > 0) {
      for (const ele of Level0Variables) {
        const Level2Array: {
          MetricOption: string;
          response_code: string;
          data_variable_code: string;
          dataYearCode: string;
          calculation_type: string;
          tooltip: string;
          isDerviedVariable: boolean;
          sort_id: number;
          isMultiPunch: boolean;
          year_code: string;
          date: string;
          is_dimension: boolean;
          isCustomGroup: boolean;
          isCustomCalculation: boolean;
          kibanaFilterScript: string;
          customGroupId: string;
          customCalculationId: string;
          MultiPunchNumber: number;
        }[] = [];
        listToUpdate = this.variableTabData.filter(
          (a: any) =>
            a.Level0 === Level0 && a.Level1 == Level1 && a.Level2 == Level2
        );
        listToUpdate = _.sortBy(listToUpdate, 'sort_id');
        const Level1Variables = _.uniq(
          _.map(
            _.filter(
              listToUpdate,
              (data) =>
                data.Level0 == Level0 &&
                data.Level1 == Level1 &&
                data.Level2 == Level2 &&
                data.Level3 != ''
            ),
            'Level3'
          )
        );
        if (Level1Variables.length > 0) {
          for (const element of Level1Variables) {
            if (ismultipunch.toString() == 'false') {
              Level2Array.push({
                MetricOption: element,
                response_code: '',
                data_variable_code: '',
                dataYearCode: '',
                calculation_type: '',
                tooltip: element,
                isDerviedVariable: false,
                sort_id: 0,
                isMultiPunch: false,
                year_code: '',
                date: '',
                is_dimension: false,
                isCustomGroup: false,
                isCustomCalculation: false,
                kibanaFilterScript: '',
                customGroupId: '',
                customCalculationId: '',
                MultiPunchNumber: 0,
              });
            } else {
              const MetricOptions = _.uniq(
                _.filter(
                  listToUpdate,
                  (data) =>
                    data.Level0 == Level0 &&
                    data.Level1 == Level1 &&
                    data.Level2 == Level2 &&
                    data.Level3 == element
                )
              );
              if (MetricOptions.length > 0) {
                Level2Array.push({
                  MetricOption: MetricOptions[0].Level3,
                  response_code:
                    MetricOptions[0].calculation_type == 'RespGrp' &&
                      MetricOptions[0].is_derived_variable == true
                      ? MetricOptions[0].derived_metric_calculation
                      : MetricOptions[0].response_code,
                  data_variable_code: MetricOptions[0].data_variable_code,
                  dataYearCode: MetricOptions[0].dataYearCode,
                  calculation_type: MetricOptions[0].calculation_type,
                  tooltip: MetricOptions[0].Level3,
                  isDerviedVariable: MetricOptions[0].is_derived_variable,
                  sort_id: MetricOptions[0].sort_id,
                  year_code: MetricOptions[0].year_code,
                  date: MetricOptions[0].date,
                  is_dimension: MetricOptions[0].is_dimension,
                  isCustomGroup: false,
                  isCustomCalculation: false,
                  kibanaFilterScript: '',
                  customGroupId: '',
                  customCalculationId: '',
                  isMultiPunch: this.GetMultiPunchValue(
                    MetricOptions[0].is_multi_punch
                  ),
                  MultiPunchNumber: MetricOptions[0].is_multi_punch,
                });
              }
            }
          }
          this.responseData2.push({
            Level0: Level0,
            Level1: Level1,
            Level2: Level2,
            is_entryPoint: is_entryPoint,
            isCheckbox: !!(
              (calc_type.toString() == 'mean' &&
                isdervied.toString() == 'true') ||
              ismultipunch.toString() == 'true'
            ),
            Level3: Level2Array,
            sortId: sortid,
            customGroupRowOrder: customGroupRowOrder,
            customCalculationRowOrder: customCalculationRowOrder,
          });
        } else {
          const LevelMetricOptions = _.uniq(
            _.map(
              _.filter(
                listToUpdate,
                (data) =>
                  data.Level0 == Level0 &&
                  data.Level1 == Level1 &&
                  data.Level2 == Level2 &&
                  data.MetricOption != ''
              ),
              'MetricOption'
            )
          );
          if (LevelMetricOptions.length > 0) {
            for (const element of LevelMetricOptions) {
              const MetricOptions = _.uniq(
                _.filter(
                  listToUpdate,
                  (data) =>
                    data.Level0 == Level0 &&
                    data.Level1 == Level1 &&
                    data.Level2 == Level2 &&
                    data.MetricOption == element
                )
              );

              if (MetricOptions.length > 0) {
                Level2Array.push({
                  MetricOption: MetricOptions[0].MetricOption,
                  response_code:
                    MetricOptions[0].calculation_type == 'RespGrp' &&
                      MetricOptions[0].is_derived_variable == true
                      ? MetricOptions[0].derived_metric_calculation
                      : MetricOptions[0].response_code,
                  data_variable_code: MetricOptions[0].data_variable_code,
                  dataYearCode: MetricOptions[0].dataYearCode,
                  calculation_type: MetricOptions[0].calculation_type,
                  tooltip: MetricOptions[0].MetricOption,
                  isDerviedVariable: MetricOptions[0].is_derived_variable,
                  sort_id: MetricOptions[0].sort_id,
                  isMultiPunch: this.GetMultiPunchValue(
                    MetricOptions[0].is_multi_punch
                  ),
                  MultiPunchNumber: MetricOptions[0].is_multi_punch,
                  year_code: MetricOptions[0].year_code,
                  date: MetricOptions[0].date,
                  is_dimension: MetricOptions[0].is_dimension,
                  isCustomGroup: false,
                  isCustomCalculation: false,
                  kibanaFilterScript: '',
                  customGroupId: '',
                  customCalculationId: '',
                });
              }
            }
            this.responseData2.push({
              Level0: Level0,
              Level1: Level1,
              Level2: Level2,
              is_entryPoint: is_entryPoint,
              isCheckbox: true,
              Level3: Level2Array,
              sortId: sortid,
              customGroupRowOrder: customGroupRowOrder,
              customCalculationRowOrder: customCalculationRowOrder,
            });
          }
        }
      }
    }

    var existingIndex;
    if (entryPointKey == 'CustomCalculation') {
      existingIndex = this.dataObject[
        entryPointKey as keyof DataObject
      ].findIndex(
        (entry: checkboxSelectionWithParent) =>
          entry &&
          entry.Data &&
          entry.Data.length > 0 &&
          entry.Parent === Level1 &&
          entry.customCalculationRowOrder === customCalculationRowOrder
      );
    } else if (entryPointKey == 'CustomGroup') {
      existingIndex = this.dataObject[
        entryPointKey as keyof DataObject
      ].findIndex(
        (entry: checkboxSelectionWithParent) =>
          entry &&
          entry.Data &&
          entry.Data.length > 0 &&
          entry.Parent === Level1 &&
          entry.customGroupRowOrder === customGroupRowOrder
      );
    } else {
      existingIndex = this.dataObject[
        entryPointKey as keyof DataObject
      ].findIndex(
        (entry: checkboxSelectionWithParent) =>
          entry &&
          entry.Data &&
          entry.Data.length > 0 &&
          entry.Parent === Level1
      );
    }
    if (existingIndex !== -1) {
      const existingEntry: checkboxSelectionWithParent =
        this.dataObject[entryPointKey as keyof DataObject][existingIndex];
      const dataLengthForParent = existingEntry.Data.length;

      const filteredData = existingEntry.Data.filter((item) => {
        const level1Parts = item.Child.split('|');
        return (
          level1Parts.length > 0 &&
          level1Parts[0] === Level1 &&
          level1Parts[1] === Level2
        );
      });

      if (filteredData.length === this.responseData2[0].Level3.length) {
        this.isSelectAll = true;
      }
    }
  }

  plotNextLevel3(
    entryPointKey: string,
    Level0: string,
    Level1: string,
    Level2: string,
    Level3: string,
    sortid: number,
    is_entryPoint: boolean,
    customGroupRowOrder?: number,
    customCalculationRowOrder?: number
  ) {
    let name = Level0 + '|' + Level1 + "|" + Level2 + "|" + Level3 + "|" + entryPointKey
    switch (entryPointKey) {
      case 'CustomCalculation':
        this.sidePanelDisplay = true;
        this.selectedLevel1Item = Level3;
        this.columnPanelDisplay = false;
        this.sidePanelLevel1Display = true;
        this.sidePanelLevel2Display = true;
        if (this.droppedLevel3Item == name) {
          if (this.droppedLevel3ItemSortId == sortid)
            this.sidePanelLevel3Display = !this.sidePanelLevel3Display;
          else
            this.sidePanelLevel3Display = true
        }
        else
          this.sidePanelLevel3Display = true;
        this.droppedLevel3ItemSortId = sortid;
        this.droppedLevel3Item = name;
        //this.sidePanelLevel3Display = true;
        this.sidePanelLevel4Display = false;
        this.sidePanelLevel5Display = false;
        this.sidePanelNestingDisplay = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelDisplay = false;
        break;
      case 'CustomGroup':
        this.sidePanelDisplay = true;
        this.selectedLevel1Item = Level3;
        this.columnPanelDisplay = false;
        this.sidePanelLevel1Display = true;
        this.sidePanelLevel2Display = true;
        if (this.droppedLevel3Item == name) {
          if (this.droppedLevel3ItemSortId == sortid)
            this.sidePanelLevel3Display = !this.sidePanelLevel3Display;
          else
            this.sidePanelLevel3Display = true
        }
        else
          this.sidePanelLevel3Display = true;
        this.droppedLevel3ItemSortId = sortid;
        this.droppedLevel3Item = name;
        //this.sidePanelLevel3Display = true;
        this.sidePanelLevel4Display = false;
        this.sidePanelLevel5Display = false;
        this.sidePanelNestingDisplay = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelDisplay = false;
        break;
      case 'Row':
        this.sidePanelDisplay = true;
        this.selectedLevel1Item = Level3;
        this.columnPanelDisplay = false;
        this.sidePanelLevel1Display = true;
        this.sidePanelLevel2Display = true;

        if (this.droppedLevel3Item == name) {
          if (this.droppedLevel3ItemSortId == sortid)
            this.sidePanelLevel3Display = !this.sidePanelLevel3Display;
          else
            this.sidePanelLevel3Display = true
        }
        else
          this.sidePanelLevel3Display = true;
        this.droppedLevel3ItemSortId = sortid;
        this.droppedLevel3Item = name;
        //this.sidePanelLevel3Display = true;
        this.sidePanelLevel4Display = false;
        this.sidePanelLevel5Display = false;
        this.sidePanelNestingDisplay = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelDisplay = false;
        break;
      case 'RowNesting':
        this.sidePanelDisplay = false;
        this.selectedLevel1Item = Level3;
        this.columnPanelDisplay = false;
        this.sidePanelNestingDisplay = true;
        this.sidePanelLevel1NestingDisplay = true;
        this.sidePanelLevel2NestingDisplay = true;
        if (this.droppedLevel3Item == name) {
          if (this.droppedLevel3ItemSortId == sortid)
            this.sidePanelLevel3NestingDisplay = !this.sidePanelLevel3NestingDisplay;
          else
            this.sidePanelLevel3NestingDisplay = true
        }
        else
          this.sidePanelLevel3NestingDisplay = true;
        this.droppedLevel3ItemSortId = sortid;
        this.droppedLevel3Item = name;
        //this.sidePanelLevel3NestingDisplay = true;
        this.sidePanelLevel4NestingDisplay = false;
        this.sidePanelLevel5NestingDisplay = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelDisplay = false;
        break;
      case 'Column':
        this.sidePanelDisplay = false;
        this.selectedLevel1Item = Level3;
        this.sidePanelNestingDisplay = false;
        this.columnPanelDisplay = true;
        this.columnPanelLevel1Display = true;
        this.columnPanelLevel2Display = true;

        if (this.droppedLevel3Item == name) {
          if (this.droppedLevel3ItemSortId == sortid)
            this.columnPanelLevel3Display = !this.columnPanelLevel3Display;
          else
            this.columnPanelLevel3Display = true
        }
        else
          this.columnPanelLevel3Display = true;
        this.droppedLevel3ItemSortId = sortid;
        this.droppedLevel3Item = name;
        //this.columnPanelLevel3Display = true;
        this.columnPanelLevel4Display = false;
        this.columnPanelLevel5Display = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelDisplay = false;
        break;
      case 'ColNesting':
        this.sidePanelDisplay = false;
        this.selectedLevel1Item = Level3;
        this.sidePanelNestingDisplay = false;
        this.columnPanelDisplay = false;
        this.columnNestingPanelDispaly = true;
        this.columnPanelNestLeve11Display = true;
        this.columnPanelNestLeve12Display = true;

        if (this.droppedLevel3Item == name) {
          if (this.droppedLevel3ItemSortId == sortid)
            this.columnPanelNestLeve13Display = !this.columnPanelNestLeve13Display;
          else
            this.columnPanelNestLeve13Display = true
        }
        else
          this.columnPanelNestLeve13Display = true;
        this.droppedLevel3ItemSortId = sortid;
        this.droppedLevel3Item = name;
        //this.columnPanelNestLeve13Display = true;
        this.columnPanelNestLeve14Display = false;
        this.columnPanelNestLeve15Display = false;
        this.filterPanelDisplay = false;
        break;

      case 'Filter':
        this.sidePanelDisplay = false;
        this.selectedLevel1Item = Level3;
        this.filterPanelDisplay = true;
        this.sidePanelNestingDisplay = false;
        this.columnPanelDisplay = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelLevel1Display = true;
        this.filterPanelLevel2Display = true;
        if (this.droppedLevel3Item == name) {
          if (this.droppedLevel3ItemSortId == sortid)
            this.filterPanelLevel3Display = !this.filterPanelLevel3Display;
          else
            this.filterPanelLevel3Display = true
        }
        else
          this.filterPanelLevel3Display = true;
        this.droppedLevel3ItemSortId = sortid;
        this.droppedLevel3Item = name;
        //this.filterPanelLevel3Display = true;
        this.filterPanelLevel4Display = false;
        this.filterPanelLevel5Display = false;
        break;
    }

    this.responseData3 = [];
    this.isSelectAll = false;
    this.searchTextLevel1 = '';
    this.searchTextLevel2 = '';
    this.searchTextLevel3 = '';
    this.searchTextLevel4 = '';
    this.searchTextLevel5 = '';
    let listToUpdate;
    const Level0Variables = _.uniq(
      _.map(
        _.filter(
          this.variableTabData,
          (data) =>
            data.Level0 == Level0 &&
            data.Level1 == Level1 &&
            data.Level2 == Level2 &&
            data.Level3 == Level3
        ),
        'Level3'
      )
    );
    const calc_type = _.uniq(
      _.map(
        _.filter(
          this.variableTabData,
          (data) =>
            data.Level0 == Level0 &&
            data.Level1 === Level1 &&
            data.Level2 == Level2 &&
            data.Level3 == Level3
        ),
        'calculation_type'
      )
    );
    const isdervied = _.uniq(
      _.map(
        _.filter(
          this.variableTabData,
          (data) =>
            data.Level0 == Level0 &&
            data.Level1 === Level1 &&
            data.Level2 == Level2 &&
            data.Level3 == Level3
        ),
        'is_derived_variable'
      )
    );
    const ismultipunch = this.GetMultiPunchValue(
      _.uniq(
        _.map(
          _.filter(
            this.variableTabData,
            (data) =>
              data.Level0 == Level0 &&
              data.Level1 === Level1 &&
              data.Level2 == Level2 &&
              data.Level3 == Level3
          ),
          'is_multi_punch'
        )
      )
    );

    if (Level0Variables.length > 0) {
      for (const ele of Level0Variables) {
        const Level2Array: {
          MetricOption: string;
          response_code: string;
          data_variable_code: string;
          dataYearCode: string;
          calculation_type: string;
          tooltip: string;
          isDerviedVariable: boolean;
          sort_id: number;
          isMultiPunch: boolean;
          year_code: string;
          date: string;
          is_dimension: boolean;
          isCustomGroup: boolean;
          isCustomCalculation: boolean;
          kibanaFilterScript: string;
          customGroupId: string;
          customCalculationId: string;
          MultiPunchNumber: number;
        }[] = [];
        listToUpdate = this.variableTabData.filter(
          (a: any) =>
            a.Level0 === Level0 &&
            a.Level1 == Level1 &&
            a.Level2 == Level2 &&
            a.Level3 == Level3
        );
        listToUpdate = _.sortBy(listToUpdate, 'sort_id');
        const Level1Variables = _.uniq(
          _.map(
            _.filter(
              listToUpdate,
              (data) =>
                data.Level0 == Level0 &&
                data.Level1 == Level1 &&
                data.Level2 == Level2 &&
                data.Level3 == Level3 &&
                data.Level4 != ''
            ),
            'Level4'
          )
        );
        if (Level1Variables.length > 0) {
          for (const element of Level1Variables) {
            if (ismultipunch.toString() == 'false') {
              Level2Array.push({
                MetricOption: element,
                response_code: '',
                data_variable_code: '',
                dataYearCode: '',
                calculation_type: '',
                tooltip: element,
                isDerviedVariable: false,
                sort_id: 0,
                isMultiPunch: false,
                year_code: '',
                date: '',
                is_dimension: false,
                isCustomGroup: false,
                isCustomCalculation: false,
                kibanaFilterScript: '',
                customGroupId: '',
                customCalculationId: '',
                MultiPunchNumber: 0,
              });
            } else {
              const MetricOptions = _.uniq(
                _.filter(
                  listToUpdate,
                  (data) =>
                    data.Level0 == Level0 &&
                    data.Level1 == Level1 &&
                    data.Level2 == Level2 &&
                    data.Level3 == Level3 &&
                    data.Level4 == element
                )
              );
              if (MetricOptions.length > 0) {
                Level2Array.push({
                  MetricOption: MetricOptions[0].Level3,
                  response_code:
                    MetricOptions[0].calculation_type == 'RespGrp' &&
                      MetricOptions[0].is_derived_variable == true
                      ? MetricOptions[0].derived_metric_calculation
                      : MetricOptions[0].response_code,
                  data_variable_code: MetricOptions[0].data_variable_code,
                  dataYearCode: MetricOptions[0].dataYearCode,
                  calculation_type: MetricOptions[0].calculation_type,
                  tooltip: MetricOptions[0].Level3,
                  isDerviedVariable: MetricOptions[0].is_derived_variable,
                  sort_id: MetricOptions[0].sort_id,
                  isMultiPunch: this.GetMultiPunchValue(
                    MetricOptions[0].is_multi_punch
                  ),
                  MultiPunchNumber: MetricOptions[0].is_multi_punch,
                  year_code: MetricOptions[0].year_code,
                  date: MetricOptions[0].date,
                  is_dimension: MetricOptions[0].is_dimension,
                  isCustomGroup: false,
                  isCustomCalculation: false,
                  kibanaFilterScript: '',
                  customGroupId: '',
                  customCalculationId: '',
                });
              }
            }
          }
          this.responseData3.push({
            Level0: Level0,
            Level1: Level1,
            Level2: Level2,
            Level3: Level3,
            is_entryPoint: is_entryPoint,
            isCheckbox: !!(
              (calc_type.toString() == 'mean' &&
                isdervied.toString() == 'true') ||
              ismultipunch.toString() == 'true'
            ),
            Level4: Level2Array,
            sortId: sortid,
            customGroupRowOrder: customGroupRowOrder,
            customCalculationRowOrder: customCalculationRowOrder,
          });
        } else {
          const LevelMetricOptions = _.uniq(
            _.map(
              _.filter(
                listToUpdate,
                (data) =>
                  data.Level0 == Level0 &&
                  data.Level1 == Level1 &&
                  data.Level2 == Level2 &&
                  data.Level3 == Level3 &&
                  data.MetricOption != ''
              ),
              'MetricOption'
            )
          );
          if (LevelMetricOptions.length > 0) {
            for (const element of LevelMetricOptions) {
              const MetricOptions = _.uniq(
                _.filter(
                  listToUpdate,
                  (data) =>
                    data.Level0 == Level0 &&
                    data.Level1 == Level1 &&
                    data.Level2 == Level2 &&
                    data.Level3 == Level3 &&
                    data.MetricOption == element
                )
              );

              if (MetricOptions.length > 0) {
                Level2Array.push({
                  MetricOption: MetricOptions[0].MetricOption,
                  response_code:
                    MetricOptions[0].calculation_type == 'RespGrp' &&
                      MetricOptions[0].is_derived_variable == true
                      ? MetricOptions[0].derived_metric_calculation
                      : MetricOptions[0].response_code,
                  data_variable_code: MetricOptions[0].data_variable_code,
                  dataYearCode: MetricOptions[0].dataYearCode,
                  calculation_type: MetricOptions[0].calculation_type,
                  tooltip: MetricOptions[0].MetricOption,
                  isDerviedVariable: MetricOptions[0].is_derived_variable,
                  sort_id: MetricOptions[0].sort_id,
                  isMultiPunch: this.GetMultiPunchValue(
                    MetricOptions[0].is_multi_punch
                  ),
                  MultiPunchNumber: MetricOptions[0].is_multi_punch,
                  year_code: MetricOptions[0].year_code,
                  date: MetricOptions[0].date,
                  is_dimension: MetricOptions[0].is_dimension,
                  isCustomGroup: false,
                  isCustomCalculation: false,
                  kibanaFilterScript: '',
                  customGroupId: '',
                  customCalculationId: '',
                });
              }
            }
            this.responseData3.push({
              Level0: Level0,
              Level1: Level1,
              Level2: Level2,
              Level3: Level3,
              is_entryPoint: is_entryPoint,
              isCheckbox: true,
              Level4: Level2Array,
              sortId: sortid,
              customGroupRowOrder: customGroupRowOrder,
              customCalculationRowOrder: customCalculationRowOrder,
            });
          }
        }
      }
    }

    var existingIndex;
    if (entryPointKey == 'CustomCalculation') {
      existingIndex = this.dataObject[
        entryPointKey as keyof DataObject
      ].findIndex(
        (entry: checkboxSelectionWithParent) =>
          entry &&
          entry.Data &&
          entry.Data.length > 0 &&
          entry.Parent === Level1 &&
          entry.customCalculationRowOrder === customCalculationRowOrder
      );
    } else if (entryPointKey == 'CustomGroup') {
      existingIndex = this.dataObject[
        entryPointKey as keyof DataObject
      ].findIndex(
        (entry: checkboxSelectionWithParent) =>
          entry &&
          entry.Data &&
          entry.Data.length > 0 &&
          entry.Parent === Level1 &&
          entry.customGroupRowOrder === customGroupRowOrder
      );
    } else {
      existingIndex = this.dataObject[
        entryPointKey as keyof DataObject
      ].findIndex(
        (entry: checkboxSelectionWithParent) =>
          entry &&
          entry.Data &&
          entry.Data.length > 0 &&
          entry.Parent === Level1
      );
    }
    if (existingIndex !== -1) {
      const existingEntry: checkboxSelectionWithParent =
        this.dataObject[entryPointKey as keyof DataObject][existingIndex];
      const dataLengthForParent = existingEntry.Data.length;

      const filteredData = existingEntry.Data.filter((item) => {
        const level1Parts = item.Child.split('|');
        return (
          level1Parts.length > 0 &&
          level1Parts[0] === Level1 &&
          level1Parts[1] === Level2 &&
          level1Parts[2] === Level3
        );
      });

      if (filteredData.length === this.responseData3[0].Level4.length) {
        this.isSelectAll = true;
      }
    }
  }

  plotNextLevel4(
    entryPointKey: string,
    Level0: string,
    Level1: string,
    Level2: string,
    Level3: string,
    Level4: string,
    sortid: number,
    is_entryPoint: boolean,
    customGroupRowOrder?: number,
    customCalculationRowOrder?: number
  ) {
    let name = Level0 + '|' + Level1 + "|" + Level2 + "|" + Level3 + "|" + Level4 + "|" + entryPointKey
    switch (entryPointKey) {
      case 'CustomCalculation':
        this.sidePanelDisplay = true;
        this.selectedLevel1Item = Level4;
        this.columnPanelDisplay = false;
        this.sidePanelLevel1Display = true;
        this.sidePanelLevel2Display = true;
        this.sidePanelLevel3Display = true;
        if (this.droppedLevel4Item == name) {
          if (this.droppedLevel4ItemSortId == sortid)
            this.sidePanelLevel4Display = !this.sidePanelLevel4Display;
          else
            this.sidePanelLevel4Display = true
        }
        else
          this.sidePanelLevel4Display = true;
        this.droppedLevel4ItemSortId = sortid;
        this.droppedLevel4Item = name;
        //this.sidePanelLevel4Display = true;
        this.sidePanelLevel5Display = false;
        this.sidePanelNestingDisplay = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelDisplay = false;
        break;
      case 'CustomGroup':
        this.sidePanelDisplay = true;
        this.selectedLevel1Item = Level4;
        this.columnPanelDisplay = false;
        this.sidePanelLevel1Display = true;
        this.sidePanelLevel2Display = true;
        this.sidePanelLevel3Display = true;

        if (this.droppedLevel4Item == name) {
          if (this.droppedLevel4ItemSortId == sortid)
            this.sidePanelLevel4Display = !this.sidePanelLevel4Display;
          else
            this.sidePanelLevel4Display = true
        }
        else
          this.sidePanelLevel4Display = true;
        this.droppedLevel4ItemSortId = sortid;
        this.droppedLevel4Item = name;
        //this.sidePanelLevel4Display = true;
        this.sidePanelLevel5Display = false;
        this.sidePanelNestingDisplay = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelDisplay = false;
        break;
      case 'Row':
        this.sidePanelDisplay = true;
        this.selectedLevel1Item = Level4;
        this.columnPanelDisplay = false;
        this.sidePanelLevel1Display = true;
        this.sidePanelLevel2Display = true;
        this.sidePanelLevel3Display = true;

        if (this.droppedLevel4Item == name) {
          if (this.droppedLevel4ItemSortId == sortid)
            this.sidePanelLevel4Display = !this.sidePanelLevel4Display;
          else
            this.sidePanelLevel4Display = true
        }
        else
          this.sidePanelLevel4Display = true;
        this.droppedLevel4ItemSortId = sortid;
        this.droppedLevel4Item = name;
        //this.sidePanelLevel4Display = true;
        this.sidePanelLevel5Display = false;
        this.sidePanelNestingDisplay = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelDisplay = false;
        break;
      case 'RowNesting':
        this.sidePanelDisplay = false;
        this.selectedLevel1Item = Level4;
        this.columnPanelDisplay = false;
        this.sidePanelNestingDisplay = true;
        this.sidePanelLevel1NestingDisplay = true;
        this.sidePanelLevel2NestingDisplay = true;
        this.sidePanelLevel3NestingDisplay = true;
        if (this.droppedLevel4Item == name) {
          if (this.droppedLevel4ItemSortId == sortid)
            this.sidePanelLevel4NestingDisplay = !this.sidePanelLevel4NestingDisplay;
          else
            this.sidePanelLevel4NestingDisplay = true
        }
        else
          this.sidePanelLevel4NestingDisplay = true;
        this.droppedLevel4ItemSortId = sortid;
        this.droppedLevel4Item = name;
        //this.sidePanelLevel4NestingDisplay = true;
        this.sidePanelLevel5NestingDisplay = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelDisplay = false;
        break;
      case 'Column':
        this.sidePanelDisplay = false;
        this.selectedLevel1Item = Level4;
        this.sidePanelNestingDisplay = false;
        this.columnPanelDisplay = true;
        this.columnPanelLevel1Display = true;
        this.columnPanelLevel2Display = true;
        this.columnPanelLevel3Display = true;

        if (this.droppedLevel4Item == name) {
          if (this.droppedLevel4ItemSortId == sortid)
            this.columnPanelLevel4Display = !this.columnPanelLevel4Display;
          else
            this.columnPanelLevel4Display = true
        }
        else
          this.columnPanelLevel4Display = true;
        this.droppedLevel4ItemSortId = sortid;
        this.droppedLevel4Item = name;
        //this.columnPanelLevel4Display = true;
        this.columnPanelLevel5Display = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelDisplay = false;
        break;
      case 'ColNesting':
        this.sidePanelDisplay = false;
        this.selectedLevel1Item = Level4;
        this.sidePanelNestingDisplay = false;
        this.columnPanelDisplay = false;
        this.columnNestingPanelDispaly = true;
        this.columnPanelNestLeve11Display = true;
        this.columnPanelNestLeve12Display = true;
        this.columnPanelNestLeve13Display = true;
        if (this.droppedLevel4Item == name) {
          if (this.droppedLevel4ItemSortId == sortid)
            this.columnPanelNestLeve14Display = !this.columnPanelNestLeve14Display;
          else
            this.columnPanelNestLeve14Display = true
        }
        else
          this.columnPanelNestLeve14Display = true;
        this.droppedLevel4ItemSortId = sortid;
        this.droppedLevel4Item = name;
        //this.columnPanelNestLeve14Display = true;
        this.columnPanelNestLeve15Display = false;
        this.filterPanelDisplay = false;
        break;

      case 'Filter':
        this.sidePanelDisplay = false;
        this.selectedLevel1Item = Level4;
        this.filterPanelDisplay = true;
        this.sidePanelNestingDisplay = false;
        this.columnPanelDisplay = false;
        this.columnNestingPanelDispaly = false;
        this.filterPanelLevel1Display = true;
        this.filterPanelLevel2Display = true;
        this.filterPanelLevel3Display = true;
        if (this.droppedLevel4Item == name) {
          if (this.droppedLevel4ItemSortId == sortid)
            this.filterPanelLevel4Display = !this.filterPanelLevel4Display;
          else
            this.filterPanelLevel4Display = true
        }
        else
          this.filterPanelLevel4Display = true;
        this.droppedLevel4ItemSortId = sortid;
        this.droppedLevel4Item = name;
        //this.filterPanelLevel4Display = true;
        this.filterPanelLevel5Display = false;
        break;
    }

    this.responseData4 = [];
    this.isSelectAll = false;
    this.searchTextLevel1 = '';
    this.searchTextLevel2 = '';
    this.searchTextLevel3 = '';
    this.searchTextLevel4 = '';
    this.searchTextLevel5 = '';
    let listToUpdate;
    const Level0Variables = _.uniq(
      _.map(
        _.filter(
          this.variableTabData,
          (data) =>
            data.Level0 == Level0 &&
            data.Level1 == Level1 &&
            data.Level2 == Level2 &&
            data.Level3 == Level3 &&
            data.Level4 == Level4
        ),
        'Level4'
      )
    );
    const calc_type = _.uniq(
      _.map(
        _.filter(
          this.variableTabData,
          (data) =>
            data.Level0 == Level0 &&
            data.Level1 === Level1 &&
            data.Level2 == Level2 &&
            data.Level3 == Level3 &&
            data.Level4 == Level4
        ),
        'calculation_type'
      )
    );
    const isdervied = _.uniq(
      _.map(
        _.filter(
          this.variableTabData,
          (data) =>
            data.Level0 == Level0 &&
            data.Level1 === Level1 &&
            data.Level2 == Level2 &&
            data.Level3 == Level3 &&
            data.Level4 == Level4
        ),
        'is_derived_variable'
      )
    );
    const ismultipunch = this.GetMultiPunchValue(
      _.uniq(
        _.map(
          _.filter(
            this.variableTabData,
            (data) =>
              data.Level0 == Level0 &&
              data.Level1 === Level1 &&
              data.Level2 == Level2 &&
              data.Level3 == Level3 &&
              data.Level4 == Level4
          ),
          'is_multi_punch'
        )
      )
    );

    if (Level0Variables.length > 0) {
      for (const ele of Level0Variables) {
        const Level2Array: {
          MetricOption: string;
          response_code: string;
          data_variable_code: string;
          dataYearCode: string;
          calculation_type: string;
          tooltip: string;
          isDerviedVariable: boolean;
          sort_id: number;
          isMultiPunch: boolean;
          year_code: string;
          date: string;
          is_dimension: boolean;
          isCustomGroup: boolean;
          isCustomCalculation: boolean;
          kibanaFilterScript: string;
          customGroupId: string;
          customCalculationId: string;
          MultiPunchNumber: number;
        }[] = [];
        listToUpdate = this.variableTabData.filter(
          (a: any) =>
            a.Level0 === Level0 &&
            a.Level1 == Level1 &&
            a.Level2 == Level2 &&
            a.Level3 == Level3 &&
            a.Level4 == Level4
        );
        listToUpdate = _.sortBy(listToUpdate, 'sort_id');
        const Level1Variables = _.uniq(
          _.map(
            _.filter(
              listToUpdate,
              (data) =>
                data.Level0 == Level0 &&
                data.Level1 == Level1 &&
                data.Level2 == Level2 &&
                data.Level3 == Level3 &&
                data.Level4 == Level4 &&
                data.Level5 != ''
            ),
            'Level5'
          )
        );
        if (Level1Variables.length > 0) {
          for (const element of Level1Variables) {
            if (ismultipunch.toString() == 'false') {
              Level2Array.push({
                MetricOption: element,
                response_code: '',
                data_variable_code: '',
                dataYearCode: '',
                calculation_type: '',
                tooltip: element,
                isDerviedVariable: false,
                sort_id: 0,
                isMultiPunch: false,
                year_code: '',
                date: '',
                is_dimension: false,
                isCustomGroup: false,
                isCustomCalculation: false,
                kibanaFilterScript: '',
                customGroupId: '',
                customCalculationId: '',
                MultiPunchNumber: 0,
              });
            } else {
              const MetricOptions = _.uniq(
                _.filter(
                  listToUpdate,
                  (data) =>
                    data.Level0 == Level0 &&
                    data.Level1 == Level1 &&
                    data.Level2 == Level2 &&
                    data.Level3 == Level3 &&
                    data.Level4 == Level4 &&
                    data.Level5 == element
                )
              );
              if (MetricOptions.length > 0) {
                Level2Array.push({
                  MetricOption: MetricOptions[0].Level4,
                  response_code:
                    MetricOptions[0].calculation_type == 'RespGrp' &&
                      MetricOptions[0].is_derived_variable == true
                      ? MetricOptions[0].derived_metric_calculation
                      : MetricOptions[0].response_code,
                  data_variable_code: MetricOptions[0].data_variable_code,
                  dataYearCode: MetricOptions[0].dataYearCode,
                  calculation_type: MetricOptions[0].calculation_type,
                  tooltip: MetricOptions[0].Level3,
                  isDerviedVariable: MetricOptions[0].is_derived_variable,
                  sort_id: MetricOptions[0].sort_id,
                  isMultiPunch: this.GetMultiPunchValue(
                    MetricOptions[0].is_multi_punch
                  ),
                  MultiPunchNumber: MetricOptions[0].is_multi_punch,
                  year_code: MetricOptions[0].year_code,
                  date: MetricOptions[0].date,
                  is_dimension: MetricOptions[0].is_dimension,
                  isCustomGroup: false,
                  isCustomCalculation: false,
                  kibanaFilterScript: '',
                  customGroupId: '',
                  customCalculationId: '',
                });
              }
            }
          }
          this.responseData4.push({
            Level0: Level0,
            Level1: Level1,
            Level2: Level2,
            Level3: Level3,
            Level4: Level4,
            is_entryPoint: is_entryPoint,
            isCheckbox: !!(
              (calc_type.toString() == 'mean' &&
                isdervied.toString() == 'true') ||
              ismultipunch.toString() == 'true'
            ),
            Level5: Level2Array,
            sortId: sortid,
            customGroupRowOrder: customGroupRowOrder,
            customCalculationRowOrder: customCalculationRowOrder,
          });
        } else {
          const LevelMetricOptions = _.uniq(
            _.map(
              _.filter(
                listToUpdate,
                (data) =>
                  data.Level0 == Level0 &&
                  data.Level1 == Level1 &&
                  data.Level2 == Level2 &&
                  data.Level3 == Level3 &&
                  data.Level4 == Level4 &&
                  data.MetricOption != ''
              ),
              'MetricOption'
            )
          );
          if (LevelMetricOptions.length > 0) {
            for (const element of LevelMetricOptions) {
              const MetricOptions = _.uniq(
                _.filter(
                  listToUpdate,
                  (data) =>
                    data.Level0 == Level0 &&
                    data.Level1 == Level1 &&
                    data.Level2 == Level2 &&
                    data.Level3 == Level3 &&
                    data.Level4 == Level4 &&
                    data.MetricOption == element
                )
              );

              if (MetricOptions.length > 0) {
                Level2Array.push({
                  MetricOption: MetricOptions[0].MetricOption,
                  response_code:
                    MetricOptions[0].calculation_type == 'RespGrp' &&
                      MetricOptions[0].is_derived_variable == true
                      ? MetricOptions[0].derived_metric_calculation
                      : MetricOptions[0].response_code,
                  data_variable_code: MetricOptions[0].data_variable_code,
                  dataYearCode: MetricOptions[0].dataYearCode,
                  calculation_type: MetricOptions[0].calculation_type,
                  tooltip: MetricOptions[0].MetricOption,
                  isDerviedVariable: MetricOptions[0].is_derived_variable,
                  sort_id: MetricOptions[0].sort_id,
                  isMultiPunch: this.GetMultiPunchValue(
                    MetricOptions[0].is_multi_punch
                  ),
                  MultiPunchNumber: MetricOptions[0].is_multi_punch,
                  year_code: MetricOptions[0].year_code,
                  date: MetricOptions[0].date,
                  is_dimension: MetricOptions[0].is_dimension,
                  isCustomGroup: false,
                  isCustomCalculation: false,
                  kibanaFilterScript: '',
                  customGroupId: '',
                  customCalculationId: '',
                });
              }
            }
            this.responseData4.push({
              Level0: Level0,
              Level1: Level1,
              Level2: Level2,
              Level3: Level3,
              Level4: Level4,
              is_entryPoint: is_entryPoint,
              isCheckbox: true,
              Level5: Level2Array,
              sortId: sortid,
              customGroupRowOrder: customGroupRowOrder,
              customCalculationRowOrder: customCalculationRowOrder,
            });
          }
        }
      }
    }

    var existingIndex;
    if (entryPointKey == 'CustomCalculation') {
      existingIndex = this.dataObject[
        entryPointKey as keyof DataObject
      ].findIndex(
        (entry: checkboxSelectionWithParent) =>
          entry &&
          entry.Data &&
          entry.Data.length > 0 &&
          entry.Parent === Level1 &&
          entry.customCalculationRowOrder === customCalculationRowOrder
      );
    } else if (entryPointKey == 'CustomGroup') {
      existingIndex = this.dataObject[
        entryPointKey as keyof DataObject
      ].findIndex(
        (entry: checkboxSelectionWithParent) =>
          entry &&
          entry.Data &&
          entry.Data.length > 0 &&
          entry.Parent === Level1 &&
          entry.customGroupRowOrder === customGroupRowOrder
      );
    } else {
      existingIndex = this.dataObject[
        entryPointKey as keyof DataObject
      ].findIndex(
        (entry: checkboxSelectionWithParent) =>
          entry &&
          entry.Data &&
          entry.Data.length > 0 &&
          entry.Parent === Level1
      );
    }

    if (existingIndex !== -1) {
      const existingEntry: checkboxSelectionWithParent =
        this.dataObject[entryPointKey as keyof DataObject][existingIndex];
      const dataLengthForParent = existingEntry.Data.length;

      const filteredData = existingEntry.Data.filter((item) => {
        const level1Parts = item.Child.split('|');
        return (
          level1Parts.length > 0 &&
          level1Parts[0] === Level1 &&
          level1Parts[1] === Level2 &&
          level1Parts[2] === Level3 &&
          level1Parts[3] === Level4
        );
      });

      if (filteredData.length === this.responseData4[0].Level4.length) {
        this.isSelectAll = true;
      }
    }
  }

  plotNextLevel5(
    entryPointKey: string,
    Level0: string,
    Level1: string,
    Level2: string,
    Level3: string,
    Level4: string,
    Level5: string,
    sortid: number,
    is_entryPoint: boolean,
    customGroupRowOrder?: number,
    customCalculationRowOrder?: number
  ) { }

  private ResetPanel() {
    this.sidePanelDisplay = false;
    this.sidePanelNestingDisplay = false;
    this.columnPanelDisplay = false;
    this.columnNestingPanelDispaly = false;
    this.filterPanelDisplay = false;
    //window.location.reload();
  }

  private isClickInside(event: Event, className: string): boolean {
    const elements = document.querySelectorAll(className);
    for (let i = 0; i < elements.length; i++) {
      const container = elements[i];
      if (container.contains(event.target as Node)) {
        return true;
      }
    }
    return false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    //Check if the click event occurred outside the <div> block.
    //document.addEventListener('mousemove', () => this._authenticateService.resetSessionTimeoutTimer());
    //document.addEventListener('keydown', () => this._authenticateService.resetSessionTimeoutTimer());
    if (this.sidePanelDisplay == true) {
      if (!this.isClickInside(event, '.RowPanel')) {
        this.sidePanelDisplay = false;
      }
    }
    if (this.sidePanelNestingDisplay == true) {
      if (!this.isClickInside(event, '.RowNestPanel')) {
        this.sidePanelNestingDisplay = false;
      }
    }
    if (this.columnPanelDisplay == true) {
      if (!this.isClickInside(event, '.ColPanel')) {
        this.columnPanelDisplay = false;
      }
    }
    if (this.columnNestingPanelDispaly == true) {
      if (!this.isClickInside(event, '.ColNestPanel')) {
        this.columnNestingPanelDispaly = false;
      }
    }
    if (this.filterPanelDisplay == true) {
      if (!this.isClickInside(event, '.FilterPanel')) {
        this.filterPanelDisplay = false;
      }
    }
    if (this.isSelectionSummary == true) {
      if (!this.isClickInside(event, '.summaryPanel')) {
        this.isSelectionSummary = false;
      }
    }
    if (this.sampleSizeDropdown == true) {
      if (!this.isClickInside(event, '.significance')) {
        this.sampleSizeDropdown = false;
        this.isReference = false;
      }
    }
    if (this.dataDropDown == true) {
      if (!this.isClickInside(event, '.dataDropdown')) {
        this.dataDropDown = false;
      }
    }
    if (this.confidenceLevelDropDown == true) {
      if (!this.isClickInside(event, '.confidance')) {
        this.confidenceLevelDropDown = false;
      }
    }
    if (this.referenceLevelDropDown) {
      if (!this.isClickInside(event, '.reference')) {
        this.referenceLevelDropDown = false;
      }
    }
    if (this.weightDropDown) {
      if (!this.isClickInside(event, '.weight')) {
        this.weightDropDown = false;
      }
    }
    if (this.showCustomMetricsPopup) {
      if (!this.isClickInside(event, '.customMetric')) {
        this.showCustomMetricsPopup = false;
      }
    }

  }

  rearrangeSelectionObject(
    panel: string,
    currentDestination: string,
    item: {
      Level0: string;
      Level1: string;
      is_custmGrpPresent: boolean;
      customGroupId: string;
    }
  ) {
    let entryText =
      panel == 'row'
        ? 'Row'
        : panel == 'col'
          ? 'Column'
          : panel == 'rowNest'
            ? 'RowNesting'
            : panel == 'colNest'
              ? 'ColNesting'
              : panel == 'customGroup'
                ? 'CustomGroup'
                : 'Filter';
    let destinationPanel =
      currentDestination == 'row'
        ? 'Row'
        : currentDestination == 'col'
          ? 'Column'
          : currentDestination == 'rowNest'
            ? 'RowNesting'
            : currentDestination == 'colNest'
              ? 'ColNesting'
              : currentDestination == 'customGroup'
                ? 'CustomGroup'
                : 'Filter';

    let existingIndex = -1;
    if (item.is_custmGrpPresent) {
      existingIndex = this.dataObject[entryText as keyof DataObject].findIndex(
        (entry) =>
          entry.Parent === item.Level1 &&
          entry.customGroupId == item.customGroupId
      );
    } else {
      existingIndex = this.dataObject[entryText as keyof DataObject].findIndex(
        (entry) => entry.Parent === item.Level1
      );
    }
    if (existingIndex !== -1) {
      const selectedItem =
        this.dataObject[entryText as keyof DataObject][existingIndex];
      this.dataObject[entryText as keyof DataObject].splice(existingIndex, 1);
      if (
        this.selectedDataFormat == 'Aggregated' &&
        currentDestination == 'filter'
      ) {
        selectedItem.Data.splice(1);
        this.dataObject[destinationPanel as keyof DataObject].push(
          selectedItem
        );
      } else
        this.dataObject[destinationPanel as keyof DataObject].push(
          selectedItem
        );
      this.updateSelectAllMetricStatus(destinationPanel, item.Level0, item.Level1);
      this.getLimitedDataPreview();
    }
  }

  private getEntryPointKey(selection: string): keyof DataObject {
    switch (selection) {
      case 'CustomGroup':
        return 'CustomGroup';
      case 'CustomCalculation':
        return 'CustomCalculation';
      case 'Row':
        return 'Row';
      case 'RowNesting':
        return 'RowNesting';
      case 'Column':
        return 'Column';
      case 'ColNesting':
        return 'ColNesting';
      case 'Filter':
        return 'Filter';
      default:
        return 'Row'; // Adjust the default based on your specific use case
    }
  }

  updateSelectAllMetricStatus(
    selection: string,
    parent: string,
    headerText: string
  ) {
    const entryPointKey = this.getEntryPointKey(selection);
    let existingIndex = -1;
    existingIndex = this.dataObject[
      entryPointKey as keyof DataObject
    ].findIndex((entry) => entry.Parent === headerText);

    if (existingIndex != -1) {
      const existingEntry =
        this.dataObject[entryPointKey as keyof DataObject][existingIndex];
      let item =
        selection == 'Row'
          ? this.listAItemsList.filter(
            (x) => x.Level0 == parent && x.Level1 == headerText
          )
          : selection == 'RowNesting'
            ? this.listBItemsList.filter(
              (x) => x.Level0 == parent && x.Level1 == headerText
            )
            : selection == 'Column'
              ? this.listCItemsList.filter(
                (x) => x.Level0 == parent && x.Level1 == headerText
              )
              : this.listDItemsList.filter(
                (x) => x.Level0 == parent && x.Level1 == headerText
              );

      if (item.length > 0) {
        item[0].isSelectAllMetrics =
          item[0].isSelectAllMetricsCount == existingEntry.Data.length
            ? true
            : false;
      }
    }
  }

  private hasDuplicateItem(
    prevDestination: string,
    currentDestination: string,
    itemdata: {
      Level0: string;
      Level1: string;
      customGroupId: string;
      is_custmGrpPresent: boolean;
    },
    Level0: string,
    Level1: string,
    destinationArray: {
      Level0: string;
      Level1: string;
      customGroupId: string;
    }[]
    //customType: string='Custom Group'
  ): boolean {
    // Check if any object in destinationArray has the specified Level0 property equal to itemData
    if (
      prevDestination != currentDestination &&
      prevDestination != 'variableList'
    ) {
      if (itemdata.Level0 == itemdata.Level1) {
        return destinationArray.some((item) => item.Level0 === itemdata.Level0);
      } else {
        if (itemdata.is_custmGrpPresent) {
          return destinationArray.some(
            (item) =>
              item.Level0 === itemdata.Level0 &&
              item.Level1 == itemdata.Level1 &&
              item.customGroupId == itemdata.customGroupId
          );
        } else {
          return destinationArray.some(
            (item) =>
              item.Level0 === itemdata.Level0 && item.Level1 == itemdata.Level1
          );
        }
      }
    } else {
      if (
        Level0.indexOf('Custom Percentage Variable') > -1 ||
        Level0.indexOf('Custom Mean Variable') > -1
      ) {
        return destinationArray.some(
          (item) =>
            item.Level0 === Level0 &&
            item.Level1 == Level1 &&
            item.customGroupId == this.customGroupId
        );
      } else {
        return destinationArray.some(
          (item) => item.Level0 === Level0 && item.Level1 == Level1
        );
      }
    }
  }

  onDrop(destination: string, event: CdkDragDrop<any>) {
    this.ResetPanel();
    if (event.previousContainer !== event.container && event.isPointerOverContainer) {
      // Get the item's data from the source container
      let prevDestination = event.previousContainer.id;
      let currentDestination = event.container.id;
      const itemData = event.previousContainer.data[event.previousIndex];

      if (destination == 'PreviewTable') {
        this.removeItem((event.previousContainer.id == 'row' ? "listAItemsList" : event.previousContainer.id == 'rowNest' ? 'listBItemsList' : event.previousContainer.id == 'col' ? 'listCItemsList' : event.previousContainer.id == 'colNest' ? 'listDItemsList' : 'listEItemsList'), itemData, (event.previousContainer.id == 'row' ? "Row" : event.previousContainer.id == 'rowNest' ? 'RowNesting' : event.previousContainer.id == 'col' ? 'Column' : event.previousContainer.id == 'colNest' ? 'ColNesting' : 'Filter'));
      }
      else {
        const isItemPresent = this.hasDuplicateItem(
          prevDestination,
          currentDestination,
          itemData,
          this.selectedVariableLevel0,
          this.selectedVariableLevel1,
          this.getListByDestination(destination)
          //customType
        );

        if (!isItemPresent || this.isCreateOrEditCustomGroup()) {
          if (this.isCreateOrEditCustomGroup()) {
            //if the same metric is present already without any selection dont allow drop same metric again
            var hasUnSelectedMetric = this.listCustomGroupItemsList.filter(
              (s) => {
                if (
                  s.customGroupRowOrder === 0 &&
                  s.Level1 === this.selectedVariableLevel1
                ) {
                  return true;
                }
                return false;
              }
            );
            if (hasUnSelectedMetric.length > 0) {
              this.errorPopup(
                'Please select a value for the dragged metric which is present already'
              );
              return;
            }
          }

          if (this.selectedVariableLevel0.indexOf('Custom Percentage Variable') > -1) {
            this.toggleActiveBlock('total_base');
            this.enableAnswerBase = false;
          } else {
            this.updateToggleBasedOnCustomGroup();
          }

          this.handleItemDrop(
            prevDestination,
            destination,
            currentDestination,
            itemData
          );
        } else {
          this.getValidation(
            'AlreadyExists',
            this.getValidationType(destination),
            ''
          );
        }
      }
    } else if (event.previousContainer === event.container) {
      let item = event.previousContainer.data[event.previousIndex];
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      if (item) {
        let selectedlevelName = item['Level1'];
        if (selectedlevelName) {
          let panel = event.container.id;
          let panelText =
            panel == 'row'
              ? 'Row'
              : panel == 'col'
                ? 'Column'
                : panel == 'rowNest'
                  ? 'RowNesting'
                  : panel == 'colNest'
                    ? 'ColNesting'
                    : panel == 'customGroup'
                      ? 'CustomGroup'
                      : panel == 'customCalculation'
                        ? 'CustomCalculation'
                        : panel;
          this.rearrangeItemsInArray(
            panelText,
            selectedlevelName,
            event.currentIndex
          );
        }
      }
    }
    this.enableOrDisableProfiling();
    this.is_entrypoint = false;
  }

  getValidationType(destination: string): string {
    switch (destination) {
      case 'listAItemsList':
        return 'Row';
      case 'listBItemsList':
        return 'Row_Nesting';
      case 'listCItemsList':
        return 'Column';
      case 'listDItemsList':
        return 'Column_Nesting';
      case 'listEItemsList':
        return 'Filters';
      case 'listCustomGroupItemsList':
        return 'Custom_Groups';
      default:
        return '';
    }
  }

  getValidation(Name: string, Selection: string, Type: string): boolean {
    if (Name == 'entrypoints' && Selection == 'Rows') {
      this.errorPopup("Entry Points can't be selected in Rows.");
    } else if (Name == 'timeperiod' && Selection == 'Rows') {
      this.errorPopup("Time Period can't be selected in Rows.");
    } else if (Name == 'dimensions' && Selection == 'Rows') {
      this.errorPopup("Dimensions can't be selected in Rows.");
    } else if (Name == 'singleentrypoint' && Selection == 'columns') {
      this.errorPopup(
        "Different Entry Points can't be selected in Column Parent Metrics."
      );
    } else if (Name == 'entrypoints' && Selection == 'columns') {
      this.errorPopup(
        'Entry Points and Time Periods(Dimensions) cannot be selected in same level.'
      );
    } else if (Name == 'AlreadyExists') {
      this.errorPopup('Duplication of Metrics not allowed.');
    } else if (Name == 'metrics') {
      this.errorPopup('Mean Variables cannot be selected in ' + Selection + '');
    } else if (Name == 'max_nesting') {
      this.errorPopup(
        'Only Two Levels of Nesting allowed in ' + Selection + ''
      );
    } else if (Name == 'timeperiod' && Selection == 'Filters.') {
      this.errorPopup(
        "Multiple Time Period Groupings can't be selected together in Filters."
      );
    } else if (Name == 'timeperiod' && Selection == 'Columns/Column Nesting') {
      this.errorPopup("Time Periods can't be selected in multiple levels.");
    } else if (Name == 'dimension' && Selection == 'Columns/Column Nesting') {
      this.errorPopup("Dimensions can't be selected in multiple levels.");
    } else if (Name == 'isEntryPointExist' && Selection == 'columns') {
      this.errorPopup(
        'Entry Points and Metrics can not be selected in the same level.'
      );
    } else if (Name == 'isTimePeriodExist' && Selection == 'columns') {
      this.errorPopup(
        'Time Period and Metrics can not be selected in the same level.'
      );
    } else if (Name == 'isdimensionexist' && Selection == 'columns') {
      this.errorPopup(
        'Dimension and Metrics can not be selected in the same level.'
      );
    } else if (
      Name == 'isEntryPointTimePeriodExist' &&
      Selection == 'columns'
    ) {
      this.errorPopup(
        'Entry Points and Time Periods(Dimensions) can not be selected in same level.'
      );
    } else if (Name == 'singleLevel' && Selection == 'columns') {
      this.errorPopup("Time Periods can't be selected in multiple levels.");
    } else if (Name == 'createtable' && Selection == 'Rows/Columns') {
      this.errorPopup('Parent Metrics (Rows/Columns) can not be empty.');
    } else if (Name == 'MetricSelection' && Selection == 'Rows/Columns') {
      this.errorPopup(
        'At least one option must be selected in every metric added to any of the selection Panels.'
      );
    } else if (Name == 'entryselection' && Selection == 'column/columnNest') {
      this.errorPopup('Same Entry Points are not allowed in Multiple Levels.');
    } else if (
      Name == 'missingtimeperiod' &&
      Selection == 'Rows/Columns/Filters'
    ) {
      this.errorPopup('At least one Time Period needs to be selected.');
    } else if (Name == 'missingentry' && Selection == 'Rows/Columns/Filters') {
      this.errorPopup(
        'At least one option has to be selected from each Entry Point.'
      );
    } else if (
      Name == 'missingentryaggregated' &&
      Selection == 'Rows/Columns/Filters'
    ) {
      this.errorPopup(
        'All the variables present in left panel are mandatory and needs to be selected either in Column, Column Nesting or Filter.'
      );
    } else if (Name == 'entry' && Selection == 'entrypoints') {
      this.errorPopup('Select all the entry points.');
    } else if (Name == 'dimension' && Selection == 'output') {
      this.errorPopup(
        'At least one option has to selected from each Dimension.'
      );
    } else if (Name == 'customGrp') {
      this.errorPopup('Percentage Variable cannot be selected in ' + Selection + '');
    } else if (Name == 'customCalc') {
      this.errorPopup(
        'Mean Variable cannot be selected in ' + Selection + ''
      );
    }

    return false;
  }

  handleListCustomCalculationItemDrop(): void {
    const matchFound = this.entryList.some((entry) =>
      this.entryList.some(
        (listItem) => entry.Level1 === this.selectedVariableLevel0
      )
    );

    const ListB = this.listBItemsList.some((entry) =>
      this.listBItemsList.some((listItem) => entry.Level0 === 'Time Period')
    );

    this.listCustomCalculationItemsList.push({
      Level0: this.selectedVariableLevel0,
      Level1: this.selectedVariableLevel1,
      tooltip: this.is_tooltip,
      has_nextLevel: this.has_nextLevel,
      sortId: this.listASortID++,
      is_Derived: this.selectedVariable_IsDervied,
      is_calc_type: this.selectedVariable_IsCalc,
      data_variable_code: this.data_variable_code,
      data_response_code: this.data_response_code,
      data_sort_id: this.data_sort_id,
      data_is_multi_punch: this.data_is_multi_punch,
      data_date: this.data_date,
      data_year_code: this.data_year_code,
      is_dimension: this.is_dimension,
      is_custmGrpPresent: this.is_custmGrpPresent,
      is_custmCalcPresent: this.is_custmCalcPresent,
      kibanaFilterScript: this.kibanaFilterScript,
      customGroupId: this.customGroupId,
      customCalculationId: this.customCalculationId,
      dataYearCode: '',
      customGroupRowOrder: 0, //when dropped initially before selecting any option default value 0 is assigned
      customCalculationRowOrder: 0,
      isSelectAllMetrics: false,
      isSelectAllMetricsCount: 0,
      is_entrypoint: false
    });
    this.allValidationsPass = true;
    this.enableOrDisableProfiling();
    if (this.has_nextLevel == false) {
      let derivedValue = this.selectedVariable_IsDervied; // or 'false'
      let DerivedVal = derivedValue === 'true';

      let multipunch = this.data_is_multi_punch; // or 'false'
      let ismultipunch = multipunch === 'true';

      this.checkboxClick(
        'CustomCalculation',
        false,
        this.selectedVariableLevel0,
        this.selectedVariableLevel0 + '|' + this.selectedVariableLevel1,
        'true',
        this.selectedVariableLevel1,
        true,
        this.data_variable_code,
        this.data_response_code,
        DerivedVal,
        this.selectedVariable_IsCalc,
        Number(this.data_sort_id),
        ismultipunch,
        this.data_year_code,
        this.data_date,
        0,
        ismultipunch ? this.selectedVariableLevel0 : '',
        this.is_dimension,
        this.is_custmGrpPresent,
        this.kibanaFilterScript,
        this.customGroupId,
        this.dataYearCode,
        Number(this.data_is_multi_punch),
        true,
        this.customCalculationId,
        //this.customCalculationRowOrder,
        0,
        0
        // 0//custom Group row order
      );
    }
    // }
  }

  handleItemDrop(
    prevDestination: string,
    destination: string,
    currentDestination: string,
    item: {
      Level0: string;
      Level1: string;
      is_Derived: string;
      is_calc_type: string;
      data_variable_code: string;
      data_response_code: string;
      data_sort_id: string;
      data_is_multi_punch: string;
      data_date: string;
      data_year_code: string;
      is_dimension: boolean;
      is_custmGrpPresent: boolean;
      is_custmCalcPresent: boolean;
      kibanaFilterScript: string;
      customGroupId: string;
      customCalculationId: string;
      dataYearCode: string;
      has_nextLevel: boolean;
      isSelectAllMetrics: boolean;
      isSelectAllMetricsCount: number;
    }
  ): void {
    const nestingConfig = this.NestingConfig;
    this.allValidationsPass = false;

    if (
      prevDestination != currentDestination &&
      prevDestination != 'variableList'
    ) {
      this.selectedVariableLevel0 = item.Level0;
      this.selectedVariableLevel1 = item.Level1;
      this.selectedVariable_IsDervied = item.is_Derived;
      this.selectedVariable_IsCalc = item.is_calc_type;
      this.data_variable_code = item.data_variable_code;
      this.data_response_code = item.data_response_code;
      this.data_sort_id = item.data_sort_id;
      this.data_is_multi_punch = item.data_is_multi_punch;
      this.data_date = item.data_date;
      this.data_year_code = item.data_year_code;
      this.is_dimension = item.is_dimension;
      this.is_custmGrpPresent = item.is_custmGrpPresent;
      this.is_custmCalcPresent = item.is_custmCalcPresent;
      this.kibanaFilterScript = item.kibanaFilterScript;
      this.customGroupId = item.customGroupId;
      this.customCalculationId = item.customCalculationId;
      this.dataYearCode = item.dataYearCode;
      this.has_nextLevel = item.has_nextLevel;
      this.isSelectAllMetricsLevel = item.isSelectAllMetrics;
    }

    switch (destination) {
      case 'listCustomCalculationItemsList':
        this.handleListCustomCalculationItemDrop();
        break;
      case 'listCustomGroupItemsList':
        this.handleListCustomGroupItemDrop();
        break;
      case 'listAItemsList':
        this.handleListAItemDrop(prevDestination, this.is_entrypoint);
        break;
      case 'listBItemsList':
        this.handleListBItemDrop(nestingConfig, prevDestination, this.is_entrypoint);
        break;
      case 'listCItemsList':
        this.handleListCItemDrop(prevDestination, currentDestination, this.is_entrypoint);
        break;
      case 'listDItemsList':
        this.handleListDItemDrop(
          nestingConfig,
          prevDestination,
          currentDestination,
          this.is_entrypoint
        );
        break;
      case 'listEItemsList':
        this.handleListEItemDrop(prevDestination, currentDestination, this.is_entrypoint);
        break;
    }
    if (
      prevDestination != currentDestination &&
      prevDestination != 'variableList' &&
      this.allValidationsPass
    ) {
      this.removeItemsInArray(prevDestination, item);
      this.rearrangeSelectionObject(prevDestination, currentDestination, item);
    }

    this.enableOrDisableProfiling();
  }

  removeItemsInArray(
    panel: string,
    item: {
      Level0: string;
      Level1: string;
      is_Derived: string;
      is_calc_type: string;
      data_variable_code: string;
      dataYearCode: string;
      data_response_code: string;
      data_sort_id: string;
      data_is_multi_punch: string;
      data_date: string;
      data_year_code: string;
      has_nextLevel: boolean;
      is_custmGrpPresent: boolean;
      is_custmCalcPresent: boolean;
      customCalculationId: string;
      customGroupId: string;
      isSelectAllMetrics: boolean;
      isSelectAllMetricsCount: number;
    }
  ) {
    let listToUpdate;
    switch (panel) {
      case 'row':
        listToUpdate = this.listAItemsList;
        break;
      case 'rowNest':
        listToUpdate = this.listBItemsList;
        break;
      case 'col':
        listToUpdate = this.listCItemsList;
        break;
      case 'colNest':
        listToUpdate = this.listDItemsList;
        break;
      case 'filter':
        listToUpdate = this.listEItemsList;
        break;
      case 'customGroup':
        listToUpdate = this.listCustomGroupItemsList;
        break;
      default:
        return;
    }
    if (item.is_custmGrpPresent) {
      listToUpdate = listToUpdate.filter(
        (a) =>
          a.Level0 !== item.Level0 ||
          a.Level1 !== item.Level1 ||
          a.customGroupId !== item.customGroupId
      );
    } else {
      listToUpdate = listToUpdate.filter(
        (a) => a.Level0 !== item.Level0 || a.Level1 !== item.Level1
      );
    }
    switch (panel) {
      case 'row':
        this.listAItemsList = listToUpdate;
        break;
      case 'rowNest':
        this.listBItemsList = listToUpdate;
        break;
      case 'col':
        this.listCItemsList = listToUpdate;
        break;
      case 'colNest':
        this.listDItemsList = listToUpdate;
        break;
      case 'filter':
        this.listEItemsList = listToUpdate;
        break;
      case 'customGroup':
        this.listCustomGroupItemsList = listToUpdate;
        break;
      default:
        return;
      // Add cases for other destinations here...
    }
    this.selectedVariableLevel0 = item.Level0;
    this.selectedVariableLevel1 = item.Level1;
    this.selectedVariable_IsDervied = item.is_Derived;
    this.selectedVariable_IsCalc = item.is_calc_type;
    this.data_variable_code = item.data_variable_code;
    this.data_response_code = item.data_response_code;
    this.data_sort_id = item.data_sort_id;
    this.data_is_multi_punch = item.data_is_multi_punch;
    this.data_date = item.data_date;
    this.data_year_code = item.data_year_code;
    this.dataYearCode = item.dataYearCode;
    this.has_nextLevel = item.has_nextLevel;
  }

  handleListCItemDrop(
    prevDestination: string,
    currentDestination: string,
    entrypoint: boolean
  ): void {
    if (this.selectedDataFormat != 'Respondent Level') {
      if (this.selectedVariableLevel0.toLowerCase() == 'kpi') {
        this.errorPopup('KPI can be selected only in row.');
      } else if (this.listCItemsList.length < 1) {
        this.listCItemsList.push({
          Level0: this.selectedVariableLevel0,
          Level1: this.selectedVariableLevel1,
          tooltip: this.is_tooltip,
          has_nextLevel: this.has_nextLevel,
          sortId: this.listCSortID++,
          is_Derived: this.selectedVariable_IsDervied,
          is_calc_type: this.selectedVariable_IsCalc,
          data_variable_code: this.data_variable_code,
          data_response_code: this.data_response_code,
          data_sort_id: this.data_sort_id,
          data_is_multi_punch: this.data_is_multi_punch,
          data_date: this.data_date,
          data_year_code: this.data_year_code,
          is_dimension: this.is_dimension,
          is_custmGrpPresent: this.is_custmGrpPresent,
          is_custmCalcPresent: this.is_custmCalcPresent,
          kibanaFilterScript: this.kibanaFilterScript,
          customGroupId: this.customGroupId,
          customCalculationId: this.customCalculationId,
          dataYearCode: '',
          isSelectAllMetrics: false,
          isSelectAllMetricsCount: this.variableTabData.filter(
            (item: any) =>
              item.Level0 === this.selectedVariableLevel0 &&
              item.Level1 == this.selectedVariableLevel1
          ).length,
          is_entrypoint: entrypoint
        });
        this.allValidationsPass = true;
      } else {
        this.errorPopup('Only one metric can be selected in parent column.');
      }
    } else {
      if (
        this.selectedVariable_IsDervied === 'true' &&
        this.selectedVariable_IsCalc == 'mean'
      ) {
        this.getValidation('metrics', 'Columns/Column Nesting', '');
      } else if (this.is_custmCalcPresent === true) {
        this.getValidation('customCalc', 'Columns/Column Nesting', '');
      } else {
        const isEntryPointExist = this.entryList.some((entry) =>
          this.listCItemsList.some(
            (listItem) => entry.Level1 === listItem.Level0
          )
        );

        const ListDEntryMatch = this.entryList.some((entry) =>
          this.listDItemsList.some(
            (listItem) =>
              entry.Level1 === this.selectedVariableLevel0 &&
              listItem.Level0 == this.selectedVariableLevel0
          )
        );
        const ListEEntryMatch = this.entryList.some((entry) =>
          this.listEItemsList.some(
            (listItem) =>
              entry.Level1 === this.selectedVariableLevel0 &&
              listItem.Level0 == this.selectedVariableLevel0
          )
        );

        const isTimePeriodExist = this.listCItemsList.some((entry) =>
          this.listCItemsList.some((listItem) => entry.Level0 === 'Time Period')
        );
        const isDimensionExist = this.listCItemsList.some((entry) =>
          this.listCItemsList.some((listItem) => entry.is_dimension === true)
        );

        const entryMatch = this.entryList.some((entry) =>
          this.entryList.some(
            (listItem) => entry.Level1 === this.selectedVariableLevel0
          )
        );

        const ListD = this.listDItemsList.some((entry) =>
          this.listDItemsList.some((listItem) => entry.Level0 === 'Time Period')
        );
        const ListE = this.listEItemsList.some((entry) =>
          this.listEItemsList.some((listItem) => entry.Level0 === 'Time Period')
        );

        const isDimensionListD = this.listDItemsList.some((entry) =>
          this.listDItemsList.some((listItem) => entry.is_dimension === true)
        );
        const isDimensionListE = this.listEItemsList.some((entry) =>
          this.listEItemsList.some((listItem) => entry.is_dimension === true)
        );

        if (isEntryPointExist && this.selectedVariableLevel0 == 'Time Period') {
          this.getValidation('isEntryPointTimePeriodExist', 'columns', '');
        } else if (isEntryPointExist) {
          if (entryMatch) {
            this.getValidation('singleentrypoint', 'columns', '');
          } else {
            this.getValidation('isEntryPointExist', 'columns', '');
          }
        } else if (isTimePeriodExist) {
          if (this.selectedVariableLevel0 == 'Time Period') {
            this.listCItemsList.push({
              Level0: this.selectedVariableLevel0,
              Level1: this.selectedVariableLevel1,
              tooltip: this.is_tooltip,
              has_nextLevel: this.has_nextLevel,
              sortId: this.listCSortID++,
              is_Derived: this.selectedVariable_IsDervied,
              is_calc_type: this.selectedVariable_IsCalc,
              data_variable_code: this.data_variable_code,
              data_response_code: this.data_response_code,
              data_sort_id: this.data_sort_id,
              data_is_multi_punch: this.data_is_multi_punch,
              data_date: this.data_date,
              data_year_code: this.data_year_code,
              is_dimension: this.is_dimension,
              is_custmGrpPresent: this.is_custmGrpPresent,
              is_custmCalcPresent: this.is_custmCalcPresent,
              kibanaFilterScript: this.kibanaFilterScript,
              customGroupId: this.customGroupId,
              customCalculationId: this.customCalculationId,
              dataYearCode: '',
              isSelectAllMetrics: this.isSelectAllMetricsLevel,
              isSelectAllMetricsCount: this.variableTabData.filter(
                (item: any) =>
                  item.Level0 === this.selectedVariableLevel0 &&
                  item.Level1 == this.selectedVariableLevel1
              ).length,
              is_entrypoint: entrypoint
            });
            this.allValidationsPass = true;
          } else if (entryMatch) {
            this.getValidation('isEntryPointTimePeriodExist', 'columns', '');
          } else {
            this.getValidation('isTimePeriodExist', 'columns', '');
          }
        } else if (isDimensionExist) {
          if (this.is_dimension == true) {
            this.listCItemsList.push({
              Level0: this.selectedVariableLevel0,
              Level1: this.selectedVariableLevel1,
              tooltip: this.is_tooltip,
              has_nextLevel: this.has_nextLevel,
              sortId: this.listCSortID++,
              is_Derived: this.selectedVariable_IsDervied,
              is_calc_type: this.selectedVariable_IsCalc,
              data_variable_code: this.data_variable_code,
              data_response_code: this.data_response_code,
              data_sort_id: this.data_sort_id,
              data_is_multi_punch: this.data_is_multi_punch,
              data_date: this.data_date,
              data_year_code: this.data_year_code,
              is_dimension: this.is_dimension,
              is_custmGrpPresent: this.is_custmGrpPresent,
              is_custmCalcPresent: this.is_custmCalcPresent,
              kibanaFilterScript: this.kibanaFilterScript,
              customGroupId: this.customGroupId,
              customCalculationId: this.customCalculationId,
              dataYearCode: '',
              isSelectAllMetrics: this.isSelectAllMetricsLevel,
              isSelectAllMetricsCount: this.variableTabData.filter(
                (item: any) =>
                  item.Level0 === this.selectedVariableLevel0 &&
                  item.Level1 == this.selectedVariableLevel1
              ).length,
              is_entrypoint: entrypoint
            });
            this.allValidationsPass = true;
          } else {
            this.getValidation('isdimensionexist', 'columns', '');
          }
        } else if (
          (ListD || ListE) &&
          this.selectedVariableLevel0 == 'Time Period' &&
          prevDestination != currentDestination &&
          prevDestination == 'variableList'
        ) {
          this.getValidation('timeperiod', 'Columns/Column Nesting', '');
        } else if (
          (isDimensionListD || isDimensionListE) &&
          this.is_dimension == true &&
          prevDestination != currentDestination &&
          prevDestination == 'variableList'
        ) {
          this.getValidation('dimension', 'Columns/Column Nesting', '');
        } else if (
          (ListDEntryMatch || ListEEntryMatch) &&
          prevDestination != currentDestination &&
          prevDestination == 'variableList'
        ) {
          this.getValidation('entryselection', 'column/columnNest', '');
        } else {
          if (this.is_dimension == true && this.listCItemsList.length > 0) {
            this.getValidation('isdimensionexist', 'columns', '');
          } else if (
            this.selectedVariableLevel0 == 'Time Period' &&
            this.listCItemsList.length > 0
          ) {
            this.getValidation('isTimePeriodExist', 'columns', '');
          } else if (entryMatch && this.listCItemsList.length > 0) {
            this.getValidation('isEntryPointExist', 'columns', '');
          } else {
            this.listCItemsList.push({
              Level0: this.selectedVariableLevel0,
              Level1: this.selectedVariableLevel1,
              tooltip: this.is_tooltip,
              has_nextLevel: this.has_nextLevel,
              sortId: this.listCSortID++,
              is_Derived: this.selectedVariable_IsDervied,
              is_calc_type: this.selectedVariable_IsCalc,
              data_variable_code: this.data_variable_code,
              data_response_code: this.data_response_code,
              data_sort_id: this.data_sort_id,
              data_is_multi_punch: this.data_is_multi_punch,
              data_date: this.data_date,
              data_year_code: this.data_year_code,
              is_dimension: this.is_dimension,
              is_custmGrpPresent: this.is_custmGrpPresent,
              is_custmCalcPresent: this.is_custmCalcPresent,
              kibanaFilterScript: this.kibanaFilterScript,
              customGroupId: this.customGroupId,
              customCalculationId: this.customCalculationId,
              dataYearCode: '',
              isSelectAllMetrics: this.isSelectAllMetricsLevel,
              isSelectAllMetricsCount: this.variableTabData.filter(
                (item: any) =>
                  item.Level0 === this.selectedVariableLevel0 &&
                  item.Level1 == this.selectedVariableLevel1
              ).length,
              is_entrypoint: entrypoint
            });
            this.allValidationsPass = true;
            if (this.has_nextLevel == false) {
              let derivedValue = this.selectedVariable_IsDervied; // or 'false'
              let DerivedVal = derivedValue === 'true';

              let multipunch = this.data_is_multi_punch; // or 'false'
              let ismultipunch = multipunch === 'true';

              if (
                (this.selectedVariableLevel0 !== 'Custom Percentage Variable - User Defined' &&
                  this.selectedVariableLevel0 !==
                  'Custom Percentage Variable - Shared With Me' &&
                  this.selectedVariableLevel0 !==
                  'Custom Mean Variable - User Defined' &&
                  this.selectedVariableLevel0 !==
                  'Custom Mean Variable - Shared With Me') ||
                prevDestination === 'variableList'
              ) {
                this.checkboxClick(
                  'Column',
                  entrypoint,
                  this.selectedVariableLevel0,
                  this.selectedVariableLevel0 +
                  '|' +
                  this.selectedVariableLevel1,
                  'true',
                  this.selectedVariableLevel1,
                  true,
                  this.data_variable_code,
                  this.data_response_code,
                  DerivedVal,
                  this.selectedVariable_IsCalc,
                  Number(this.data_sort_id),
                  ismultipunch,
                  this.data_year_code,
                  this.data_date,
                  0,
                  ismultipunch ? this.selectedVariableLevel0 : '',
                  this.is_dimension,
                  this.is_custmGrpPresent,
                  this.kibanaFilterScript,
                  this.customGroupId,
                  this.dataYearCode,
                  Number(this.data_is_multi_punch),
                  false,//custom calculation can't be moved to columns
                  this.customCalculationId,
                  this.customCalculationRowOrder,
                  0
                );
              }
            } else if (
              this.has_nextLevel == true &&
              this.isSelectAllMetrics &&
              prevDestination == 'variableList'
            ) {
              this.Loader.showLoader();
              setTimeout(() => {
                const result = _.sortBy(this.variableTabData, 'sort_id');

                const filteredList = result.filter(
                  (item) =>
                    item.Level0 === this.selectedVariableLevel0 &&
                    item.Level1 == this.selectedVariableLevel1
                );

                for (const item of filteredList) {
                  const Level2 = filteredList.filter(
                    (item) =>
                      item.Level0 === item.Level0 &&
                      item.Level1 == item.Level1 &&
                      item.Level2 != ''
                  );
                  const Level3 = Level2.filter(
                    (item) =>
                      item.Level0 === item.Level0 &&
                      item.Level1 == item.Level1 &&
                      item.Level2 != '' &&
                      item.Level3 != ''
                  );
                  if (
                    Level2.length == 0 ||
                    (Level3.length == 0 && item.is_multi_punch)
                  ) {
                    this.checkboxClick(
                      'Column',
                      entrypoint,
                      item.Level0,
                      item.is_multi_punch
                        ? item.Level1 + '|' + item.Level2
                        : item.Level1 + '|' + item.MetricOption,
                      'true',
                      item.Level1,
                      true,
                      item.data_variable_code,
                      (item.calculation_type == 'RespGrp' && item.is_derived_variable ? item.derived_metric_calculation : item.response_code),
                      item.is_derived_variable,
                      item.calculation_type,
                      Number(item.sort_id),
                      false,
                      item.year_code,
                      item.date,
                      0,
                      '',
                      false,
                      false,
                      '',
                      item.customGroupId,
                      item.dataYearCode,
                      Number(this.data_is_multi_punch),
                      item.isCustomCalculation,
                      this.customCalculationId,
                      this.customCalculationRowOrder,
                      0
                    );
                  } else {
                    const Level4 = Level3.filter(
                      (item) =>
                        item.Level0 === item.Level0 &&
                        item.Level1 == item.Level1 &&
                        item.Level2 === item.Level2 &&
                        item.Level3 != '' &&
                        item.Level4 != ''
                    );

                    if (
                      Level3.length == 0 ||
                      (Level4.length == 0 && item.is_multi_punch)
                    ) {
                      this.checkboxClick(
                        'Column',
                        entrypoint,
                        item.Level0,
                        Level4.length == 0 && item.is_multi_punch
                          ? item.Level1 + '|' + item.Level2 + '|' + item.Level3
                          : item.MetricOption == ''
                            ? item.Level1 + '|' + item.Level2
                            : item.Level1 +
                            '|' +
                            item.Level2 +
                            '|' +
                            item.MetricOption,
                        'true',
                        item.Level1,
                        true,
                        item.data_variable_code,
                        (item.calculation_type == 'RespGrp' && item.is_derived_variable ? item.derived_metric_calculation : item.response_code),
                        item.is_derived_variable,
                        item.calculation_type,
                        Number(item.sort_id),
                        false,
                        item.year_code,
                        item.date,
                        0,
                        '',
                        false,
                        false,
                        '',
                        item.customGroupId,
                        item.dataYearCode,
                        Number(this.data_is_multi_punch),
                        item.isCustomCalculation,
                        this.customCalculationId,
                        this.customCalculationRowOrder,
                        0
                      );
                    } else {
                      const Level5 = Level4.filter(
                        (item) =>
                          item.Level0 === item.Level0 &&
                          item.Level1 == item.Level1 &&
                          item.Level2 === item.Level2 &&
                          item.Level3 != '' &&
                          item.Level4 != '' &&
                          item.Level5 != ''
                      );

                      if (
                        Level4.length == 0 ||
                        (Level5.length == 0 && item.is_multi_punch)
                      ) {
                        this.checkboxClick(
                          'Column',
                          entrypoint,
                          item.Level0,
                          item.is_multi_punch
                            ? item.Level1 +
                            '|' +
                            item.Level2 +
                            '|' +
                            item.Level3
                            : item.Level1 +
                            '|' +
                            item.Level2 +
                            '|' +
                            item.Level3 +
                            '|' +
                            item.MetricOption,
                          'true',
                          item.Level1,
                          true,
                          item.data_variable_code,
                          (item.calculation_type == 'RespGrp' && item.is_derived_variable ? item.derived_metric_calculation : item.response_code),
                          item.is_derived_variable,
                          item.calculation_type,
                          Number(item.sort_id),
                          false,
                          item.year_code,
                          item.date,
                          0,
                          '',
                          false,
                          false,
                          '',
                          item.customGroupId,
                          item.dataYearCode,
                          Number(this.data_is_multi_punch),
                          item.isCustomCalculation,
                          this.customCalculationId,
                          this.customCalculationRowOrder,
                          0
                        );
                      }
                    }
                  }
                }
                this.Loader.hideLoader();
              }, 0);
            }
          }
        }
      }
    }
  }
  handleListDItemDrop(
    nestingConfig: number,
    prevDestination: string,
    currentDestination: string,
    entrypoint: boolean
  ): void {
    if (this.selectedDataFormat != 'Respondent Level') {
      if (this.selectedVariableLevel0.toLowerCase() == 'kpi') {
        this.errorPopup('KPI can be selected only in row.');
      } else if (this.listDItemsList.length < this.NestingConfig) {
        this.listDItemsList.push({
          Level0: this.selectedVariableLevel0,
          Level1: this.selectedVariableLevel1,
          tooltip: this.is_tooltip,
          has_nextLevel: this.has_nextLevel,
          sortId: this.listDSortID++,
          is_Derived: this.selectedVariable_IsDervied,
          is_calc_type: this.selectedVariable_IsCalc,
          data_variable_code: this.data_variable_code,
          data_response_code: this.data_response_code,
          data_sort_id: this.data_sort_id,
          data_is_multi_punch: this.data_is_multi_punch,
          data_date: this.data_date,
          data_year_code: this.data_year_code,
          is_dimension: this.is_dimension,
          is_custmGrpPresent: this.is_custmGrpPresent,
          is_custmCalcPresent: this.is_custmCalcPresent,
          kibanaFilterScript: this.kibanaFilterScript,
          customGroupId: this.customGroupId,
          customCalculationId: this.customCalculationId,
          dataYearCode: '',
          isSelectAllMetrics: false,
          isSelectAllMetricsCount: this.variableTabData.filter(
            (item: any) =>
              item.Level0 === this.selectedVariableLevel0 &&
              item.Level1 == this.selectedVariableLevel1
          ).length,
          is_entrypoint: entrypoint
        });
        this.allValidationsPass = true;
      } else {
        this.getValidation('max_nesting', 'Columns', '');
      }
    } else {
      const ListCEntryMatch = this.entryList.some((entry) =>
        this.listCItemsList.some(
          (listItem) =>
            entry.Level1 === this.selectedVariableLevel0 &&
            listItem.Level0 == this.selectedVariableLevel0
        )
      );
      const ListEEntryMatch = this.entryList.some((entry) =>
        this.listEItemsList.some(
          (listItem) =>
            entry.Level1 === this.selectedVariableLevel0 &&
            listItem.Level0 == this.selectedVariableLevel0
        )
      );

      const ListC = this.listCItemsList.some((entry) =>
        this.listCItemsList.some((listItem) => entry.Level0 === 'Time Period')
      );

      const ListD = this.listDItemsList.some((entry) =>
        this.listDItemsList.some((listItem) => entry.Level0 === 'Time Period')
      );

      const ListE = this.listEItemsList.some((entry) =>
        this.listEItemsList.some((listItem) => entry.Level0 === 'Time Period')
      );

      const isDimensionListC = this.listCItemsList.some((entry) =>
        this.listCItemsList.some((listItem) => entry.is_dimension === true)
      );
      const isDimensionListE = this.listEItemsList.some((entry) =>
        this.listEItemsList.some((listItem) => entry.is_dimension === true)
      );

      const filteredCList = this.listCItemsList.filter(
        (item) => item.Level0 === 'Time Period'
      );
      const ListCLength = filteredCList.length;

      const filteredEList = this.listEItemsList.filter(
        (item) => item.Level0 === 'Time Period'
      );
      const ListELength = filteredEList.length;
      if (this.is_custmGrpPresent === true) {
        this.getValidation('customGrp', 'Column Nesting', '');
      } else if (this.is_custmCalcPresent === true) {
        this.getValidation('customCalc', 'Column Nesting', '');
      } else if (
        (ListE || ListC) &&
        this.selectedVariableLevel0 == 'Time Period' &&
        prevDestination != currentDestination &&
        prevDestination == 'variableList'
      ) {
        this.getValidation('timeperiod', 'Columns/Column Nesting', '');
      } else if (
        (isDimensionListC || isDimensionListE) &&
        this.is_dimension == true &&
        prevDestination != currentDestination &&
        prevDestination == 'variableList'
      ) {
        this.getValidation('dimension', 'Columns/Column Nesting', '');
      } else if (
        (ListE || ListC) &&
        this.selectedVariableLevel0 == 'Time Period' &&
        prevDestination != currentDestination &&
        prevDestination != 'variableList' &&
        (ListCLength > 1 || ListELength > 1)
      ) {
        this.getValidation('timeperiod', 'Columns/Column Nesting', '');
      } else if (ListD && this.selectedVariableLevel0 == 'Time Period') {
        this.getValidation('singleLevel', 'columns', '');
      } else if (
        (ListCEntryMatch || ListEEntryMatch) &&
        prevDestination != currentDestination &&
        prevDestination == 'variableList'
      ) {
        this.getValidation('entryselection', 'column/columnNest', '');
      } else if (this.listDItemsList.length < nestingConfig) {
        this.handleListDItemDropInner(prevDestination, entrypoint);
      } else {
        this.getValidation('max_nesting', 'Columns', '');
      }
    }
  }

  handleListDItemDropInner(prevDestination: string, entrypoint: boolean): void {
    if (
      this.selectedVariable_IsDervied === 'true' &&
      this.selectedVariable_IsCalc == 'mean'
    ) {
      this.getValidation('metrics', 'Columns/Column Nesting', '');
    } else {
      this.listDItemsList.push({
        Level0: this.selectedVariableLevel0,
        Level1: this.selectedVariableLevel1,
        tooltip: this.is_tooltip,
        has_nextLevel: this.has_nextLevel,
        sortId: this.listDSortID++,
        is_Derived: this.selectedVariable_IsDervied,
        is_calc_type: this.selectedVariable_IsCalc,
        data_variable_code: this.data_variable_code,
        data_response_code: this.data_response_code,
        data_sort_id: this.data_sort_id,
        data_is_multi_punch: this.data_is_multi_punch,
        data_date: this.data_date,
        data_year_code: this.data_year_code,
        is_dimension: this.is_dimension,
        is_custmGrpPresent: this.is_custmGrpPresent,
        is_custmCalcPresent: this.is_custmCalcPresent,
        kibanaFilterScript: this.kibanaFilterScript,
        customGroupId: this.customGroupId,
        customCalculationId: this.customCalculationId,
        dataYearCode: '',
        isSelectAllMetrics: this.isSelectAllMetricsLevel,
        isSelectAllMetricsCount: this.variableTabData.filter(
          (item: any) =>
            item.Level0 === this.selectedVariableLevel0 &&
            item.Level1 == this.selectedVariableLevel1
        ).length,
        is_entrypoint: entrypoint
      });
      this.allValidationsPass = true;

      if (
        this.has_nextLevel == true &&
        this.isSelectAllMetrics &&
        prevDestination == 'variableList'
      ) {
        this.Loader.showLoader();
        setTimeout(() => {
          const result = _.sortBy(this.variableTabData, 'sort_id');

          const filteredList = result.filter(
            (item) =>
              item.Level0 === this.selectedVariableLevel0 &&
              item.Level1 == this.selectedVariableLevel1
          );

          for (const item of filteredList) {
            const Level2 = filteredList.filter(
              (item) =>
                item.Level0 === item.Level0 &&
                item.Level1 == item.Level1 &&
                item.Level2 != ''
            );
            const Level3 = Level2.filter(
              (item) =>
                item.Level0 === item.Level0 &&
                item.Level1 == item.Level1 &&
                item.Level2 != '' &&
                item.Level3 != ''
            );

            if (
              Level2.length == 0 ||
              (Level3.length == 0 && item.is_multi_punch)
            ) {
              this.checkboxClick(
                'ColNesting',
                entrypoint,
                item.Level0,
                item.is_multi_punch
                  ? item.Level1 + '|' + item.Level2
                  : item.Level1 + '|' + item.MetricOption,
                'true',
                item.Level1,
                true,
                item.data_variable_code,
                (item.calculation_type == 'RespGrp' && item.is_derived_variable ? item.derived_metric_calculation : item.response_code),
                item.is_derived_variable,
                item.calculation_type,
                Number(item.sort_id),
                false,
                item.year_code,
                item.date,
                0,
                '',
                false,
                false,
                '',
                item.customGroupId,
                item.dataYearCode,
                Number(this.data_is_multi_punch),
                item.isCustomCalculation,
                this.customCalculationId,
                this.customCalculationRowOrder,
                0
              );
            } else {
              const Level4 = Level3.filter(
                (item) =>
                  item.Level0 === item.Level0 &&
                  item.Level1 == item.Level1 &&
                  item.Level2 === item.Level2 &&
                  item.Level3 != '' &&
                  item.Level4 != ''
              );

              if (
                Level3.length == 0 ||
                (Level4.length == 0 && item.is_multi_punch)
              ) {
                this.checkboxClick(
                  'ColNesting',
                  entrypoint,
                  item.Level0,
                  Level4.length == 0 && item.is_multi_punch
                    ? item.Level1 + '|' + item.Level2 + '|' + item.Level3
                    : item.MetricOption == ''
                      ? item.Level1 + '|' + item.Level2
                      : item.Level1 + '|' + item.Level2 + '|' + item.MetricOption,
                  'true',
                  item.Level1,
                  true,
                  item.data_variable_code,
                  (item.calculation_type == 'RespGrp' && item.is_derived_variable ? item.derived_metric_calculation : item.response_code),
                  item.is_derived_variable,
                  item.calculation_type,
                  Number(item.sort_id),
                  false,
                  item.year_code,
                  item.date,
                  0,
                  '',
                  false,
                  false,
                  '',
                  item.customGroupId,
                  item.dataYearCode,
                  Number(this.data_is_multi_punch),
                  item.isCustomCalculation,
                  this.customCalculationId,
                  this.customCalculationRowOrder,
                  0
                );
              } else {
                const Level5 = Level4.filter(
                  (item) =>
                    item.Level0 === item.Level0 &&
                    item.Level1 == item.Level1 &&
                    item.Level2 === item.Level2 &&
                    item.Level3 != '' &&
                    item.Level4 != '' &&
                    item.Level5 != ''
                );

                if (
                  Level4.length == 0 ||
                  (Level5.length == 0 && item.is_multi_punch)
                ) {
                  this.checkboxClick(
                    'ColNesting',
                    entrypoint,
                    item.Level0,
                    item.is_multi_punch
                      ? item.Level1 + '|' + item.Level2 + '|' + item.Level3
                      : item.Level1 +
                      '|' +
                      item.Level2 +
                      '|' +
                      item.Level3 +
                      '|' +
                      item.MetricOption,
                    'true',
                    item.Level1,
                    true,
                    item.data_variable_code,
                    (item.calculation_type == 'RespGrp' && item.is_derived_variable ? item.derived_metric_calculation : item.response_code),
                    item.is_derived_variable,
                    item.calculation_type,
                    Number(item.sort_id),
                    false,
                    item.year_code,
                    item.date,
                    0,
                    '',
                    false,
                    false,
                    '',
                    item.customGroupId,
                    item.dataYearCode,
                    Number(this.data_is_multi_punch),
                    item.isCustomCalculation,
                    this.customCalculationId,
                    this.customCalculationRowOrder,
                    0
                  );
                }
              }
            }
          }
          this.Loader.hideLoader();
        }, 0);
      }
    }
  }

  handleListEItemDrop(
    prevDestination: string,
    currentDestination: string,
    entryPoint: boolean
  ): void {
    if (this.selectedDataFormat != 'Respondent Level') {
      if (this.selectedVariableLevel0.toLowerCase() == 'kpi') {
        this.errorPopup('KPI can be selected only in row.');
      } else {
        this.listEItemsList.push({
          Level0: this.selectedVariableLevel0,
          Level1: this.selectedVariableLevel1,
          tooltip: this.is_tooltip,
          has_nextLevel: this.has_nextLevel,
          sortId: this.listESortID++,
          is_Derived: this.selectedVariable_IsDervied,
          is_calc_type: this.selectedVariable_IsCalc,
          data_variable_code: this.data_variable_code,
          data_response_code: this.data_response_code,
          data_sort_id: this.data_sort_id,
          data_is_multi_punch: this.data_is_multi_punch,
          data_date: this.data_date,
          data_year_code: this.data_year_code,
          is_dimension: this.is_dimension,
          is_custmGrpPresent: this.is_custmGrpPresent,
          is_custmCalcPresent: this.is_custmCalcPresent,
          kibanaFilterScript: this.kibanaFilterScript,
          customGroupId: this.customGroupId,
          customCalculationId: this.customCalculationId,
          dataYearCode: '',
          isSelectAllMetrics: false,
          is_entrypoint: entryPoint,
          isSelectAllMetricsCount: this.variableTabData.filter(
            (item: any) =>
              item.Level0 === this.selectedVariableLevel0 &&
              item.Level1 == this.selectedVariableLevel1
          ).length,
        });
        this.allValidationsPass = true;
      }
    } else {
      if (
        this.selectedVariable_IsDervied === 'true' &&
        this.selectedVariable_IsCalc == 'mean'
      ) {
        this.getValidation('metrics', 'Filters.', '');
      } else if (this.is_custmCalcPresent === true) {
        this.getValidation('customCalc', 'Filters.', '');
      } else {
        const ListCEntryMatch = this.entryList.some((entry) =>
          this.listCItemsList.some(
            (listItem) =>
              entry.Level1 === this.selectedVariableLevel0 &&
              listItem.Level0 == this.selectedVariableLevel0
          )
        );
        const ListDEntryMatch = this.entryList.some((entry) =>
          this.listDItemsList.some(
            (listItem) =>
              entry.Level1 === this.selectedVariableLevel0 &&
              listItem.Level0 == this.selectedVariableLevel0
          )
        );

        const matchFound = this.listEItemsList.some((entry) =>
          this.listEItemsList.some(
            (listItem) => entry.Level0 === this.selectedVariableLevel0
          )
        );
        const ListC = this.listCItemsList.some((entry) =>
          this.listCItemsList.some((listItem) => entry.Level0 === 'Time Period')
        );
        const ListD = this.listDItemsList.some((entry) =>
          this.listDItemsList.some((listItem) => entry.Level0 === 'Time Period')
        );

        const filteredCList = this.listCItemsList.filter(
          (item) => item.Level0 === 'Time Period'
        );
        const ListCLength = filteredCList.length;

        const filteredDList = this.listDItemsList.filter(
          (item) => item.Level0 === 'Time Period'
        );
        const ListDLength = filteredDList.length;

        const isDimensionListD = this.listDItemsList.some((entry) =>
          this.listDItemsList.some((listItem) => entry.is_dimension === true)
        );
        const isDimensionListC = this.listCItemsList.some((entry) =>
          this.listCItemsList.some((listItem) => entry.is_dimension === true)
        );

        if (matchFound && this.selectedVariableLevel0 == 'Time Period') {
          this.getValidation('timeperiod', 'Filters.', '');
        } else if (
          (ListC || ListD) &&
          this.selectedVariableLevel0 == 'Time Period' &&
          prevDestination != currentDestination &&
          prevDestination == 'variableList'
        ) {
          this.getValidation('timeperiod', 'Columns/Column Nesting', '');
        } else if (
          (isDimensionListD || isDimensionListC) &&
          this.is_dimension == true &&
          prevDestination != currentDestination &&
          prevDestination == 'variableList'
        ) {
          this.getValidation('dimension', 'Columns/Column Nesting', '');
        } else if (
          (ListD || ListC) &&
          this.selectedVariableLevel0 == 'Time Period' &&
          prevDestination != currentDestination &&
          prevDestination != 'variableList' &&
          (ListCLength > 1 || ListDLength > 1)
        ) {
          this.getValidation('timeperiod', 'Columns/Column Nesting', '');
        }
        //else if (ListD && this.selectedVariableLevel0 == 'Time Period') {
        //  this.getValidation('timeperiod', 'Columns/Column Nesting', '');
        //}
        else if (
          (ListCEntryMatch || ListDEntryMatch) &&
          prevDestination != currentDestination &&
          prevDestination == 'variableList'
        ) {
          this.getValidation('entryselection', 'column/columnNest', '');
        } else {
          this.listEItemsList.push({
            Level0: this.selectedVariableLevel0,
            Level1: this.selectedVariableLevel1,
            tooltip: this.is_tooltip,
            has_nextLevel: this.has_nextLevel,
            sortId: this.listESortID++,
            is_Derived: this.selectedVariable_IsDervied,
            is_calc_type: this.selectedVariable_IsCalc,
            data_variable_code: this.data_variable_code,
            data_response_code: this.data_response_code,
            data_sort_id: this.data_sort_id,
            data_is_multi_punch: this.data_is_multi_punch,
            data_date: this.data_date,
            data_year_code: this.data_year_code,
            is_dimension: this.is_dimension,
            is_custmGrpPresent: this.is_custmGrpPresent,
            is_custmCalcPresent: this.is_custmCalcPresent,
            kibanaFilterScript: this.kibanaFilterScript,
            customGroupId: this.customGroupId,
            customCalculationId: this.customCalculationId,
            dataYearCode: '',
            is_entrypoint: entryPoint,
            isSelectAllMetrics: this.isSelectAllMetricsLevel,
            isSelectAllMetricsCount: this.variableTabData.filter(
              (item: any) =>
                item.Level0 === this.selectedVariableLevel0 &&
                item.Level1 == this.selectedVariableLevel1
            ).length,
          });
          this.allValidationsPass = true;
        }
        if (this.has_nextLevel == false) {
          let derivedValue = this.selectedVariable_IsDervied; // or 'false'
          let DerivedVal = derivedValue === 'true';

          let multipunch = this.data_is_multi_punch; // or 'false'
          let ismultipunch = multipunch === 'true';

          if (
            (this.selectedVariableLevel0 !== 'Custom Percentage Variable - User Defined' &&
              this.selectedVariableLevel0 !== 'Custom Percentage Variable - Shared With Me' &&
              this.selectedVariableLevel0 !==
              'Custom Mean Variable - User Defined' &&
              this.selectedVariableLevel0 !==
              'Custom Mean Variable - Shared With Me') ||
            prevDestination === 'variableList'
          ) {
            this.checkboxClick(
              'Filter',
              entryPoint,
              this.selectedVariableLevel0,
              this.selectedVariableLevel0 + '|' + this.selectedVariableLevel1,
              'true',
              this.selectedVariableLevel1,
              true,
              this.data_variable_code,
              this.data_response_code,
              DerivedVal,
              this.selectedVariable_IsCalc,
              Number(this.data_sort_id),
              ismultipunch,
              this.data_year_code,
              this.data_date,
              0,
              ismultipunch ? this.selectedVariableLevel0 : '',
              this.is_dimension,
              this.is_custmGrpPresent,
              this.kibanaFilterScript,
              this.customGroupId,
              this.dataYearCode,
              Number(this.data_is_multi_punch),
              false,//custom calculation cant be moved to filters
              this.customCalculationId,
              this.customCalculationRowOrder,
              0
            );
          }
        }
      }
    }
  }

  handleListAItemDrop(prevDestination: string, entrypoint: boolean): void {
    if (this.selectedDataFormat != 'Respondent Level') {
      if (this.selectedVariableLevel0.toLowerCase() != 'kpi') {
        this.errorPopup('Only KPI can be selected in row.');
      } else {
        this.listAItemsList.push({
          Level0: this.selectedVariableLevel0,
          Level1: this.selectedVariableLevel1,
          tooltip: this.is_tooltip,
          has_nextLevel: this.has_nextLevel,
          sortId: this.listASortID++,
          is_Derived: this.selectedVariable_IsDervied,
          is_calc_type: this.selectedVariable_IsCalc,
          data_variable_code: this.data_variable_code,
          data_response_code: this.data_response_code,
          data_sort_id: this.data_sort_id,
          data_is_multi_punch: this.data_is_multi_punch,
          data_date: this.data_date,
          data_year_code: this.data_year_code,
          is_dimension: this.is_dimension,
          is_custmGrpPresent: this.is_custmGrpPresent,
          is_custmCalcPresent: this.is_custmCalcPresent,
          kibanaFilterScript: this.kibanaFilterScript,
          customGroupId: this.customGroupId,
          customCalculationId: this.customCalculationId,
          dataYearCode: '',
          isSelectAllMetrics: this.isSelectAllMetricsLevel,
          isSelectAllMetricsCount: this.variableTabData.filter(
            (item: any) =>
              item.Level0 === this.selectedVariableLevel0 &&
              item.Level1 == this.selectedVariableLevel1
          ).length,
          is_entrypoint: entrypoint
        });
        this.allValidationsPass = true;
      }
    } else {
      const matchFound = this.entryList.some((entry) =>
        this.entryList.some(
          (listItem) => entry.Level1 === this.selectedVariableLevel0
        )
      );

      const ListB = this.listBItemsList.some((entry) =>
        this.listBItemsList.some((listItem) => entry.Level0 === 'Time Period')
      );
      if (matchFound) {
        this.getValidation('entrypoints', 'Rows', '');
      } else if (ListB && this.selectedVariableLevel0 == 'Time Period') {
        this.getValidation('timeperiod', 'Columns/Column Nesting', '');
      } else if (this.selectedVariableLevel0 == 'Time Period') {
        this.getValidation('timeperiod', 'Rows', '');
      } else if (this.is_dimension == true) {
        this.getValidation('dimensions', 'Rows', '');
      } else {
        this.listAItemsList.push({
          Level0: this.selectedVariableLevel0,
          Level1: this.selectedVariableLevel1,
          tooltip: this.is_tooltip,
          has_nextLevel: this.has_nextLevel,
          sortId: this.listASortID++,
          is_Derived: this.selectedVariable_IsDervied,
          is_calc_type: this.selectedVariable_IsCalc,
          data_variable_code: this.data_variable_code,
          data_response_code: this.data_response_code,
          data_sort_id: this.data_sort_id,
          data_is_multi_punch: this.data_is_multi_punch,
          data_date: this.data_date,
          data_year_code: this.data_year_code,
          is_dimension: this.is_dimension,
          is_custmGrpPresent: this.is_custmGrpPresent,
          is_custmCalcPresent: this.is_custmCalcPresent,
          kibanaFilterScript: this.kibanaFilterScript,
          customGroupId: this.customGroupId,
          customCalculationId: this.customCalculationId,
          dataYearCode: '',
          isSelectAllMetrics: this.isSelectAllMetricsLevel,
          isSelectAllMetricsCount: this.variableTabData.filter(
            (item: any) =>
              item.Level0 === this.selectedVariableLevel0 &&
              item.Level1 == this.selectedVariableLevel1
          ).length,
          is_entrypoint: entrypoint
        });
        this.allValidationsPass = true;
        this.enableOrDisableProfiling();
        if (this.has_nextLevel == false) {
          let derivedValue = this.selectedVariable_IsDervied; // or 'false'
          let DerivedVal = derivedValue === 'true';

          let multipunch = this.data_is_multi_punch; // or 'false'
          let ismultipunch = this.GetMultiPunchValue(multipunch);

          if (
            (this.selectedVariableLevel0 !== 'Custom Percentage Variable - User Defined' &&
              this.selectedVariableLevel0 !== 'Custom Percentage Variable - Shared With Me' &&
              this.selectedVariableLevel0 !==
              'Custom Mean Variable - User Defined' &&
              this.selectedVariableLevel0 !==
              'Custom Mean Variable - Shared With Me') ||
            prevDestination === 'variableList'
          ) {
            this.checkboxClick(
              'Row',
              entrypoint,
              this.selectedVariableLevel0,
              this.selectedVariableLevel0 + '|' + this.selectedVariableLevel1,
              'true',
              this.selectedVariableLevel1,
              true,
              this.data_variable_code,
              this.data_response_code,
              DerivedVal,
              this.selectedVariable_IsCalc,
              Number(this.data_sort_id),
              ismultipunch,
              this.data_year_code,
              this.data_date,
              0,
              ismultipunch ? this.selectedVariableLevel0 : '',
              this.is_dimension,
              this.is_custmGrpPresent,
              this.kibanaFilterScript,
              this.customGroupId,
              this.dataYearCode,
              Number(this.data_is_multi_punch),
              this.is_custmCalcPresent,
              this.customCalculationId,
              this.customCalculationRowOrder,
              0
            );
          }
        } else if (
          this.has_nextLevel == true &&
          this.isSelectAllMetrics &&
          prevDestination == 'variableList'
        ) {
          this.Loader.showLoader();
          setTimeout(() => {
            const result = _.sortBy(this.variableTabData, 'sort_id');

            const filteredList = result.filter(
              (item) =>
                item.Level0 === this.selectedVariableLevel0 &&
                item.Level1 == this.selectedVariableLevel1
            );

            for (const item of filteredList) {
              const Level2 = filteredList.filter(
                (item) =>
                  item.Level0 === item.Level0 &&
                  item.Level1 == item.Level1 &&
                  item.Level2 != ''
              );
              const Level3 = Level2.filter(
                (item) =>
                  item.Level0 === item.Level0 &&
                  item.Level1 == item.Level1 &&
                  item.Level2 != '' &&
                  item.Level3 != ''
              );
              if (
                Level2.length == 0 ||
                (Level3.length == 0 && item.is_multi_punch)
              ) {
                this.checkboxClick(
                  'Row',
                  entrypoint,
                  item.Level0,
                  item.is_multi_punch
                    ? item.Level1 + '|' + item.Level2
                    : item.Level1 + '|' + item.MetricOption,
                  'true',
                  item.Level1,
                  true,
                  item.data_variable_code,
                  (item.calculation_type == 'RespGrp' && item.is_derived_variable ? item.derived_metric_calculation : item.response_code),
                  item.is_derived_variable,
                  item.calculation_type,
                  Number(item.sort_id),
                  false,
                  item.year_code,
                  item.date,
                  0,
                  '',
                  false,
                  false,
                  '',
                  item.customGroupId,
                  item.dataYearCode,
                  Number(this.data_is_multi_punch),
                  item.isCustomCalculation,
                  this.customCalculationId,
                  this.customCalculationRowOrder,
                  0
                );
              } else {
                const Level4 = Level3.filter(
                  (item) =>
                    item.Level0 === item.Level0 &&
                    item.Level1 == item.Level1 &&
                    item.Level2 === item.Level2 &&
                    item.Level3 != '' &&
                    item.Level4 != ''
                );
                if (
                  Level3.length == 0 ||
                  (Level4.length == 0 && item.is_multi_punch)
                ) {
                  this.checkboxClick(
                    'Row',
                    entrypoint,
                    item.Level0,
                    Level4.length == 0 && item.is_multi_punch
                      ? item.Level1 + '|' + item.Level2 + '|' + item.Level3
                      : item.MetricOption == ''
                        ? item.Level1 + '|' + item.Level2
                        : item.Level1 +
                        '|' +
                        item.Level2 +
                        '|' +
                        item.MetricOption,
                    'true',
                    item.Level1,
                    true,
                    item.data_variable_code,
                    (item.calculation_type == 'RespGrp' && item.is_derived_variable ? item.derived_metric_calculation : item.response_code),
                    item.is_derived_variable,
                    item.calculation_type,
                    Number(item.sort_id),
                    false,
                    item.year_code,
                    item.date,
                    0,
                    '',
                    false,
                    false,
                    '',
                    item.customGroupId,
                    item.dataYearCode,
                    Number(this.data_is_multi_punch),
                    item.isCustomCalculation,
                    this.customCalculationId,
                    this.customCalculationRowOrder,
                    0
                  );
                } else {
                  const Level5 = Level4.filter(
                    (item) =>
                      item.Level0 === item.Level0 &&
                      item.Level1 == item.Level1 &&
                      item.Level2 === item.Level2 &&
                      item.Level3 != '' &&
                      item.Level4 != '' &&
                      item.Level5 != ''
                  );

                  if (
                    Level4.length == 0 ||
                    (Level5.length == 0 && item.is_multi_punch)
                  ) {
                    this.checkboxClick(
                      'Row',
                      entrypoint,
                      item.Level0,
                      item.is_multi_punch
                        ? item.Level1 + '|' + item.Level2 + '|' + item.Level3
                        : item.Level1 +
                        '|' +
                        item.Level2 +
                        '|' +
                        item.Level3 +
                        '|' +
                        item.MetricOption,
                      'true',
                      item.Level1,
                      true,
                      item.data_variable_code,
                      (item.calculation_type == 'RespGrp' && item.is_derived_variable ? item.derived_metric_calculation : item.response_code),
                      item.is_derived_variable,
                      item.calculation_type,
                      Number(item.sort_id),
                      false,
                      item.year_code,
                      item.date,
                      0,
                      '',
                      false,
                      false,
                      '',
                      item.customGroupId,
                      item.dataYearCode,
                      Number(this.data_is_multi_punch),
                      item.isCustomCalculation,
                      this.customCalculationId,
                      this.customCalculationRowOrder,
                      0
                    );
                  }
                }
              }
            }
            this.Loader.hideLoader();
          }, 0);
        }
      }
    }
  }

  GetMultiPunchValue(value: any) {
    return value.toString() == Status.None.toString() ? false : true;
  }

  handleListBItemDrop(nestingConfig: number, prevDestination: string, entrypoint: boolean): void {
    const matchFound = this.entryList.some((entry) =>
      this.entryList.some(
        (listItem) => entry.Level1 === this.selectedVariableLevel0
      )
    );
    const ListA = this.listAItemsList.some((entry) =>
      this.listAItemsList.some((listItem) => entry.Level0 === 'Time Period')
    );
    if (
      this.selectedVariable_IsDervied === 'true' &&
      this.selectedVariable_IsCalc == 'mean'
    ) {
      this.getValidation('metrics', 'Row Nesting', '');
    } else if (this.is_custmGrpPresent === true) {
      this.getValidation('customGrp', 'Row Nesting', '');
    } else if (this.is_custmCalcPresent === true) {
      this.getValidation('customCalc', 'Row Nesting', '');
    } else {
      if (matchFound) {
        this.getValidation('entrypoints', 'Rows', '');
      } else if (ListA && this.selectedVariableLevel0 == 'Time Period') {
        this.getValidation('timeperiod', 'Columns/Column Nesting', '');
      } else if (this.selectedVariableLevel0 == 'Time Period') {
        this.getValidation('timeperiod', 'Rows', '');
      } else if (this.is_dimension == true) {
        this.getValidation('dimensions', 'Rows', '');
      } else if (this.listBItemsList.length < nestingConfig) {
        this.listBItemsList.push({
          Level0: this.selectedVariableLevel0,
          Level1: this.selectedVariableLevel1,
          tooltip: this.is_tooltip,
          has_nextLevel: this.has_nextLevel,
          sortId: this.listBSortID++,
          is_Derived: this.selectedVariable_IsDervied,
          is_calc_type: this.selectedVariable_IsCalc,
          data_variable_code: this.data_variable_code,
          data_response_code: this.data_response_code,
          data_sort_id: this.data_sort_id,
          data_is_multi_punch: this.data_is_multi_punch,
          data_date: this.data_date,
          data_year_code: this.data_year_code,
          is_dimension: this.is_dimension,
          is_custmGrpPresent: this.is_custmGrpPresent,
          is_custmCalcPresent: this.is_custmCalcPresent,
          kibanaFilterScript: this.kibanaFilterScript,
          customGroupId: this.customGroupId,
          customCalculationId: this.customCalculationId,
          dataYearCode: '',
          isSelectAllMetrics: this.isSelectAllMetricsLevel,
          isSelectAllMetricsCount: this.variableTabData.filter(
            (item: any) =>
              item.Level0 === this.selectedVariableLevel0 &&
              item.Level1 == this.selectedVariableLevel1
          ).length,
          is_entrypoint: entrypoint
        });
        if (
          this.has_nextLevel == true &&
          this.isSelectAllMetrics &&
          prevDestination == 'variableList'
        ) {
          this.Loader.showLoader();
          setTimeout(() => {
            const result = _.sortBy(this.variableTabData, 'sort_id');

            const filteredList = result.filter(
              (item) =>
                item.Level0 === this.selectedVariableLevel0 &&
                item.Level1 == this.selectedVariableLevel1
            );

            for (const item of filteredList) {
              const Level2 = filteredList.filter(
                (item) =>
                  item.Level0 === item.Level0 &&
                  item.Level1 == item.Level1 &&
                  item.Level2 != ''
              );
              const Level3 = Level2.filter(
                (item) =>
                  item.Level0 === item.Level0 &&
                  item.Level1 == item.Level1 &&
                  item.Level2 != '' &&
                  item.Level3 != ''
              );
              if (
                Level2.length == 0 ||
                (Level3.length == 0 && item.is_multi_punch)
              ) {
                //const itemList = this.listBItemsList.filter(x => x.Level0 == this.selectedVariableLevel0);
                //for (const count of itemList) {
                //  count.isSelectAllMetricsCount = filteredList.length;
                //}

                this.checkboxClick(
                  'RowNesting',
                  entrypoint,
                  item.Level0,
                  item.is_multi_punch
                    ? item.Level1 + '|' + item.Level2
                    : item.Level1 + '|' + item.MetricOption,
                  'true',
                  item.Level1,
                  true,
                  item.data_variable_code,
                  (item.calculation_type == 'RespGrp' && item.is_derived_variable ? item.derived_metric_calculation : item.response_code),
                  item.is_derived_variable,
                  item.calculation_type,
                  Number(item.sort_id),
                  false,
                  item.year_code,
                  item.date,
                  0,
                  '',
                  false,
                  false,
                  '',
                  item.customGroupId,
                  item.dataYearCode,
                  Number(this.data_is_multi_punch),
                  item.isCustomCalculation,
                  this.customCalculationId,
                  this.customCalculationRowOrder,
                  0
                );
              } else {
                const Level4 = Level3.filter(
                  (item) =>
                    item.Level0 === item.Level0 &&
                    item.Level1 == item.Level1 &&
                    item.Level2 === item.Level2 &&
                    item.Level3 != '' &&
                    item.Level4 != ''
                );

                if (
                  Level3.length == 0 ||
                  (Level4.length == 0 && item.is_multi_punch)
                ) {
                  this.checkboxClick(
                    'RowNesting',
                    entrypoint,
                    item.Level0,
                    Level4.length == 0 && item.is_multi_punch
                      ? item.Level1 + '|' + item.Level2 + '|' + item.Level3
                      : item.MetricOption == ''
                        ? item.Level1 + '|' + item.Level2
                        : item.Level1 + '|' + item.Level2 + '|' + item.MetricOption,
                    'true',
                    item.Level1,
                    true,
                    item.data_variable_code,
                    (item.calculation_type == 'RespGrp' && item.is_derived_variable ? item.derived_metric_calculation : item.response_code),
                    item.is_derived_variable,
                    item.calculation_type,
                    Number(item.sort_id),
                    false,
                    item.year_code,
                    item.date,
                    0,
                    '',
                    false,
                    false,
                    '',
                    item.customGroupId,
                    item.dataYearCode,
                    Number(this.data_is_multi_punch),
                    item.isCustomCalculation,
                    this.customCalculationId,
                    this.customCalculationRowOrder,
                    0
                  );
                } else {
                  const Level5 = Level4.filter(
                    (item) =>
                      item.Level0 === item.Level0 &&
                      item.Level1 == item.Level1 &&
                      item.Level2 === item.Level2 &&
                      item.Level3 != '' &&
                      item.Level4 != '' &&
                      item.Level5 != ''
                  );

                  if (
                    Level4.length == 0 ||
                    (Level5.length == 0 && item.is_multi_punch)
                  ) {
                    this.checkboxClick(
                      'RowNesting',
                      entrypoint,
                      item.Level0,
                      item.is_multi_punch
                        ? item.Level1 + '|' + item.Level2 + '|' + item.Level3
                        : item.Level1 +
                        '|' +
                        item.Level2 +
                        '|' +
                        item.Level3 +
                        '|' +
                        item.MetricOption,
                      'true',
                      item.Level1,
                      true,
                      item.data_variable_code,
                      (item.calculation_type == 'RespGrp' && item.is_derived_variable ? item.derived_metric_calculation : item.response_code),
                      item.is_derived_variable,
                      item.calculation_type,
                      Number(item.sort_id),
                      false,
                      item.year_code,
                      item.date,
                      0,
                      '',
                      false,
                      false,
                      '',
                      item.customGroupId,
                      item.dataYearCode,
                      Number(this.data_is_multi_punch),
                      item.isCustomCalculation,
                      this.customCalculationId,
                      this.customCalculationRowOrder,
                      0
                    );
                  }
                }
              }
            }
            this.Loader.hideLoader();
          }, 0);
        }
        this.allValidationsPass = true;
      } else {
        this.getValidation('max_nesting', 'Rows', '');
      }
    }

    this.enableOrDisableProfiling();


  }

  handleListCustomGroupItemDrop(): void {
    const matchFound = this.entryList.some((entry) =>
      this.entryList.some(
        (listItem) => entry.Level1 === this.selectedVariableLevel0
      )
    );

    const ListB = this.listBItemsList.some((entry) =>
      this.listBItemsList.some((listItem) => entry.Level0 === 'Time Period')
    );
    this.listCustomGroupItemsList.push({
      Level0: this.selectedVariableLevel0,
      Level1: this.selectedVariableLevel1,
      tooltip: this.is_tooltip,
      has_nextLevel: this.has_nextLevel,
      sortId: this.listASortID++,
      is_Derived: this.selectedVariable_IsDervied,
      is_calc_type: this.selectedVariable_IsCalc,
      data_variable_code: this.data_variable_code,
      data_response_code: this.data_response_code,
      data_sort_id: this.data_sort_id,
      data_is_multi_punch: this.data_is_multi_punch,
      data_date: this.data_date,
      data_year_code: this.data_year_code,
      is_dimension: this.is_dimension,
      is_custmGrpPresent: this.is_custmGrpPresent,
      is_custmCalcPresent: this.is_custmCalcPresent,
      kibanaFilterScript: this.kibanaFilterScript,
      customGroupId: this.customGroupId,
      customCalculationId: this.customCalculationId,
      dataYearCode: '',
      customGroupRowOrder: 0, //when dropped initially before selecting any option default value 0 is assigned
      isSelectAllMetrics: false,
      isSelectAllMetricsCount: 0,
      is_entrypoint: false
    });
    this.allValidationsPass = true;
    this.enableOrDisableProfiling();
    if (this.has_nextLevel == false) {
      let derivedValue = this.selectedVariable_IsDervied; // or 'false'
      let DerivedVal = derivedValue === 'true';

      let multipunch = this.data_is_multi_punch; // or 'false'
      let ismultipunch = multipunch === 'true';

      this.checkboxClick(
        'Custom Group',
        false,
        this.selectedVariableLevel0,
        this.selectedVariableLevel0 + '|' + this.selectedVariableLevel1,
        'true',
        this.selectedVariableLevel1,
        true,
        this.data_variable_code,
        this.data_response_code,
        DerivedVal,
        this.selectedVariable_IsCalc,
        Number(this.data_sort_id),
        ismultipunch,
        this.data_year_code,
        this.data_date,
        0,
        ismultipunch ? this.selectedVariableLevel0 : '',
        this.is_dimension,
        this.is_custmGrpPresent,
        this.kibanaFilterScript,
        this.customGroupId,
        this.dataYearCode,
        Number(this.data_is_multi_punch),
        false,
        this.customCalculationId,
        this.customCalculationRowOrder,
        0
        // 0//custom Group row order
      );
    }
    // }
  }

  // disable Answer base if custom group is selected in row,column or filter
  updateToggleBasedOnCustomGroup() {
    //this.enableAnswerBase = true;
    //if custom group is selected at any leveles then answer base is disabled
    if (this.checkSelectionsToDisableToggle(this.listAItemsList)) { //row
      if (this.checkSelectionsToDisableToggle(this.listCItemsList)) //Column
        this.checkSelectionsToDisableToggle(this.listEItemsList); //Filter
    }
  }

  checkSelectionsToDisableToggle(listContent: any) {
    //is custom group present in the object
    var isCustomGroupPresent =
      _.filter(listContent, (r) => r.is_custmGrpPresent).length > 0;

    //disable answer base toggle if custom group is selected
    if (isCustomGroupPresent) {
      this.toggleActiveBlock('total_base');
      this.enableAnswerBase = false;
    } else this.enableAnswerBase = true;
    //this.toggleActiveBlock('total_base');
    return this.enableAnswerBase;
  }

  toggleActiveBlock(block: string): void {
    if (block === 'filtering') {
      this.isFilteringActive = true;
      this.isProfilingActive = false;
    } else if (block === 'profiling') {
      this.isFilteringActive = false;
      this.isProfilingActive = true;
    } else if (block === 'total_base') {
      this.isTotalBase = true;
      this.isAnswerBase = false;
    } else if (block === 'answer_base') {
      this.isTotalBase = false;
      this.isAnswerBase = true;
    }
  }

  isCreateOrEditCustomGroup() {
    if (
      this.isCreateCustomGroup ||
      this.isUpdateCustomGroup ||
      this.isDuplicateCustomGroup
    )
      return true;
    else return false;
  }

  getListByDestination(destination: string): any[] {
    switch (destination) {
      case 'listAItemsList':
        return this.listAItemsList;
      case 'listBItemsList':
        return this.listBItemsList;
      case 'listCItemsList':
        return this.listCItemsList;
      case 'listDItemsList':
        return this.listDItemsList;
      case 'listEItemsList':
        return this.listEItemsList;
      case 'listCustomGroupItemsList':
        return this.listCustomGroupItemsList;
      case 'listCustomCalculationItemsList':
        return this.listCustomCalculationItemsList;
      default:
        return [];
    }
  }

  rearrangeItemsInArray(panel: string, parent: string, newIndex: number) {
    const data = this.dataObject[panel as keyof DataObject];
    const index = data.findIndex((item) => item.Parent === parent);
    if (index !== -1) {
      const itemToMove = data.splice(index, 1)[0];
      data.splice(newIndex, 0, itemToMove);
      this.getLimitedDataPreview();
    }
  }

  getRowDataLenth() {
    let rowCount = 0;
    this.dataObject.Row.forEach((element: any) => {
      rowCount = rowCount + element.Data.length;
    });
    return rowCount == 0 ? 1 : rowCount;
  }

  getColumnDataLenth() {
    let columnCount = 0;
    this.dataObject.Column.forEach((element: any) => {
      columnCount = columnCount + element.Data.length;
    });
    return columnCount == 0 ? 1 : columnCount;
  }

  getLimitedDataPreview() {
    let columnCount = this.getColumnDataLenth();
    let columnLevelOneCount =
      this.dataObject.ColNesting.length > 0
        ? this.dataObject.ColNesting[0].Data.length
        : 1;
    let columnLevelTwoCount =
      this.dataObject.ColNesting.length > 1
        ? this.dataObject.ColNesting[1].Data.length
        : 1;
    let totalColumnCount =
      columnCount * columnLevelOneCount * columnLevelTwoCount;
    let rowLevelZeroCount = this.getRowDataLenth();
    let rowLevelOneCount =
      this.dataObject.RowNesting.length > 0
        ? this.dataObject.RowNesting[0].Data.length
        : 1;
    let rowLevelTwoCount =
      this.dataObject.RowNesting.length > 1
        ? this.dataObject.RowNesting[1].Data.length
        : 1;
    let totalRowCount = rowLevelZeroCount * rowLevelOneCount * rowLevelTwoCount;
    let totalDataCount = totalColumnCount * totalRowCount;
    if (totalDataCount <= this.limitCellCount) {
      this.previewData = JSON.parse(JSON.stringify(this.dataObject));
      this.FooterNote = false;
    } else {
      let limitColumnCount = 0;
      let limitRowCount = 0;
      if (
        totalColumnCount >= this.LimitForPreviewTable &&
        totalRowCount >= this.LimitForPreviewTable
      ) {
        limitColumnCount = this.LimitForPreviewTable;
        limitRowCount = this.LimitForPreviewTable;
      } else if (
        totalColumnCount >= this.LimitForPreviewTable &&
        totalRowCount < this.LimitForPreviewTable
      ) {
        if (totalColumnCount <= this.limitCellCount / totalRowCount) {
          limitColumnCount = totalColumnCount;
        } else {
          limitColumnCount = this.limitCellCount / totalRowCount;
        }
        limitRowCount = totalRowCount;
      } else if (
        totalRowCount >= this.LimitForPreviewTable &&
        totalColumnCount < this.LimitForPreviewTable
      ) {
        if (totalRowCount <= this.limitCellCount / totalColumnCount) {
          limitRowCount = totalRowCount;
        } else {
          limitRowCount = this.limitCellCount / totalColumnCount;
        }
        limitColumnCount = totalColumnCount;
      } else {
        limitColumnCount = this.LimitForPreviewTable;
        limitRowCount = this.LimitForPreviewTable;
      }
      let rowItems = this.getItemsBasedOnCount(
        this.dataObject.Row,
        limitRowCount
      );
      let columnItems = this.getItemsBasedOnCount(
        this.dataObject.Column,
        limitColumnCount
      );
      this.previewData.Row = [...rowItems];
      this.previewData.Column = [...columnItems];
      this.previewData.ColNesting = [];
      this.previewData.RowNesting = [];
      this.previewData.Filter = [...this.dataObject.Filter];

      let colCount = this.getCountOfItemsSelected(this.dataObject.Column);
      let rowCount = this.getCountOfItemsSelected(this.dataObject.Row);
      if (this.dataObject.ColNesting.length > 0) {
        const result = this.generateCombinations(
          this.dataObject.ColNesting,
          0,
          limitColumnCount
        );

        const itemsInResult = this.dataObject.ColNesting.map((obj) => ({
          Parent: obj.Parent,
          SortId: obj.SortId,
          isMultiPunch: obj.isMultiPunch,
          MultiPunchNumber: obj.MultiPunchNumber,
          isCustomGroup: obj.isCustomGroup,
          isCustomCalculation: obj.isCustomCalculation,
          customGroupId: obj.customGroupId,
          customCalculationId: obj.customCalculationId,
          brandanalysisCode: 0,
          is_entrypoint: obj.is_entrypoint,
          Data: obj.Data.filter((item) =>
            result.flat().some((resItem) => resItem.Child === item.Child)
          ),
        }));
        this.previewData.ColNesting = [...itemsInResult];
        let colstoConsider = this.getCountFromValue(
          limitColumnCount,
          result.length
        );
        let columnItems = this.getItemsBasedOnCount(
          this.dataObject.Column,
          colstoConsider
        );
        this.previewData.Column = [...columnItems];
      }
      if (this.dataObject.RowNesting.length > 0) {
        const rowNesting = this.generateCombinations(
          this.dataObject.RowNesting,
          0,
          limitRowCount
        );

        if (
          rowNesting != null &&
          rowNesting.length * rowCount > limitRowCount
        ) {
          this.FooterNote = true;
        }
        const RowNestingResult = this.dataObject.RowNesting.map((obj) => ({
          Parent: obj.Parent,
          SortId: obj.SortId,
          isMultiPunch: obj.isMultiPunch,
          MultiPunchNumber: obj.MultiPunchNumber,
          isCustomGroup: obj.isCustomGroup,
          isCustomCalculation: obj.isCustomCalculation,
          customGroupId: obj.customGroupId,
          customCalculationId: obj.customCalculationId,
          brandanalysisCode: 0,
          is_entrypoint: obj.is_entrypoint,
          Data: obj.Data.filter((item) =>
            rowNesting.flat().some((resItem) => resItem.Child === item.Child)
          ),
        }));
        this.previewData.RowNesting = [...RowNestingResult];
        let rowstoConsider = this.getCountFromValue(
          limitRowCount,
          rowNesting.length
        );
        let rowItems = this.getItemsBasedOnCount(
          this.dataObject.Row,
          rowstoConsider
        );
        this.previewData.Row = [...rowItems];
      }
      // if (
      //   colCount > this.LimitForPreviewTable ||
      //   rowCount > this.LimitForPreviewTable
      // ) {
      //   this.FooterNote = true;
      // }
      this.FooterNote = true;
    }
  }

  getCountOfItemsSelected(arrayItem: any) {
    if (arrayItem.length > 0) {
      let count = 0;
      arrayItem.forEach((x: any) => {
        count = count + x.Data.length;
      });
      return count;
    }
    return arrayItem.length;
  }

  generateCombinations(arrays: any, index: number, limit: number): any[][] {
    if (index === arrays.length - 1) {
      // Base case: Last array, return its elements as individual combinations
      return arrays[index].Data.slice(0, limit).map((item: any) => [
        { Child: item.Child },
      ]);
    }
    const nextCombinations = this.generateCombinations(
      arrays,
      index + 1,
      limit
    );
    const currentData = arrays[index].Data.slice(0, limit);
    return currentData
      .reduce((result: any, currentItem: any) => {
        return result.concat(
          nextCombinations.map((combination) => [
            { Child: currentItem.Child },
            ...combination,
          ])
        );
      }, [])
      .slice(0, limit);
  }

  getCountFromValue(value: number, denominator: number): number {
    if (value > 0 && denominator > 0) {
      return Math.ceil(value / denominator);
    } else {
      return 0;
    }
  }
  getItemsBasedOnCount(items: any, count: number) {
    const result: any[] = [];
    let remainingCount = count;

    for (const item of items) {
      const dataCount = item.Data.length;

      if (remainingCount >= dataCount) {
        // If there are enough items in the current data array, include the whole item
        result.push(item);
        remainingCount -= dataCount;
      } else {
        // If not, include a partial data array and break
        result.push({
          ...item,
          Data: item.Data.slice(0, remainingCount),
        });
        break;
      }
    }
    return result;
  }

  private enableOrDisableProfiling() {
    //disable profile if mean is selected in parent
    const isMeanDragged =
      this.listAItemsList.filter(
        (entry) =>
          (entry.is_Derived.toString() == 'true' &&
            entry.is_calc_type.toLowerCase() == 'mean') ||
          entry.is_custmCalcPresent
      ).length > 0
        ? true
        : false;

    if (isMeanDragged || this.listBItemsList.length == 0) {
      this.enableProfiling = false;
      if (this.isProfilingActive.toString() == 'true') {
        //if profiling is selected already enable filtering
        this.isFilteringActive = true;
        this.isProfilingActive = false;

        if (isMeanDragged && !this.isCreateCustomGroup) {
          //only if mean is dragged display this
          this.errorPopup(
            'Nesting type has been changed to Filtering as a Mean Variable is selected in Row Parent selection panel'
          );
        }
      }
    } else if (this.listBItemsList.length > 0) this.enableProfiling = true;
  }

  errorPopup(message: string, headerTxt: string = 'Error'): void {
    const alertObj: AlertMessageModel = {
      header: headerTxt,
      message: message,
      show: true,
    };
    this._alertService.showAlert(alertObj);
  }

  updateHeight() {
    if (this.isRowNesting) return 50; // Set a new height value in percentage
    return 100;
  }

  backButtonCustomCalcClick() {

  }

  backButtonClick() {
    this.customGroupItemIndex = -1;
    this.closeCustomVariableScreen.emit(true);
  }

  expandPanel() {
    this.left_panel_collapse = false;
  }

  CancelEntryPointChange(cancelData: CancelEntryPointItem) {

  }

  handleLeftpanel(eventData: {
    entry_items_expand: string;
    entryFlag: boolean;
  }): void {

  }

  handleEntryPoint(eventData: { entryPanelData: any }): void {

  }

  clearAllSelection() {

  }

  handleDragDrop(eventData: {
    title: string;
    Parent: any;
    is_derived: string;
    is_calc_type: string;
    tooltip: string;
    has_nextLevel: boolean;
    data_variable_code: string;
    data_response_code: string;
    data_sort_id: string;
    data_is_multi_punch: string;
    data_date: string;
    data_year_code: string;
    is_dimension: boolean;
    is_entrypoint: boolean;
    is_custmGrpPresent: boolean;
    is_custmCalcPresent: boolean;
    data_kibanaFilterScript: string;
    data_customGroupId: string;
    data_customCalculationId: string;
    dataYearCode: string;
    is_Time_Period_Present: boolean;
  }): void {
    this.selectedVariableLevel0 = eventData.Parent;
    this.selectedVariableLevel1 = eventData.title;
    this.selectedVariable_IsDervied = eventData.is_derived;
    this.selectedVariable_IsCalc = eventData.is_calc_type;
    this.is_Derived = eventData.is_derived;
    this.is_calc_type = eventData.is_calc_type;
    this.is_tooltip = eventData.tooltip;
    this.has_nextLevel = eventData.has_nextLevel;
    this.data_variable_code = eventData.data_variable_code;
    this.data_response_code = eventData.data_response_code;
    this.data_sort_id = eventData.data_sort_id;
    this.data_is_multi_punch = eventData.data_is_multi_punch;
    this.data_date = eventData.data_date;
    this.data_year_code = eventData.data_year_code;
    this.is_dimension = eventData.is_dimension;
    this.is_custmGrpPresent = eventData.is_custmGrpPresent;
    this.is_custmCalcPresent = eventData.is_custmCalcPresent;
    this.kibanaFilterScript = eventData.data_kibanaFilterScript;
    this.customGroupId = eventData.data_customGroupId;
    this.customCalculationId = eventData.data_customCalculationId;
    this.dataYearCode = eventData.dataYearCode;
    this.is_Time_Period_Present = eventData.is_Time_Period_Present;
    this.is_entrypoint = eventData.is_entrypoint;
  }

  handleConfig(eventData: {
    RowNestSelection: boolean;
    ColNestSelection: boolean;
    is_brand_analysis: boolean;
    is_chart_visualization: boolean;
    is_sticky_selection: boolean;
    is_reset_selection: boolean;
    is_Transpose: any;
    isFilterToggle: any;
    isAnswerToggle: any;
    is_indexing: any;
    is_sigtesting: any;
    is_ranking: any;
    comparisionpoint: any;
    confidenceLevel: any;
    excelExport: any;
    repository: any;
    lowBaseIdentifier: any;
    pptExport: any;
    is_AI_Integration: boolean;
    is_StoryBoard: boolean;
    is_Column_Row_Percentage: boolean;
    insufficentBase: string;
    lowBase: string;
    threshold: string;
    configweights: any;
    iscustomgrp: boolean;
    iscustomCalc: boolean;
    iscustomreport: boolean;
    changeEntryPoint: boolean;
    dataFormat: string;
    isWeightedBase: boolean;
    isSampleSize: boolean;
    aggregatedVariables: any;
    mandatoryVariableMapping: any;
    isSelectAllMetrics: any;
  }): void {

  }

  handleBtnChange(eventData: {
    save: boolean;
    load: boolean;
    custom_groups: boolean;
    custom_calc: boolean;
    create_table: boolean;
    weighted_btns: boolean;
  }): void {

  }

  onDimensionListChange(updatedDimensionList: any[]): void {

  }

  onEntryListChange(updatedEntryList: any[]): void {

  }

  updateButtonState(items: string[], isDisabled: boolean): void {
    this.disabledCustomGroupOperatorList = [];

    for (const item of items) {
      const buttonClass = this.buttonCssClassMap[item];

      this.disabledCustomGroupOperatorList.push(buttonClass);
    }
  }

  invokeValidationRules(latestSelection: string): void {
    const newValue = latestSelection;
    //$('#DummyForSelectionWatching').attr('latestSelection');

    for (const rule of customGroupsvalidationRules) {
      if (
        rule.latestSelection.toLowerCase() === latestSelection?.toLowerCase()
      ) {
        //const enabledItems = rule.itemsToBeEnabled;
        const disabledItems = rule.itemsToBeDisabled;

        // this.updateButtonState(enabledItems, false);
        this.updateButtonState(disabledItems, true);
      }
    }
  }

  handleCustomGroupSavePopUp(eventData: { isClose: boolean }): void {
    this.isSaveCustomGroupSelectionClick = eventData.isClose;
  }

  private updateDisplayAndResetSelection(destination: string) {
    switch (destination) {
      case 'listAItemsList':
        this.sidePanelDisplay = false;
        break;
      case 'listBItemsList':
        this.sidePanelNestingDisplay = false;
        break;
      case 'listCItemsList':
        this.columnPanelDisplay = false;
        break;
      case 'listDItemsList':
        this.columnNestingPanelDispaly = false;
        break;
      case 'listEItemsList':
        this.filterPanelDisplay = false;
        break;

      default:
        return;
    }
  }

  updateSharedListAndObject(
    selectedObj: any,
    selectedEmailsToShare: string[],
    sharedWithData: any[],
    userDefinedData: any[],
    type: string
  ): void {
    //check wether the same selection is shared already then need not update the existing object
    var filteredObj = _.filter(sharedWithData, (element) => {
      if (
        element.selection_name === selectedObj.selection_name &&
        element.study === selectedObj.study &&
        element.client_name === selectedObj.client_name
      ) {
        return element;
      }
    });

    // Update shared list if the object is shared with the current user
    if (
      selectedEmailsToShare.includes(selectedObj.user_name) &&
      filteredObj.length == 0
    ) {
      sharedWithData.unshift(selectedObj);
    }

    // Update shared object
    userDefinedData = _.map(userDefinedData, (element) => {
      if (
        element.selection_name === selectedObj.selection_name &&
        element.study === selectedObj.study &&
        element.client_name === selectedObj.client_name
      ) {
        return {
          ...element,
          is_shared: true,
          shared_with: selectedEmailsToShare.toString(),
        };
      }
      return element;
    });

    //updating original objects to rebind data
    if (type == 'customGroup') {
      this.loadCustomGroupUserDefinedData = userDefinedData;
      this.loadCustomGroupSharedWithMeData = sharedWithData;
    }
    //  else if (type == 'loadSelection') {
    //   this.loadUserDefinedSavedScenarioData = userDefinedData;
    //   this.loadSharedWithMeSavedScenarioData = sharedWithData;
    // } else if (type == 'customCalculation') {
    //   this.loadCustomCalculationUserDefinedData = userDefinedData;
    //   this.loadCustomCalculationSharedWithMeData = sharedWithData;
    // }
  }

  handleCustomGroupSharePopUp(eventData: { isClose: boolean, isShared: boolean }): void {
    if (eventData.isShared) {
      //   // Update shared object
      this.updateSharedListAndObject(
        this.selectedCustomGroupObj,
        this.selectedEmailsToShare,
        this.loadCustomGroupSharedWithMeData,
        this.loadCustomGroupUserDefinedData,
        'customGroup'
      );
    }

    this.isShareCustomGroupPopupClick = eventData.isClose;
  }

  ngOnDestroy() {
    if (this.$unSubscribeSubmitData1) {
      this.$unSubscribeSubmitData1.unsubscribe();
    }
    if (this.$unSubscribeSubmitData2) {
      this.$unSubscribeSubmitData2.unsubscribe();
    }
  }
}
