<div class="custom-reports-popup-conatiner">
    <div class="custom-reports-model">
        <div class="custom-reports-header">
            <div class="custom-reports-header-text">{{headerText}}</div>
            <div class="custom-reports-close" title="Refresh / Reload Custom Reports" (click)="OnRefreshCustomReports()"
                *ngIf="!isCreateEdit">
                <div class="custom-reports-refresh-icon"></div>
            </div>
            <!-- <div class="custom-reports-close" title="Exports Downloads" (click)="OnExportDownloadsClick()" *ngIf="!isCreateEdit">
                <div class="custom-reports-download-icon"></div>
            </div> -->
            <div class="custom-reports-close" title="Close" *ngIf="!isCreateEdit" (click)="closeCustomReportPopup()">
                <div class="custom-reports-close-icon"></div>
            </div>
        </div>
        <div class="custom-reports-body">
            <ng-container *ngIf=!isCreateEdit>
                <div class="custom-reports-list">
                    <div class="custom-reports-block">
                        <div class="custom-reports-list-header">
                            <div class="custom-report-list-header-block">
                                <div class="custom-reports-list-header-txt" (click)="toggleUserDefinedTable()">
                                    {{userDefinedHeaderText}}</div>
                                <div class="custom-report-list-search"
                                    *ngIf="showUserDefinedTable && userDefinedReportsList!=null && userDefinedReportsList.length>0">
                                    <div class="search-block" title="Search">
                                        <input type="text" [(ngModel)]="userDefinedSearchQuery" class="search-input"
                                            placeholder="Search">
                                        <div class="search-icon"></div>
                                    </div>
                                </div>
                                <div class="custom-report-list-toggle" (click)="toggleUserDefinedTable()">
                                    <div class="custom-reports-list-toggle-icon custom-reports-list-downarrow-icon"
                                        [ngClass]="{'custom-reports-list-uparrow-icon':showUserDefinedTable}"></div>
                                </div>
                            </div>

                        </div>
                        <div class="custom-report-list-body" *ngIf="showUserDefinedTable">
                            <table>
                                <thead *ngIf="userDefinedReportsList!=null && userDefinedReportsList.length>0">
                                    <tr>
                                        <th *ngFor="let header of userDefinedListHeaders">{{header}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of userDefinedReportsList | search: userDefinedSearchQuery : ['custom_report_name']">
                                        <td class="report-name-txt"
                                            title="{{item.custom_report_name}}{{item.is_shared==true?'_Shared':''}}">
                                            <div class="custom-report-name-txt">
                                                {{item.custom_report_name}}{{item.is_shared==true?'_Shared':''}}</div>

                                        </td>
                                        <td>
                                            <div class="report-operation-block"
                                                [ngClass]="{'disabled':item.is_downloading}" title="Download"
                                                (click)="OnDownloadReportClick(item)">
                                                <div class="report-operation-icon report-download-icon">&nbsp;</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="report-operation-block" title="Info"
                                                (click)="OnCustomReportInfoClick(item)">
                                                <div class="report-operation-icon report-info-icon">&nbsp;</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="report-operation-block"
                                                [ngClass]="{'disabled':item.is_downloading}" title="Update"
                                                (click)="OnUpdateReportClick(item)">
                                                <div class="report-operation-icon report-update-icon">&nbsp;</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="report-operation-block" title="Duplicate"
                                                (click)="OnDuplicateReportClick(item)">
                                                <div class="report-operation-icon report-duplicate-icon">&nbsp;</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="report-operation-block"
                                                [ngClass]="{'disabled':item.is_downloading}" title="Delete"
                                                (click)="OnDeleteClick(item,userDefinedReportText)">
                                                <div class="report-operation-icon report-delete-icon">&nbsp;</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="report-operation-block" title="Share"
                                                (click)="OnShareReportClick(item)">
                                                <div class="report-operation-icon report-share-icon">&nbsp;</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="no-data"
                                        *ngIf="userDefinedReportsList==null|| userDefinedReportsList.length==0">
                                        <td colspan="7" class="emptyrows">You have not created any custom reports.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="custom-reports-block" [ngClass]="{'bd-top':!showUserDefinedTable}">
                        <div class="custom-reports-list-header "
                            [ngClass]="{'shared-withme-header':!showUserDefinedTable}">
                            <div class="custom-report-list-header-block">
                                <div class="custom-reports-list-header-txt" (click)="toggleSharedWithMeTable()">
                                    {{sharedWithMeHeaderText}}</div>
                                <div class="custom-report-list-search"
                                    *ngIf="showSharedWithMeTable && sharedWithMeReportsList!=null && sharedWithMeReportsList.length>0">
                                    <div class="search-block" title="Search">
                                        <input type="text" [(ngModel)]="sharedWithMeSearchQuery" class="search-input"
                                            placeholder="Search">
                                        <div class="search-icon"></div>
                                    </div>
                                </div>
                                <div class="custom-report-list-toggle" (click)="toggleSharedWithMeTable()">
                                    <div class="custom-reports-list-toggle-icon custom-reports-list-downarrow-icon"
                                        [ngClass]="{'custom-reports-list-uparrow-icon':showSharedWithMeTable}"></div>
                                </div>
                            </div>
                        </div>
                        <div class="custom-report-list-body shared-withme_reports" *ngIf="showSharedWithMeTable">
                            <table>
                                <thead *ngIf="sharedWithMeReportsList!=null && sharedWithMeReportsList.length>0">
                                    <tr>
                                        <th *ngFor="let header of sharedWithMeListHeaders">{{header}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of sharedWithMeReportsList | search: sharedWithMeSearchQuery : ['custom_report_name','user_name']">
                                        <td class="report-name-txt" title="{{item.custom_report_name}}">
                                            <div class="custom-report-name-txt">{{item.custom_report_name}}</div>
                                        </td>
                                        <td>
                                            <div class="report-operation-block"
                                                [ngClass]="{'disabled':item.is_downloading}" title="Download"
                                                (click)="OnDownloadReportClick(item)">
                                                <div class="report-operation-icon report-download-icon">&nbsp;</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="report-operation-block" title="Info"
                                                (click)="OnCustomReportInfoClick(item)">
                                                <div class="report-operation-icon report-info-icon">&nbsp;</div>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="report-operation-block"
                                                [ngClass]="{'disabled':item.is_downloading}" title="Delete"
                                                (click)="OnDeleteClick(item,sharedWithMeReportText)">
                                                <div class="report-operation-icon report-delete-icon">&nbsp;</div>
                                            </div>
                                        </td>
                                        <td class="report-name-txt" title="{{item.user_name}}">
                                            <div class="custom-report-name-txt">{{item.user_name}}</div>
                                        </td>
                                    </tr>
                                    <tr class="no-data"
                                        *ngIf="sharedWithMeReportsList==null|| sharedWithMeReportsList.length==0">
                                        <td colspan="5" class="emptyrows">No custom reports have been shared with you.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="custom-report-btns">
                    <div class="custom-report-btn-block" title="Create Custom Report" (click)="OnCreateCustomReport()">
                        <div class="btn-icon create-icon"></div>
                        <div class="btn-header-text">Create</div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf=isCreateEdit>
                <div class="custom-reports-list create-custom-report-block">
                    <div class="custom-reports-block">
                        <div class="custom-reports-list-header">
                            <div class="custom-report-list-header-block">
                                <div class="custom-reports-list-header-txt" (click)="toggleDataUserDefinedTable()">
                                    {{datauserDefinedHeaderText}}</div>
                                <div class="custom-report-list-search">
                                    <div class="search-block" title="Search"
                                        *ngIf="datashowUserDefinedTable && dataExplorerSavedSelectionList.length>0">
                                        <input type="text" [(ngModel)]="datauserDefinedSearchQuery" class="search-input"
                                            placeholder="Search">
                                        <div class="search-icon"></div>
                                    </div>
                                </div>
                                <div class="custom-report-list-toggle" (click)="toggleDataUserDefinedTable()">
                                    <div class="custom-reports-list-toggle-icon custom-reports-list-downarrow-icon"
                                        [ngClass]="{'custom-reports-list-uparrow-icon':datashowUserDefinedTable}"></div>
                                </div>
                            </div>

                        </div>
                        <div class="custom-report-list-body" *ngIf="datashowUserDefinedTable">
                            <table class="create-custom-report-table">
                                <thead *ngIf="dataExplorerSavedSelectionList.length>0">
                                    <tr>
                                        <th *ngFor="let header of savedSelctionListHeaders">{{header}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of dataExplorerSavedSelectionList | search: datauserDefinedSearchQuery : ['selection_name']">
                                        <td class="save-selecetion-name-block" title="{{item.selection_name}}">
                                            <div class="save-selection-name-container" (click)="onCheckBoxClick(item)">
                                                <div class="checkbox-container">
                                                    <div class="checkbox-icon unchecked-icon"
                                                        [ngClass]="{'checked-icon':item.isChecked}"></div>
                                                </div>
                                                <div class="save-selection-name-text">{{item.selection_name}}</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="report-operation-block" title="Info"
                                                (click)="OnSummaryInfoClick(item)">
                                                <div class="report-operation-icon report-info-icon">&nbsp;</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="no-data"
                                        *ngIf="dataExplorerSavedSelectionList==null|| dataExplorerSavedSelectionList.length==0">
                                        <td colspan="2" class="emptyrows">You have not created any saved selections.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="custom-reports-block">
                        <div class="custom-reports-list-header">
                            <div class="custom-report-list-header-block">
                                <div class="custom-reports-list-header-txt" (click)="toggleDataSharedWithMeTable()">
                                    {{datasharedWithMeHeaderText}}</div>
                                <div class="custom-report-list-search">
                                    <div class="search-block" title="Search"
                                        *ngIf="datashowSharedWithMeTable && dataExplorerSharedWithMeSelectionList.length>0">
                                        <input type="text" [(ngModel)]="datasharedWithMeSearchQuery"
                                            class="search-input" placeholder="Search">
                                        <div class="search-icon"></div>
                                    </div>
                                </div>
                                <div class="custom-report-list-toggle" (click)="toggleDataSharedWithMeTable()">
                                    <div class="custom-reports-list-toggle-icon custom-reports-list-downarrow-icon"
                                        [ngClass]="{'custom-reports-list-uparrow-icon':datashowSharedWithMeTable}">
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="custom-report-list-body" *ngIf="datashowSharedWithMeTable">
                            <table class="create-custom-report-table">
                                <thead *ngIf="dataExplorerSharedWithMeSelectionList.length>0">
                                    <tr>
                                        <th *ngFor="let header of savedSelctionListHeaders">{{header}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of dataExplorerSharedWithMeSelectionList | search: datasharedWithMeSearchQuery : ['selection_name']">
                                        <td class="save-selecetion-name-block" title="{{item.selection_name}}">
                                            <div class="save-selection-name-container" (click)="onCheckBoxClick(item)">
                                                <div class="checkbox-container">
                                                    <div class="checkbox-icon unchecked-icon"
                                                        [ngClass]="{'checked-icon':item.isChecked}"></div>
                                                </div>
                                                <div class="save-selection-name-text">{{item.selection_name}}</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="report-operation-block" title="Info"
                                                (click)="OnSummaryInfoClick(item)">
                                                <div class="report-operation-icon report-info-icon">&nbsp;</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="no-data"
                                        *ngIf="dataExplorerSharedWithMeSelectionList==null|| dataExplorerSharedWithMeSelectionList.length==0">
                                        <td colspan="2" class="emptyrows">No saved selections have been shared with you.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="custom-reports-block" *ngIf="showBrandAnalysis">
                        <div class="custom-reports-list-header">
                            <div class="custom-report-list-header-block">
                                <div class="custom-reports-list-header-txt" (click)="toggleBrandUserDefinedTable()">
                                    {{branduserDefinedHeaderText}}</div>
                                <div class="custom-report-list-search">
                                    <div class="search-block" title="Search"
                                        *ngIf="brandshowUserDefinedTable && brandAnalysisSavedSelectionList.length>0">
                                        <input type="text" [(ngModel)]="branduserDefinedSearchQuery"
                                            class="search-input" placeholder="Search">
                                        <div class="search-icon"></div>
                                    </div>
                                </div>
                                <div class="custom-report-list-toggle" (click)="toggleBrandUserDefinedTable()">
                                    <div class="custom-reports-list-toggle-icon custom-reports-list-downarrow-icon"
                                        [ngClass]="{'custom-reports-list-uparrow-icon':brandshowUserDefinedTable}">
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="custom-report-list-body" *ngIf="brandshowUserDefinedTable">
                            <table class="create-custom-report-table">
                                <thead *ngIf="brandAnalysisSavedSelectionList.length>0">
                                    <tr>
                                        <th *ngFor="let header of savedSelctionListHeaders">{{header}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of brandAnalysisSavedSelectionList | search: branduserDefinedSearchQuery : ['selection_name']">
                                        <td class="save-selecetion-name-block" title="{{item.selection_name}}">
                                            <div class="save-selection-name-container" (click)="onCheckBoxClick(item)">
                                                <div class="checkbox-container">
                                                    <div class="checkbox-icon unchecked-icon"
                                                        [ngClass]="{'checked-icon':item.isChecked}"></div>
                                                </div>
                                                <div class="save-selection-name-text">{{item.selection_name}}</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="report-operation-block" title="Info"
                                                (click)="OnSummaryInfoClick(item)">
                                                <div class="report-operation-icon report-info-icon">&nbsp;</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="no-data"
                                        *ngIf="brandAnalysisSavedSelectionList==null|| brandAnalysisSavedSelectionList.length==0">
                                        <td colspan="2" class="emptyrows">You have not created any saved selections.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="custom-reports-block" *ngIf="showBrandAnalysis">
                        <div class="custom-reports-list-header">
                            <div class="custom-report-list-header-block">
                                <div class="custom-reports-list-header-txt" (click)="toggleBrandSharedWithMeTable()">
                                    {{brandsharedWithMeHeaderText}}</div>
                                <div class="custom-report-list-search">
                                    <div class="search-block" title="Search"
                                        *ngIf="brandshowSharedWithMeTable && brandAnalysisSharedWithMeSelectionList.length>0">
                                        <input type="text" [(ngModel)]="brandsharedWithMeSearchQuery"
                                            class="search-input" placeholder="Search">
                                        <div class="search-icon"></div>
                                    </div>
                                </div>
                                <div class="custom-report-list-toggle" (click)="toggleBrandSharedWithMeTable()">
                                    <div class="custom-reports-list-toggle-icon custom-reports-list-downarrow-icon"
                                        [ngClass]="{'custom-reports-list-uparrow-icon':brandshowSharedWithMeTable}">
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="custom-report-list-body" *ngIf="brandshowSharedWithMeTable">
                            <table class="create-custom-report-table">
                                <thead *ngIf="brandAnalysisSharedWithMeSelectionList.length>0">
                                    <tr>
                                        <th *ngFor="let header of savedSelctionListHeaders">{{header}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of brandAnalysisSharedWithMeSelectionList | search: brandsharedWithMeSearchQuery : ['selection_name']">
                                        <td class="save-selecetion-name-block" title="{{item.selection_name}}">
                                            <div class="save-selection-name-container" (click)="onCheckBoxClick(item)">
                                                <div class="checkbox-container">
                                                    <div class="checkbox-icon unchecked-icon"
                                                        [ngClass]="{'checked-icon':item.isChecked}"></div>
                                                </div>
                                                <div class="save-selection-name-text">{{item.selection_name}}</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="report-operation-block" title="Info"
                                                (click)="OnSummaryInfoClick(item)">
                                                <div class="report-operation-icon report-info-icon">&nbsp;</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="no-data"
                                        *ngIf="brandAnalysisSharedWithMeSelectionList==null|| brandAnalysisSharedWithMeSelectionList.length==0">
                                        <td colspan="2" class="emptyrows">No saved selections have been shared with you.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="custom-report-btns">
                    <div class="custom-report-btn-block" [ngClass]="{'disabled':!isSaveDisable}"
                        title="Save Custom Group" (click)="OnSaveCustomReport()">
                        <div class="btn-icon save-icon"></div>
                        <div class="btn-header-text">Save</div>
                    </div>
                    <div class="custom-report-btn-block" title="Cancel Custom Group" (click)="OnCancelCustomGroup()">
                        <div class="btn-icon cancel-icon"></div>
                        <div class="btn-header-text">Cancel</div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<div class="custom-popup-conatiner" *ngIf="savePopup">
    <div class="custom-reports-popup-model">
        <div class="header-container">
            <div class="custom-reports-header-text">{{saveSelectionHeader}}</div>
            <div class="custom-reports-close" title="Close" (click)="onSavePopupClose()">
                <div class="custom-reports-close-icon"></div>
            </div>
        </div>
        <div class="body-container">
            <div class="body-content">
                <div class="body-label-text">{{saveSelectionText}}</div>
                <input type="text" placeholder="Custom Report name.." [(ngModel)]="customReportName" maxlength="50"
                    autocomplete="off" name="SaveSelection" (keydown)="onKeyDown($event)">
            </div>
        </div>
        <div class="footer-container">
            <div class="custom-report-btn-block" [ngClass]="{'disabled':customReportName==''}"
                title="Save Custom Report" (click)="OnSubmitCustomGroup()">
                <div class="btn-icon save-icon"></div>
                <div class="btn-header-text">Save</div>
            </div>
        </div>
    </div>
</div>
<div class="custom-popup-conatiner customReportInfo" *ngIf="showCustomReportInfo">
    <div class="custom-reports-info-popup-model">
        <div class="header-container">
            <div class="custom-reports-header-text">{{customReportInfoHeader}}</div>
            <div class="custom-reports-close" title="Close" (click)="onCustomInfoPopupClose()">
                <div class="custom-reports-close-icon"></div>
            </div>
        </div>
        <div class="body-container">
            <div class="info-block-container">
                <div class="info-block" *ngFor="let item of customReportInfoList">
                    <div class="info-text" title="{{item}}">{{item}}</div>
                </div>
            </div>
        </div>
        <div class="footer-container">
            <div class="custom-report-btn-block" title="Back to Custom Report" (click)="onCustomInfoPopupClose()">
                <div class="btn-icon back-icon"></div>
                <div class="btn-header-text">Back</div>
            </div>
        </div>
    </div>
</div>
<div class="custom-popup-conatiner savedSelectionIfo" *ngIf="showSavedSelectionInfo">
    <div class="saved-selection-info-popup-model">
        <div class="header-container">
            <div class="custom-reports-header-text">{{savedSelectionInfoHeader}}</div>
            <div class="custom-reports-close" title="Close" (click)="onSavedSelectionPopupClose()">
                <div class="custom-reports-close-icon"></div>
            </div>
        </div>
        <div class="body-container">
            <div class="selection-summary-info" [innerHTML]="selectionSummary"></div>
        </div>
        <div class="footer-container">
            <div class="custom-report-btn-block" title="Back to Selection" (click)="onSavedSelectionPopupClose()">
                <div class="btn-icon back-icon"></div>
                <div class="btn-header-text">Back</div>
            </div>
        </div>
    </div>
</div>
<div class="custom-popup-conatiner" *ngIf="showShareSelectionPopup">
    <div class="share-selection-popup-model">
        <div class="share-header-container">
            <div class="custom-reports-header-text">{{shareReportHeader}}</div>
            <div class="custom-reports-close" title="Close" (click)="OnSharePopupClose()">
                <div class="custom-reports-close-icon"></div>
            </div>
        </div>
        <div class="body-container share-body-block">
            <div class="user-list-container">
                <div class="list-header-block">{{userListHeader}}</div>
                <div class="list-body-block">
                    <input type="text" (input)="OnEmailInput($event)" placeholder="Enter Email">
                    <div class="email-dropdown-container" *ngIf="filteredEmailList!=null && filteredEmailList.length>0">
                        <div class="email-block" *ngFor="let item of filteredEmailList"
                            (click)="OnEmailCheckBoxClick(item)">
                            <div class="checkbox-container">
                                <div class="checkbox-icon unchecked-icon" [ngClass]="{'checked-icon':item.isChecked}">
                                </div>
                            </div>
                            <div class="email-text" title="{{item.email}}">{{item.email}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user-list-container">
                <div class="list-header-block">{{selectedUserListHeader}}</div>
                <div class="list-body-block">
                    <div class="selected-email-block">
                        <div class="selected-email-list" *ngFor="let item of selectedEmailList">
                            <div class="selected-email-text" title={{item.email}}>{{item.email}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-container">
            <div class="custom-report-btn-block"  [ngClass]="{'disabled': !isSelectionChanged()}"  title=" Share Custom Report"
                (click)="OnShareReportSaveClick()">
                <div class="btn-icon report-share-icon width-25"></div>
                <div class="btn-header-text">Share</div>
            </div>
        </div>
    </div>
</div>
<app-confirmation-dialog *ngIf="isShowDialog" [messageText]="confirmationMessage"
    (confirmed)="onConfirmation($event)"></app-confirmation-dialog>
<app-confirmation-dialog *ngIf="isShowDialogDownload" [confirmBtnText]="confirmBtnTextForDownload"
    [cancelBtnText]="cancelBtnTextForDownload" [messageText]="confirmationMessageDownload"
    (confirmed)="onConfirmationDownload($event)"></app-confirmation-dialog>
<app-confirmation-dialog *ngIf="isShowDialogForNoTimePeriod" [messageText]="confirmationMessageDownloadNoTimeperiod"
    (confirmed)="onConfirmationDownloadNoTimePeriod($event)"></app-confirmation-dialog>
<!-- <app-export-downloads-popup (closePopup)="onCloseExportDownloads()" [showExportsDownloads]="showExportsDownloadsPopup" *ngIf="showExportsDownloadsPopup" [ReportInput]="exportReportInput"></app-export-downloads-popup> -->